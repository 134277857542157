export type Seat = {
  id: number;
  status: "occupied" | "unoccupied" | "calling";
  participant: any;
  muted: boolean;
};

export type SeatStatus = { [id: string]: Seat };

export enum ParticipantType {
  QUEUE = "queue",
  STAGEDOOR = "vip",
}
