import React from "react";
import { useSelector } from "react-redux";
import { useTimer } from "../../../utilities/customHooks";

type Props = {
  participant: any;
};

const ParticipantStateLarge: React.FC<Props> = (props) => {
  const { participant } = props;

  const serverTimeOffset = useSelector(
    (state: any) => state.queue.serverTimeOffset
  );

  const { durationString } = useTimer(
    participant.timeStamp,
    "second",
    serverTimeOffset
  );

  const renderParticipantState = () => {
    let greenRoomState = participant.greenRoomState || "gsStateError";
    let className = `callerStateDiv ${greenRoomState}`;
    let returnText = "**DEFAULT**";

    switch (greenRoomState) {
      case "uncalled": {
        returnText = `Uncalled and Waiting For: ${durationString}`;
        break;
      }
      case "establishedCall": {
        returnText = `Called and Call Lasted: ${participant.duration}`;
        break;
      }
      case "callNoAnswer": {
        returnText = "Called and No Answer";
        break;
      }
      case "SCHEDULED": {
        returnText = "SCHEDULED";
        break;
      }
      case "DEMO": {
        returnText = "DEMO CALLER";
        break;
      }
      default: {
        returnText = "!! Error !!";
        break;
      }
    }
    return (
      <div className={`${className} well greenRoomStateData`}>{returnText}</div>
    );
  };

  return <div>{renderParticipantState()}</div>;
};

export default ParticipantStateLarge;
