import React from "react";
import CQ from "../../../utilities/socket/CQ.js";
import MultiboxAvailableAssignments from "./MultiboxAvailableAssignments";
import AvailableParticipantsDropdown from "./AvailableParticipantsDropdown";
import AssignedSeatDropdown from "./AssignedSeatDropdown";
import { getRows, getSeats } from "./CrowdViewHelperFuncs";

type Props = {
  alias: string;
  canControlMultibox: boolean;
  currentScreener: any;
};

const CrowdPreviewer: React.FC<Props> = (props) => {
  const { alias, canControlMultibox, currentScreener } = props;
  const { muteControlsEnabled, popControlsEnabled } = currentScreener;

  const numCols: number = getSeats(currentScreener.name);
  const numRows: number = getRows(currentScreener.name);

  // Has to be computed programmatically
  const layoutStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: `repeat(${numCols}, minmax(0, 1fr))`,
    gridAutoRows: "1fr",
    gridGap: `5px`,
    height: "100%",
    padding: "0px",
  };

  function renderIndividualSeatStatus(
    _seatInfo,
    _inCall,
    _alias,
    _seatStatus,
    _participants
  ) {
    if (_seatInfo.status === "unoccupied") {
      return (
        <div className="seat-container">
          <AvailableParticipantsDropdown
            assignParticipantToSeat={(_participant, _seatID) => {
              CQ.emit("assignParticipantToSeat", {
                seat: _seatID,
                participant: _participant,
                multibox: currentScreener.name,
              });
            }}
            canControlMultibox={canControlMultibox}
            participants={_participants}
            seatInfo={_seatInfo}
            seatStatus={_seatStatus}
          />
        </div>
      );
    } else {
      return (
        <div className="seat-container">
          <AssignedSeatDropdown
            endpointName={currentScreener.name}
            inCall={_inCall}
            muteControlsEnabled={muteControlsEnabled}
            popControlsEnabled={popControlsEnabled}
            poppedParticipantID={currentScreener.multibox.poppedParticipantID}
            seatInfo={_seatInfo}
          />
        </div>
      );
    }
  }

  function renderCrowd() {
    const crowd = [];

    for (let i = 0; i < numCols * numRows; i++) {
      const seatNum = i + 1;
      const seat = renderIndividualSeatStatus(
        currentScreener.multibox.seatStatus[seatNum],
        currentScreener.inCall,
        alias,
        currentScreener.multibox.seatStatus,
        currentScreener.assignedMultiboxParticipants
      );
      crowd.push(seat);
    }

    return crowd;
  }

  return (
    <div className="layout-screener-crowdviewPreviewer">
      <div className="layout-screener-crowdviewRenderCrowd">
        <div style={layoutStyle}>{renderCrowd()}</div>
      </div>
      <div className="layout-screener-crowdviewAssignments">
        <MultiboxAvailableAssignments
          assignedMultiboxParticipants={
            currentScreener.assignedMultiboxParticipants
          }
          assignParticipantToSeat={(_participant, _seatID) =>
            CQ.emit("assignParticipantToSeat", {
              seat: _seatID,
              participant: _participant,
              multibox: currentScreener.name,
            })
          }
          canControlMultibox={canControlMultibox}
          inCall={currentScreener.inCall}
          seatStatus={currentScreener.multibox.seatStatus}
          unassignParticipantFromMultiboxEndpoint={(_participant) =>
            CQ.emit("unassignParticipantFromMultiboxEndpoint", {
              participant: _participant,
            })
          }
        />
      </div>
    </div>
  );
};

export default CrowdPreviewer;
