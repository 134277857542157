import { FunctionComponent, MouseEventHandler } from "react";
import TooltipButton from "./TooltipButton";

type Props = {
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const BackButton: FunctionComponent<Props> = (props) => {
  const { title, onClick } = props;

  return (
    <TooltipButton
      color="info"
      icon={["fas", "arrow-left"]}
      title={title}
      onClick={onClick}
    />
  );
};

export default BackButton;
