import type { FunctionComponent } from "react";
import type { BaseTextFieldProps, InputProps } from "@mui/material";
import type {
  Control,
  ControllerProps,
  FieldError,
  FieldErrors,
} from "react-hook-form";

import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { TextField as MuiTextField } from "@mui/material";

export interface TextFieldProps
  extends Partial<Omit<BaseTextFieldProps, "defaultValue">>,
    Omit<ControllerProps, "render"> {
  control: Control<any, any>;
  errors?: FieldError | FieldErrors;
  InputProps?: InputProps;
  name: string;
}

export const TextField: FunctionComponent<TextFieldProps> = (props) => {
  const {
    InputLabelProps = {},
    children,
    control,
    errors,
    name,
    required,
    sx,
    variant = "outlined",
    ...rest
  } = props;

  const { hasErrors, defaultErrorMessage } = useFieldErrors(errors);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <MuiTextField
          {...rest}
          {...field}
          data-testid="text-field"
          error={hasErrors}
          helperText={defaultErrorMessage ?? ""}
          InputLabelProps={{
            // always show placeholder
            required,
            shrink: true,
            ...InputLabelProps,
          }}
          sx={sx ? { ...sx, marginTop: "0.5em" } : { marginTop: "0.5em" }}
          variant={variant}
        >
          {children}
        </MuiTextField>
      )}
    />
  );
};

/**
 * @description Parse errors generated by `react-hook-form` for a single field.
 */
export const useFieldErrors = (
  errors?: FieldError | FieldErrors
): { hasErrors: boolean; defaultErrorMessage?: string } => {
  return useMemo(() => {
    if (typeof errors !== "object" || Object.keys(errors).length === 0) {
      return {
        hasErrors: false,
      };
    } else if (errors.message) {
      return {
        hasErrors: true,
        defaultErrorMessage: errors.message,
      };
    } else {
      const message = Object.values(errors)[0]?.message;

      return {
        hasErrors: true,
        defaultErrorMessage: message,
      };
    }
  }, [errors]);
};

export type UseFieldErrorsReturn = ReturnType<typeof useFieldErrors>;

export default TextField;
