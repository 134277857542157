import type {
  CSSProperties,
  FunctionComponent,
  MouseEventHandler,
} from "react";

import { Fragment } from "react";
import { Box, Checkbox, Collapse, TableCell, TableRow } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

// Hard override of default MaterialUI style
const useRowStyles = makeStyles({
  expanded: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export interface BaseRowProps {
  className?: string;
  disabled?: boolean;
  expandedRow?: JSX.Element;
  isOpen?: boolean;
  isSelected?: boolean;
  keepClosed?: boolean;
  key: string;
  rowItem: any;
  showCheckbox: boolean;
  style?: CSSProperties;
  uniqueIdentifierProperty?: string;
  onClick?: (id: string) => void;
}

const CustomTableRow: FunctionComponent<BaseRowProps> = (props) => {
  const {
    children,
    className,
    disabled = false,
    expandedRow,
    keepClosed = false,
    rowItem,
    showCheckbox,
    style,
    uniqueIdentifierProperty = "id",
    onClick,
  } = props;

  const isOpen = props.isOpen && !keepClosed;
  const classes = useRowStyles();

  const handleClick: MouseEventHandler<HTMLTableRowElement> = (_event) => {
    if (!disabled && !keepClosed) {
      onClick?.(rowItem[uniqueIdentifierProperty]);
    }
  };

  return (
    <Fragment>
      <TableRow
        className={`${classes.root} ${className}`}
        hover
        style={style}
        onClick={handleClick}
      >
        {showCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox checked={isOpen} color="primary" disabled={disabled} />
          </TableCell>
        )}
        {children}
      </TableRow>
      {expandedRow && typeof isOpen === "boolean" && onClick && (
        <TableRow>
          <TableCell className={classes.expanded} colSpan={6}>
            <Collapse in={isOpen} mountOnEnter timeout="auto" unmountOnExit>
              <Box margin={1}>{expandedRow}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

export default CustomTableRow;
