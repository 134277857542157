import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleRight,
  faArrowDown,
  faArrowLeft,
  faArrowsRotate,
  faArrowUp,
  faBan,
  faBell,
  faBellSlash,
  faCalendarPlus,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faCircle,
  faClock,
  faCloudUploadAlt,
  faComment,
  faComments,
  faCopy,
  faDesktop,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faFileVideo,
  faFilter,
  faGlobeAmericas,
  faHistory,
  faLightbulb,
  faLink,
  faLock,
  faMapMarker,
  faMapMarkerAlt,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faMobile,
  faPaperPlane,
  faPhone,
  faPhoneSlash,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faRedoAlt,
  faSms,
  faSortAlphaDown,
  faSortAlphaUp,
  faStar,
  faStop,
  faSync,
  faTablet,
  faTh,
  faThLarge,
  faTimes,
  faTimesCircle,
  faToolbox,
  faUnlock,
  faUser,
  faUserAlt,
  faUserAltSlash,
  faUserCircle,
  faUserMinus,
  faUserPlus,
  faUserSlash,
  faUsersSlash,
  faUserTag,
  faVideo,
  faVideoSlash,
  faVolumeMute,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAndroid,
  faApple,
  faBuromobelexperte,
  faLinux,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faAndroid,
  faAngleDoubleRight,
  faApple,
  faArrowDown,
  faArrowLeft,
  faArrowsRotate,
  faArrowUp,
  faBan,
  faBell,
  faBellSlash,
  faBuromobelexperte,
  faCalendarPlus,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckSquare,
  faCircle,
  faClock,
  faCloudUploadAlt,
  faComment,
  faComments,
  faCopy,
  faDesktop,
  faDownload,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faFileVideo,
  faFilter,
  faGlobeAmericas,
  faHistory,
  faLightbulb,
  faLink,
  faLinux,
  faLock,
  faMapMarker,
  faMapMarkerAlt,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faMobile,
  faPaperPlane,
  faPhone,
  faPhoneSlash,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faRedoAlt,
  faSms,
  faSortAlphaDown,
  faSortAlphaUp,
  faStar,
  faStop,
  faSync,
  faTablet,
  faTh,
  faThLarge,
  faTimes,
  faTimesCircle,
  faToolbox,
  faUnlock,
  faUser,
  faUserAlt,
  faUserAltSlash,
  faUserCircle,
  faUserMinus,
  faUserPlus,
  faUserSlash,
  faUsersSlash,
  faUserTag,
  faVideo,
  faVideoSlash,
  faVolumeMute,
  faWindows,
  faWrench
);
