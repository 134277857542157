import type { FunctionComponent, MouseEventHandler, ReactNode } from "react";
import type { ButtonProps, PaperProps } from "@mui/material";
import type { TooltipButtonProps } from "./TooltipButton";

import { createContext, useContext, useState } from "react";
import Draggable from "react-draggable";
import { Dialog, DialogTitle, IconButton, Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close as CloseIcon } from "@mui/icons-material";
import TooltipButton from "./TooltipButton";
import Hidden from "./Hidden";

const useStyles = makeStyles(() => ({
  actions: {
    justifyContent: "space-between",
  },
  largePaper: {
    height: 610,
    maxHeight: 610,
    minHeight: 610,
    maxWidth: 750,
    minWidth: 750,
    width: 750,
  },
  paper: {
    maxWidth: 750,
    minWidth: 750,
    width: 750,
  },
  title: {
    alignItems: "flex-start",
    cursor: "move",
    paddingBottom: 0,
  },
}));

export interface DraggableDialogChildProps {
  changePage?: (page: string) => void;
  close?: (...params: any) => void;
  open?: boolean;
  titleId?: string;
}

export const DraggableDialogContext = createContext<DraggableDialogChildProps>(
  {}
);

export interface ActionButtonProps extends ButtonProps {
  onClick: () => Promise<void>;
  text: string;
  triggerClose?: boolean;
}

function PaperComponent(props: PaperProps) {
  const { titleId } = useContext(DraggableDialogContext);

  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      handle={`#${titleId}`}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface BaseDraggableDialogProps {
  /**
   * @default true
   */
  disableBackdropClick?: boolean;
  id: string;
  title: string | ReactNode;
  titleIcon?: ReactNode;
  OpenButtonProps: Omit<TooltipButtonProps, "onClick">;
  onClose?: () => void;
}

interface PaginatedDraggableDialogProps extends BaseDraggableDialogProps {
  backButton?: ReactNode;
  children?: never;
  changePage: (page: string) => void;
  currentPage: string;
  large?: boolean;
  pages: Record<string, ReactNode>;
}

interface UnpaginatedDraggableDialogProps extends BaseDraggableDialogProps {
  backButton?: never;
  changePage?: never;
  currentPage?: never;
  large?: boolean;
  pages?: never;
}

export type DraggableDialogProps =
  | PaginatedDraggableDialogProps
  | UnpaginatedDraggableDialogProps;

const DraggableDialog: FunctionComponent<DraggableDialogProps> = (props) => {
  const {
    backButton,
    changePage,
    children,
    currentPage,
    disableBackdropClick = true,
    id,
    onClose,
    large = false,
    pages,
    title,
    titleIcon,
    OpenButtonProps,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const classes = useStyles();

  const handleClickOpen: MouseEventHandler<HTMLButtonElement> = (_event) => {
    setOpen(true);
  };

  const handleClose = (_event, reason?: string) => {
    if (reason === "backdropClick" && disableBackdropClick) {
      return;
    }

    onClose?.();
    setOpen(false);
  };

  const paginated = Boolean(pages);

  return (
    <DraggableDialogContext.Provider
      value={{
        open,
        close: handleClose,
        changePage,
        titleId: id,
      }}
    >
      <TooltipButton {...OpenButtonProps} onClick={handleClickOpen} />

      <Dialog
        aria-labelledby={id}
        open={open}
        PaperComponent={PaperComponent}
        PaperProps={{ className: large ? classes.largePaper : classes.paper }}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
        }}
        onClose={handleClose}
      >
        <DialogTitle className={classes.title} id={id}>
          <Grid
            alignItems="flex-start"
            container
            justifyContent="start"
            spacing={1}
          >
            {backButton && <Grid item>{backButton}</Grid>}

            {titleIcon && <Grid item>{titleIcon}</Grid>}

            <Grid item>{title}</Grid>

            <Grid item sx={{ marginLeft: "auto" }}>
              <IconButton size="small" onClick={() => handleClose({})}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        {paginated
          ? Object.entries(pages).map(([page, node]) => (
              <Hidden hide={page !== currentPage} key={page}>
                {node}
              </Hidden>
            ))
          : children}
      </Dialog>
    </DraggableDialogContext.Provider>
  );
};

export default DraggableDialog;
