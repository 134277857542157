import { MouseEvent, Fragment, FunctionComponent, useState } from "react";
import { useDispatch } from "react-redux";
import { union } from "ramda";
import { Button, Menu, MenuItem } from "@mui/material";
import { selectNetwork } from "../../store/queue/actions";

type Props = {
  authorizedNetworks: string[];
  currentNetwork: string;
  hostedNetworks: string[];
  disabled: boolean;
};

const SelectNetwork: FunctionComponent<Props> = (props) => {
  const { authorizedNetworks, currentNetwork, hostedNetworks, disabled } =
    props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const dispatch = useDispatch();

  function handleClick(event: MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorEl(null);
  }

  function handleSelect(network: string): void {
    dispatch(selectNetwork(network));
    handleClose();
  }

  const combinedNetworks = union(authorizedNetworks, hostedNetworks).sort();

  return (
    <Fragment>
      <Button disabled={disabled} onClick={handleClick}>
        {currentNetwork}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="network-selection"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {combinedNetworks.map((network, i) => {
          if (hostedNetworks.includes(network)) {
            if (authorizedNetworks.includes(network)) {
              return (
                <MenuItem
                  className={
                    network === currentNetwork
                      ? "networkSelected"
                      : "networkHostedAndAuthorized"
                  }
                  key={i}
                  onClick={() => handleSelect(network)}
                >
                  <b>{network}</b>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  className="networkHostedAndUnauthorized"
                  disabled={true}
                  key={i}
                >
                  {network}
                </MenuItem>
              );
            }
          } else {
            return (
              <MenuItem className="networkNotHosted" disabled={true} key={i}>
                {network}
              </MenuItem>
            );
          }
        })}
      </Menu>
    </Fragment>
  );
};

export default SelectNetwork;
