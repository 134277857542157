import type { FunctionComponent, MouseEventHandler } from "react";

import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Today as TodayIcon } from "@mui/icons-material";
import {
  useAuthorizer,
  useDateFormatter,
  useHttpRequest,
  useParticipantSessions,
  useSelector,
  useSnackbar,
} from "../../hooks";
import AssignedParticipantInfo from "./AssignedCaller/AssignedParticipantInfo";
import CallButtons from "./CallButtons/CallButtons";
import DeviceInfo from "../BaseParticipantExpandedRow/DeviceInfo";
import DeviceInfoIcons from "../../components/DeviceInfoIcons";
import ChatDisplay from "./AssignedCaller/ChatDisplay";
import MoveParticipantButton from "./MoveParticipantButton";
import InvitationOptions from "../InvitationOptions";
import TooltipButton from "../../components/TooltipButton";
import { participantTypeEnums } from "../../utilities/definitions";
import TabPanel from "../../components/TabPanel";
import ParticipantNoteListView from "../../components/ParticipantNotes";
import * as template from "./templates";
//import ParticipantEvents from "../ParticipantEvents";
import {
  determineParticipantStatus,
  /*formatProductionEventDuration,*/
} from "../../utilities/utilityFunctions";
import ParticipantStatusChip from "../../components/ParticipantStatusChip";
import SaveParticipantDialog from "../QuickParticipant/SaveParticipantDialog";
import SessionEventLogsTab from "../BaseParticipantExpandedRow/SessionEventLogsTab";

type Props = {
  currentScreener: any;
  screeners: any;
  participant: any;
  parentFuncs: any;
};

const ParticipantSummary: FunctionComponent<Props> = (props) => {
  const { currentScreener, parentFuncs, participant, screeners } = props;
  const formatDate = useDateFormatter();

  const {
    chatHistory,
    connected,
    contacts: contactsProperty,
    guid,
    id,
    invitationSentAt,
    networkDiagnosticState,
    waitingForConnection,
    fullName,
    family_name,
    given_name,
    type,
    temporary,
    //twoWaySmsConfiguration, // Suppressed 10/3/2023 for merge purposes
  } = participant;

  /*const [twoWaySmsEnabled, setTwoWaySmsEnabled] = useState<boolean>(
    Boolean(twoWaySmsConfiguration.subscriptionId) &&
      Boolean(twoWaySmsConfiguration.webhookId)
  );*/ // Suppressed 10/3/2023 for merge purposes

  const isQueue = type === participantTypeEnums.QUEUE;

  const hasReceivedInvitation = Boolean(invitationSentAt);

  const {
    fetchSessions,
    latestSession,
    latestSessionDevice,
    latestSessionNetworkDiagnostic,
  } = useParticipantSessions(guid, connected, networkDiagnosticState);

  const { running } = networkDiagnosticState;

  const { score } = latestSessionNetworkDiagnostic ?? {};

  const deviceInfo = isQueue ? participant.deviceInfo : latestSessionDevice;

  const [recommendedApp, setRecommendedApp] = useState(undefined);

  const { allocatedCMP, inCall, productionEventGuid } = currentScreener;
  const localCall = !allocatedCMP;

  const isInCall = Boolean(inCall);

  const { enqueueSnackbar } = useSnackbar();

  const [contacts, setContacts] = useState<any[]>([]);

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );
  const productionEvents = useSelector((state) => state.queue.productionEvents);

  const productionEvent = productionEvents.find(
    ({ guid }) => guid === productionEventGuid
  );

  const [participantRequest] = useHttpRequest(
    `admin_proxy/participants/${guid}`,
    {
      method: "get",
    }
  );

  const [deleteEvent, deleteEventState] = useHttpRequest(
    `admin_proxy/productions/${productionGuid}/events/${productionEventGuid}`,
    {
      method: "delete",
      searchParams: {
        accountGuid,
      },
      onError: () => {
        enqueueSnackbar("Failed to mark event completed.", {
          variant: "error",
        });
      },
      onSuccess: () => {
        enqueueSnackbar("Production event marked completed.", {
          variant: "success",
        });
      },
    }
  );

  const [activeTab, setActiveTab] = useState<number>(1);
  const [chatOpened, setChatOpened] = useState<boolean>(false);
  const [initialMessageCount] = useState(chatHistory?.length);
  const [newMessagesCount, setNewMessagesCount] = useState<number>(0);
  const [openSaveParticipantModal, setOpenSaveParticipantModal] =
    useState<boolean>(false);

  const chatMessageCount = chatHistory?.length;

  const loadSessions = useCallback(async () => {
    await fetchSessions();
  }, []);

  const loadContacts = useCallback(async () => {
    const data = await participantRequest({});

    if (data) {
      setContacts(data.contacts);
    }
  }, [participantRequest]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  useEffect(() => {
    if (Array.isArray(contactsProperty)) {
      setContacts(contactsProperty);
    }
  }, [contactsProperty]);

  useEffect(() => {
    if (activeTab !== 3 && initialMessageCount !== chatMessageCount) {
      setNewMessagesCount((count) => count + 1);
    }
  }, [activeTab, chatMessageCount, initialMessageCount]);

  /*useEffect(() => {
    const { subscriptionId, webhookId } = twoWaySmsConfiguration;
    setTwoWaySmsEnabled(Boolean(subscriptionId) && Boolean(webhookId));
  }, [twoWaySmsConfiguration]);*/ // Suppressed 10/3/2023 for merge purposes

  const handleCompleteEventClick: MouseEventHandler<HTMLButtonElement> = async (
    _event
  ) => {
    await deleteEvent();
  };

  function handleTabChange(_event, value) {
    if (value === 3) {
      setChatOpened(true);
      setNewMessagesCount(0);
    } else {
      setChatOpened(false);
    }

    setActiveTab(value);
  }

  function endAssignment() {
    parentFuncs.assignedCaller.unassignParticipant(localCall);
  }

  const participantNameTitleText = useMemo(() => {
    if (fullName) {
      return fullName.trim();
    }

    return `${given_name} ${family_name}`.trim() || template.nameNotProvided;
  }, [fullName, given_name, family_name]);

  const participantStatus = useMemo(() => {
    if (participant) return determineParticipantStatus(participant);
    return null;
  }, [participant]);

  const authorize = useAuthorizer();
  const userHasAdminPermission = authorize("queueadmin");

  useEffect(() => {
    const deviceOs = deviceInfo?.deviceOs ?? deviceInfo?.os?.name;

    if (!running && connected && typeof score === "number") {
      let tempRecommendedApp = "Twilio";

      if (
        deviceOs?.toLowerCase() === "macos" ||
        deviceOs?.toLowerCase() === "ios"
      ) {
        // Recommend FaceTime as top option
        if (score > 4) tempRecommendedApp = "Facetime";
        else if (score < 4 && score > 2) tempRecommendedApp = "Twilio";
        else tempRecommendedApp = "Gruveo";
      } else {
        // Recommend VCC WebRTC as top option
        if (score > 3) tempRecommendedApp = "Twilio";
        else tempRecommendedApp = "Gruveo";
      }

      setRecommendedApp(tempRecommendedApp);
    }
  }, [connected, deviceInfo, running, score, setRecommendedApp]);

  return (
    <Fragment>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Paper className="layout-screener-participantSummary">
            <Grid container direction="column" spacing={1} xs={12}>
              {type === participantTypeEnums.VIP && productionEvent && (
                <Grid item>
                  <Alert
                    action={
                      <TooltipButton
                        color="secondary"
                        disabled={
                          currentScreener.inCall || deleteEventState.loading
                        }
                        fullWidth={true}
                        text={
                          template.participantSummary.scheduled.buttons
                            .completeCall
                        }
                        title={
                          template.participantSummary.scheduled.tooltips
                            .completeCall
                        }
                        onClick={handleCompleteEventClick}
                      />
                    }
                    color="info"
                    icon={<TodayIcon />}
                  >
                    <AlertTitle>
                      <Typography variant="subtitle2">
                        <b>
                          Scheduled{" "}
                          {
                            template.participantSummary.scheduled.eventDetails
                              .typeText[productionEvent.type]
                          }{" "}
                          Event:
                        </b>{" "}
                        {formatDate(productionEvent.start)}
                        {/*&nbsp;(
                        {formatProductionEventDuration(
                          productionEvent.duration
                        )}
                        )*/}
                      </Typography>
                    </AlertTitle>
                  </Alert>
                </Grid>
              )}
              <Grid
                alignItems="center"
                container
                direction="row"
                item
                spacing={1}
              >
                <Grid
                  alignItems="center"
                  container
                  direction="row"
                  item
                  justifyContent="flex-start"
                  xs={6}
                >
                  <Grid item>
                    <Typography display="inline" variant="h5">
                      <b>{participantNameTitleText}</b>
                    </Typography>
                  </Grid>

                  {participantStatus && (
                    <Grid item>
                      <ParticipantStatusChip status={participantStatus} />
                      &nbsp;
                    </Grid>
                  )}
                  <Grid item>
                    <DeviceInfoIcons
                      deviceInfo={
                        isQueue ? participant.deviceInfo : latestSessionDevice
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end" xs={6}>
                  <TooltipButton
                    color="warning"
                    disabled={isInCall}
                    text="Unassign Participant"
                    title={
                      isInCall
                        ? "Cannot unassign participant when in call"
                        : "Remove assigned participant from this Control Panel"
                    }
                    onClick={() => {
                      if (temporary) {
                        setOpenSaveParticipantModal(true);
                      } else {
                        endAssignment();
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                alignItems="center"
                container
                direction="row"
                item
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item>
                  <MoveParticipantButton
                    currentScreener={currentScreener}
                    screeners={screeners}
                  />
                </Grid>
                {type === participantTypeEnums.VIP && (
                  <Grid item>
                    <InvitationOptions
                      hasReceivedInvitation={hasReceivedInvitation}
                      isAssigned={true}
                      participantGuid={guid}
                      reloadContactsCallback={async () => {
                        await loadContacts();
                      }}
                      //twoWaySmsEnabled={twoWaySmsEnabled} // Suppressed 10/3/2023 for merge purposes
                      reloadSessionsCallback={async () => {
                        await loadSessions();
                      }}
                      screenerView={true}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <br />
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={activeTab}
              variant="scrollable"
              onChange={handleTabChange}
            >
              {!waitingForConnection && <Tab label={"Call"} value={1} />}
              <Tab label={isQueue ? "Contact/Selfie" : "Contact"} value={2} />
              <Tab label="Device & Connection" value={3} />
              {isQueue && (
                <Tab
                  label={`Chat (${newMessagesCount}${
                    newMessagesCount > 0 ? "*" : ""
                  })`}
                  value={4}
                />
              )}
              {!isQueue && [
                <Tab label="Notes" value={5} />,
                /*<Tab label="Events" value={6} />,*/
              ]}
              {!isQueue && userHasAdminPermission && (
                <Tab label="Session Activity" value={7} />
              )}
            </Tabs>
            <TabPanel index={1} value={activeTab}>
              <CallButtons
                currentEndpoint={currentScreener}
                deviceInfo={
                  isQueue ? participant.deviceInfo : latestSessionDevice
                }
                parentFuncs={parentFuncs.callButtons}
                participant={participant}
                recommendedApp={recommendedApp}
              />
            </TabPanel>
            <TabPanel index={2} value={activeTab}>
              <AssignedParticipantInfo
                participant={participant}
                onInfoChange={loadContacts}
              />
            </TabPanel>
            <TabPanel index={3} value={activeTab}>
              <DeviceInfo
                deviceInfo={
                  isQueue ? participant.deviceInfo : latestSessionDevice
                }
              />
            </TabPanel>
            {isQueue && (
              <TabPanel index={4} value={activeTab}>
                <ChatDisplay
                  callerID={id}
                  chatMessages={chatHistory}
                  chatOpened={chatOpened}
                  chatToServer={parentFuncs.assignedCaller.chatToServer}
                  hideChat={false}
                />
              </TabPanel>
            )}
            {!isQueue && (
              <Fragment>
                <TabPanel index={5} value={activeTab}>
                  <ParticipantNoteListView participantGuid={guid} />
                </TabPanel>

                {/*<TabPanel index={6} value={activeTab}>
                  <ParticipantEvents participant={participant} />
                </TabPanel>*/}
              </Fragment>
            )}

            {!isQueue && userHasAdminPermission && (
              <Fragment>
                <TabPanel index={7} value={activeTab}>
                  <SessionEventLogsTab
                    participantGuid={guid}
                    session={latestSession}
                  />
                </TabPanel>
              </Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>
      {type === participantTypeEnums.VIP && openSaveParticipantModal && (
        <SaveParticipantDialog
          endAssignment={endAssignment}
          networkDiagnosticState={participant?.networkDiagnosticState}
          participantConnected={participant?.connected}
          participantGuid={guid}
        />
      )}
    </Fragment>
  );
};

export default ParticipantSummary;
