import { createReducer } from "@reduxjs/toolkit";
import { UserPrefsState } from "./types";
import { setDisplayMilitary, setTimeZone } from "./actions";
import { localTimeZone, localStorageKey } from "../../utilities/definitions";

const localStorageDisplayMilitaryItem = localStorage.getItem(
  localStorageKey.militaryTime
);
const localStorageTimeZone = localStorage.getItem(localStorageKey.timeZone);

const initialDisplayMilitary = localStorageDisplayMilitaryItem === "true";
const initialTimeZone = localStorageTimeZone
  ? decodeURI(localStorageTimeZone)
  : localTimeZone;

const initialState: UserPrefsState = {
  displayMilitary: initialDisplayMilitary,
  timeZone: initialTimeZone,
};

export const userPrefsReducer = createReducer<UserPrefsState>(
  initialState,
  (builder) => {
    builder
      .addCase(setDisplayMilitary, (state, action) => {
        state.displayMilitary = action.payload;
      })
      .addCase(setTimeZone, (state, action) => {
        state.timeZone = action.payload;
      });
  }
);
