import type { OktaAuthOptions } from "@okta/okta-auth-js";

import { OktaAuth } from "@okta/okta-auth-js";
import { OKTA_CLIENT_ID, OKTA_ISSUER } from "../config";

export const oidcConfig: OktaAuthOptions = {
  clientId: OKTA_CLIENT_ID,
  issuer: OKTA_ISSUER,
  pkce: true,
  postLogoutRedirectUri: window.location.origin,
  redirectUri: `${window.location.origin}/implicit/callback/`,
  scopes: ["openid", "profile", "email", "offline_access"],
  tokenManager: {
    autoRemove: true,
    autoRenew: true,
    secure: true,
    storage: "localStorage",
    syncStorage: true,
  },
};

export const oktaClient = new OktaAuth(oidcConfig);

// the 'groups' property was removed from the types definition, but still exists on the User object
export type User = Awaited<ReturnType<typeof OktaAuth.prototype.getUser>>;
export type ExtendedUser = User & {
  groups?: string[];
};
