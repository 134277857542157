import type { FunctionComponent } from "react";
import type { SubmitHandler } from "react-hook-form";
import type { InferType } from "yup";

import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useHttpRequest,
  useParticipantSessionLogs,
  useParticipantSessions,
  useSelector,
  useSnackbar,
} from "../hooks";
import TextField from "../components/TextField";
import * as template from "./template";
import * as textFieldFormTemplate from "./BaseParticipantExpandedRow/template";
import LimitedBackdrop from "../components/LimitedBackdrop";
import { isEmpty } from "ramda";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import {
  branding,
  ParticipantSessionEventLogType,
} from "../utilities/definitions";
import TextFieldForm from "../components/TextFieldForm";
import {
  emailValidationSchema,
  phoneValidationSchema,
} from "../utilities/validators";
import colorDefinitions from "../utilities/colorDefinitions";
import CustomTooltip from "../components/Tooltip";

const schema = yup.object().shape({
  customMessage: yup.string().max(254),
});

export type Contact = {
  guid: string;
  type: string;
  value: string;
  phoneIsInternational: boolean;
  phoneIsSmsCapable: boolean;
};

type InvitationFormFields = InferType<typeof schema>;

type Props = {
  disableEditing?: boolean;
  initialContacts?: any[];
  open: boolean;
  participantGuid: string;
  productionEventGuid?: string;
  onClose: () => void;
  reloadCallback?: () => Promise<void>;
};

const SendInvitationDialog: FunctionComponent<Props> = (props) => {
  const {
    disableEditing = false,
    initialContacts,
    open,
    participantGuid,
    productionEventGuid,
    onClose,
    reloadCallback,
  } = props;

  const [contacts, setContacts] = useState<any[]>(initialContacts ?? []);

  const [participantRequest, participantRequestState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}`,
    {
      method: "get",
    }
  );

  const loadContacts = useCallback(async () => {
    const data = await participantRequest({});

    if (data) {
      setContacts(data.contacts);
    }
  }, [participantRequest]);

  useEffect(() => {
    if (open) {
      if (!initialContacts) {
        loadContacts();
      }
    }
    return () => {
      setContacts([]);
    };
  }, [initialContacts, open, setContacts]);

  /**Unused at this time. */
  const isCalendarInvite = false; //Boolean(productionEventGuid);

  const { enqueueSnackbar } = useSnackbar();

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );
  const participants = useSelector((state) => state.queue.participants);
  const username = useSelector((state) => state.queue.userInfo.displayName);

  const fullName = participants.find(
    (p) => p.guid === participantGuid
  )?.fullName;

  const { latestSession: session, fetchSessions } = useParticipantSessions(
    participantGuid,
    true,
    { error: null, running: false }
  );

  const { eventLogs } = useParticipantSessionLogs(
    participantGuid,
    session?.guid
  );

  const [createContactRequest, createContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/contacts`,
    {
      method: "post",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const [deleteContactRequest, deleteContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/contacts/`,
    {
      method: "delete",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const [updateContactRequest, updateContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/contacts/`,
    {
      method: "put",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const calendarInviteSent = Boolean(
    eventLogs.find(
      ({ data, type }) =>
        type === ParticipantSessionEventLogType.InvitationSent && data?.ical
    )
  );

  const [sendCalendarInvitation, sendCalendarInvitationState] = useHttpRequest(
    `productions/${productionGuid}/events/${productionEventGuid}/invite`,
    {
      admin: true,
      method: "get",
      searchParams: {
        participantGuid,
        sessionGuid: session?.guid,
      },
      onError: () => {
        enqueueSnackbar("Failed to send calendar invitation.", {
          variant: "error",
        });
      },
      onSuccess: () => {
        enqueueSnackbar(`Calendar invitation sent.`, {
          variant: "info",
        });

        handleClose();
      },
    }
  );

  const [sendInvitation, sendInvitationState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/sessions/${
      session?.guid ?? ""
    }/invite`,
    {
      method: "get",
      searchParams: {
        accountGuid,
        productionGuid,
      },
      onError: () => {
        enqueueSnackbar("Failed to send invitation.", {
          variant: "error",
        });
      },
      onSuccess: () => {
        enqueueSnackbar(`Invitation sent.`, {
          variant: "success",
        });

        handleClose();
      },
    }
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      customMessage: "",
    },
    resolver: yupResolver(schema),
  });

  function handleClose() {
    setContacts([]);
    reset();
    onClose();
  }

  function handleSelectedChange(event: React.ChangeEvent<HTMLInputElement>) {
    let tempInvitationContacts = Array.from(new Set(invitationContacts));

    const {
      target: { checked, value },
    } = event;

    if (checked) {
      tempInvitationContacts.push(value);
    } else {
      const selectedIndex = tempInvitationContacts.indexOf(value); // -1?
      tempInvitationContacts.splice(selectedIndex, 1);
    }

    setInvitationContacts(tempInvitationContacts);
  }

  useEffect(() => {
    if (open) {
      fetchSessions();
    }
    return () => {
      /* Do nothing */
    };
  }, [fetchSessions, open]);

  const [contactOptions, setContactOptions] = useState<any[]>([]);

  const [
    contactsContainInvalidSmsPhoneNumber,
    setContactsContainInvalidSmsPhoneNumber,
  ] = useState<boolean>(false);

  const [
    contactsContainInternationalPhoneNumber,
    setContactsContainInternationalPhoneNumber,
  ] = useState<boolean>(false);

  const [contactsContainUkPhoneNumber, setContactsContainUkPhoneNumber] =
    useState<boolean>(false);

  const [hasMultipleEmailContacts, setHasMultipleEmailContacts] =
    useState<boolean>(false);
  const [hasMultiplePhoneContacts, setHasMultiplePhoneContacts] =
    useState<boolean>(false);

  const [, setPreselectedContactOptions] = useState<string[]>([]);
  const [invitationContacts, setInvitationContacts] = useState<string[]>([]);

  useEffect(() => {
    let newContactOptions =
      contacts?.filter(
        ({ type }) =>
          type === "email" || type === "phone" /* && !isCalendarInvite*/
      ) ?? [];

    const newPreselectedContactOptions: string[] = newContactOptions
      .filter(({ phoneIsInternational, phoneIsSmsCapable, type, value }) => {
        const checkIfCanBeInvited = () => {
          if (!value) {
            return false;
          }
          switch (type) {
            case "phone":
              if (!phoneIsSmsCapable) {
                return false;
              }
              if (phoneIsInternational) {
                return false;
              }
              return true;
            default:
              // "email" etc.
              return true;
          }
        };

        const canBeInvited = type === "phone" ? checkIfCanBeInvited() : true;
        return canBeInvited;
      })
      .map(({ guid }) => {
        return guid;
      });

    if (!newContactOptions.some((contact) => contact.type === "email")) {
      newContactOptions.push({
        guid: undefined,
        isPrimary: false,
        phoneIsInternational: false,
        phoneIsSmsCapable: false,
        type: "email",
        value: "",
      });
    }

    if (!newContactOptions.some((contact) => contact.type === "phone")) {
      newContactOptions.push({
        guid: undefined,
        isPrimary: false,
        phoneIsInternational: false,
        phoneIsSmsCapable: true,
        type: "phone",
        value: "",
      });
    }

    // Sort so that "phone" entries are first, and primary entries, and subsort so "primary" is listed first
    // Note that "isPrimary" does not exist as of 10/6/2023
    newContactOptions.sort((a, b) => {
      if (
        a.type === "phone" &&
        (b.type !== "phone" || !b.hasOwnProperty("isPrimary"))
      ) {
        return -1; // 'phone' comes before other types and those without isPrimary
      } else if (
        (a.type !== "phone" || !a.hasOwnProperty("isPrimary")) &&
        b.type === "phone"
      ) {
        return 1; // Other types and those without isPrimary come after 'phone'
      } else if (
        a.hasOwnProperty("isPrimary") &&
        !b.hasOwnProperty("isPrimary")
      ) {
        return -1; // Objects with isPrimary come before those without
      } else if (
        !a.hasOwnProperty("isPrimary") &&
        b.hasOwnProperty("isPrimary")
      ) {
        return 1; // Objects without isPrimary come after those with
      } else if (a.isPrimary && b.isPrimary) {
        return 0; // Both are primary or both are not, no change needed
      } else if (a.isPrimary && !b.isPrimary) {
        return -1; // 'isPrimary: true' comes before 'isPrimary: false'
      } else {
        return 1; // 'isPrimary: false' comes after 'isPrimary: true'
      }
    });

    setContactOptions(newContactOptions);
    setPreselectedContactOptions(newPreselectedContactOptions);
    setInvitationContacts(newPreselectedContactOptions);

    setContactsContainInvalidSmsPhoneNumber(
      contacts?.some(({ type, phoneIsInternational, phoneIsSmsCapable }) => {
        if (type === "phone" && phoneIsInternational) return false;
        return type === "phone" && !phoneIsSmsCapable;
      })
    );
    setContactsContainInternationalPhoneNumber(
      contacts?.some(({ type, phoneIsInternational }) => {
        return type === "phone" && phoneIsInternational;
      })
    );
    setContactsContainUkPhoneNumber(
      contacts?.some(({ type, value }) => {
        return type === "phone" && value.includes("+44");
      })
    );

    const hasMultipleOfType = (a: any[], targetType: string) => {
      const typeCount = a.filter((c) => c.type === targetType).length;
      return typeCount > 1;
    };

    setHasMultipleEmailContacts(hasMultipleOfType(contactOptions, "email"));
    setHasMultiplePhoneContacts(hasMultipleOfType(contactOptions, "phone"));
  }, [
    contacts,
    setContactsContainInvalidSmsPhoneNumber,
    setContactsContainInternationalPhoneNumber,
    setContactsContainUkPhoneNumber,
    setContactOptions,
    setHasMultipleEmailContacts,
    setHasMultiplePhoneContacts,
    setInvitationContacts,
    setPreselectedContactOptions,
  ]);

  const onSubmit: SubmitHandler<InvitationFormFields> = useCallback(
    async (data) => {
      const { customMessage } = data;

      const searchParams = {
        customMessage,
        username,
        invitationContacts: invitationContacts.toString(),
      };

      if (isCalendarInvite) {
        await sendCalendarInvitation({
          searchParams,
        });
      } else {
        await sendInvitation({
          searchParams,
        });
      }
    },
    [
      sendCalendarInvitation,
      sendInvitation,
      invitationContacts,
      isCalendarInvite,
      username,
    ]
  );

  const submitHandler = handleSubmit(onSubmit);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(
      participantRequestState.loading ||
        sendCalendarInvitationState.loading ||
        sendInvitationState.loading ||
        createContactRequestState.loading ||
        deleteContactRequestState.loading ||
        updateContactRequestState.loading
    );
  }, [
    participantRequestState,
    sendCalendarInvitationState,
    sendInvitationState,
    createContactRequestState,
    deleteContactRequestState,
    updateContactRequestState,
  ]);

  const handleInviteLinkCopy = useCallback(() => {
    enqueueSnackbar(`StageDoor invitation link was copied to your clipboard.`, {
      variant: "success",
    });
  }, [enqueueSnackbar]);

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(session?.invitationUrl);
    handleInviteLinkCopy();
  };

  return (
    <>
      <LimitedBackdrop open={isLoading} />
      <Dialog id="send-invitation-dialog" open={open} onClose={handleClose}>
        <DialogTitle>
          {isCalendarInvite
            ? `Send ${fullName} a ${branding.stagedoor} calendar invitation`
            : `Send ${fullName} a ${branding.stagedoor} invitation`}
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="subtitle2">
                Select the method(s) for the invitation to be sent
              </Typography>
            </Grid>

            <Grid
              container
              item
              sx={{
                height: 65 * contactOptions.length,
                maxHeight: 65 * 4,
                minHeight: 65 * contactOptions.length,
                overflowY: "auto",
                paddingRight: "16px",
              }}
            >
              {contactOptions?.map((contact) => {
                const {
                  guid,
                  isPrimary = false,
                  phoneIsInternational,
                  phoneIsSmsCapable,
                  type,
                  value,
                } = contact;

                const isInInvitationsContacts =
                  invitationContacts.includes(guid);

                const isPhoneContact = type === "phone";

                const phoneIsUk = isPhoneContact && value.includes("+44");

                const determineIfShouldBeDisabled = () => {
                  if (disableEditing) return true;
                  if (guid) {
                    if (isPhoneContact) {
                      if (phoneIsUk) return true;
                      else if (phoneIsInternational) return false;
                      else return !phoneIsSmsCapable;
                    }
                  } else {
                    return true;
                  }
                  return false;
                };

                const shouldBeDisabled = determineIfShouldBeDisabled();

                const phoneNumberWarningText =
                  phoneIsInternational && !phoneIsUk
                    ? `${branding.airfirst} has determined that phone number ${value} is international (non-US) and may not be able to receive SMS messages.`
                    : `${branding.airfirst} has determined that phone number ${value} cannot receive SMS messages.`;

                return (
                  <Grid
                    alignItems="center"
                    container
                    item
                    justifyContent="space-between"
                    key={guid}
                  >
                    <Grid
                      alignItems="center"
                      container
                      item
                      justifyContent="space-between"
                    >
                      <Grid
                        alignItems="center"
                        item
                        justifyContent="flex-start"
                        style={{
                          display: "flex",
                          marginLeft: "-8px",
                          minWidth: "315px",
                        }}
                      >
                        <Checkbox
                          checked={isInInvitationsContacts && !shouldBeDisabled}
                          disabled={shouldBeDisabled}
                          inputProps={{ "aria-label": "controlled" }}
                          value={guid}
                          onChange={handleSelectedChange}
                        />
                        <TextFieldForm
                          createRequest={(name, value) =>
                            createContactRequest({
                              body: {
                                type: name,
                                value,
                              },
                            })
                          }
                          deleteRequest={async (_name) => {
                            if (guid) {
                              deleteContactRequest({
                                route: guid,
                              });
                            }
                          }}
                          disabled={disableEditing}
                          initialValue={value}
                          label={`${isPhoneContact ? "SMS" : "Email"}`}
                          name={type}
                          placeholder={
                            textFieldFormTemplate.formFields[type].placeholder
                          }
                          updateRequest={async (_name, value) => {
                            if (guid) {
                              updateContactRequest({
                                route: guid,
                                body: {
                                  value,
                                },
                              });
                            }
                          }}
                          validationSchema={
                            isPhoneContact
                              ? phoneValidationSchema.optional()
                              : emailValidationSchema.optional()
                          }
                          onSave={async () => {
                            await reloadCallback?.();
                            await loadContacts();
                          }}
                        />
                      </Grid>

                      <Grid
                        alignItems="center"
                        item
                        justifyContent="flex-end"
                        style={{ display: "flex" }}
                      >
                        <Grid item sx={{ padding: "14px" }}>
                          {isPhoneContact &&
                          (!phoneIsSmsCapable || phoneIsInternational) ? (
                            <CustomTooltip
                              placement="right"
                              title={phoneNumberWarningText}
                            >
                              <span
                                style={{
                                  color:
                                    phoneIsInternational && !phoneIsUk
                                      ? colorDefinitions.YELLOW
                                      : colorDefinitions.RED,
                                  fontSize: "18px",
                                }}
                              >
                                {"⚠"}
                              </span>
                            </CustomTooltip>
                          ) : null}
                          {isPrimary &&
                          ((type === "phone" && hasMultiplePhoneContacts) ||
                            (type === "email" && hasMultipleEmailContacts)) ? (
                            <CustomTooltip
                              placement="right"
                              title={`This option was selected as the participant's preferred ${type} contact.`}
                            >
                              <span
                                style={{
                                  color: colorDefinitions.GREEN,
                                  fontSize: "18px",
                                }}
                              >
                                {"✓"}
                              </span>
                            </CustomTooltip>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid item>
              <TextField
                control={control}
                disabled={invitationContacts.length === 0}
                errors={errors.customMessage}
                fullWidth
                label={template.inputs.message.label}
                multiline
                name="customMessage"
                placeholder={template.inputs.message.placeholder}
              />
            </Grid>

            {isCalendarInvite && (
              <Grid item>
                <Alert color="info" severity="info">
                  <AlertTitle>About This Invitation</AlertTitle>A{" "}
                  {branding.stagedoor} link will be attached to the calendar
                  event the participant receives. If the invitation is
                  cancelled, the participant's calendar event will <b>NOT</b> be
                  automatically updated.
                </Alert>
              </Grid>
            )}

            {isCalendarInvite && calendarInviteSent && (
              <Grid item>
                <Alert severity="warning">
                  A calendar invitation with the current {branding.stagedoor}{" "}
                  link has already been sent to this participant
                </Alert>
              </Grid>
            )}

            {(contactsContainInvalidSmsPhoneNumber ||
              contactsContainUkPhoneNumber) && (
              <Grid item>
                <Alert severity="error">
                  {`${branding.airfirst} has determined that the participant's${
                    contactsContainUkPhoneNumber ? " UK" : ""
                  } phone number cannot receive ${
                    branding.stagedoor
                  } invitation SMS messages.${
                    contactsContainUkPhoneNumber
                      ? "  This is due to a change made by UK-based telecom providers effective June 1, 2023."
                      : ""
                  }  We recommend copying the link or sending via Email.`}
                </Alert>
              </Grid>
            )}
            {contactsContainInternationalPhoneNumber &&
              !contactsContainUkPhoneNumber && (
                <Grid item>
                  <Alert severity="warning">
                    {`${branding.airfirst} has determined that the participant's international (non-US) phone number may not be able to receive ${branding.stagedoor} invitation SMS messages.  If they have not received their invitation after you send it, we recommend copying the link or sending via Email.`}
                  </Alert>
                </Grid>
              )}

            {invitationContacts.length === 0 && (
              <Grid item>
                <Alert severity="error">
                  At least one invitation method must be chosen.
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={!session?.guid} onClick={copyLinkToClipboard}>
            Copy Link
          </Button>
          <Button
            disabled={invitationContacts.length === 0 && isEmpty(errors)}
            onClick={submitHandler}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendInvitationDialog;
