import React, { MouseEvent, useState } from "react";
import AlertDialog from "./AlertDialog";
import TooltipButton, { TooltipButtonProps } from "./TooltipButton";

type Action = {
  label: string;
  onClick: any;
  isDisabled?: boolean;
};

interface Props extends TooltipButtonProps {
  actions: Action[];
  dialogTitle: string;
  dialogText?: string;
}

const AlertDialogButton: React.FC<Props> = (props) => {
  const { actions, children, dialogTitle, dialogText, ...rest } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function handleClickOpen(
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ): void {
    event.stopPropagation();
    setIsOpen(true);
  }

  function handleClose(): void {
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <TooltipButton {...rest} onClick={handleClickOpen} />
      <AlertDialog
        actions={actions}
        children={children}
        dialogText={dialogText}
        dialogTitle={dialogTitle}
        disableBackdropClick={false}
        handleClose={handleClose}
        isOpen={isOpen}
      />
    </React.Fragment>
  );
};

export default AlertDialogButton;
