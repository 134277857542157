import type { FunctionComponent } from "react";
import { useCallback, useMemo } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "../../hooks/redux";
import TooltipButton from "../TooltipButton";
import { parseMultiboxName } from "../../utilities/utilityFunctions";
import mustache from "mustache";
import * as templates from "./templates";
import { changePage } from "../../store/queue/actions";

type Props = {
  canUserControlCrowdViews: any;
  endpoint: any;
  isUserAdmin: boolean;
  isUserFullscreener;
  isUserPrescreener: boolean;
};

const EnterEndpoint: FunctionComponent<Props> = (props) => {
  const {
    canUserControlCrowdViews,
    endpoint,
    isUserAdmin,
    isUserFullscreener,
    isUserPrescreener,
  } = props;

  const dispatch = useDispatch();

  const {
    authState: { isAuthenticated },
  } = useOktaAuth();

  const { canAccess, tooltip } = useMemo(() => {
    const endpointName = endpoint ? parseMultiboxName(endpoint.name) : "";

    let canAccess: boolean = true;
    let tooltip: string = mustache.render(
      templates.enterEndpointButton.tooltip.default,
      { endpoint: endpointName }
    );

    if (endpoint.qManagerInControl) {
      canAccess = false;
      tooltip = mustache.render(templates.enterEndpointButton.tooltip.inUse, {
        endpoint: endpointName,
        producer: endpoint.qManagerInControl.username,
      });
    } else {
      if (isUserAdmin) {
        canAccess = true;
      } else if (endpoint.qManagerInControl) {
        canAccess = false;
      } else if (endpoint.name.startsWith("multibox_")) {
        canAccess = canUserControlCrowdViews;
      } else if (endpoint.name.toLowerCase().includes("prescreen")) {
        canAccess = isUserPrescreener;
      } else {
        canAccess = isUserFullscreener;
      }

      if (!canAccess) {
        tooltip = mustache.render(
          templates.enterEndpointButton.tooltip.notPermitted,
          {
            endpoint: endpointName,
          }
        );
      }
    }

    return {
      canAccess,
      tooltip,
    };
  }, [
    canUserControlCrowdViews,
    endpoint,
    isUserAdmin,
    isUserFullscreener,
    isUserPrescreener,
  ]);

  const handleClick = useCallback(() => {
    if (isAuthenticated) {
      dispatch(
        changePage({
          newPage: "Screener",
          screenerName: endpoint.name,
        })
      );
    }
  }, [endpoint.name, dispatch, isAuthenticated]);

  return (
    <TooltipButton
      color="warning"
      isDisabled={!canAccess}
      text={mustache.render(templates.enterEndpointButton.text, {
        endpoint: parseMultiboxName(endpoint.name),
      })}
      title={tooltip}
      onClick={handleClick}
    />
  );
};

export default EnterEndpoint;
