import type { Theme } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { createTheme } from "@mui/material/styles";
import { amber, blueGrey, lightGreen, red } from "@mui/material/colors";
import { localStorageKey } from "../utilities/definitions";

const THEME_NAME_DARK = "dark";
const THEME_NAME_LIGHT = "light";
type ThemeName = typeof THEME_NAME_DARK | typeof THEME_NAME_LIGHT;

const lightTheme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 15,
  },
  palette: {
    info: blueGrey,
    success: lightGreen,
    mode: THEME_NAME_LIGHT,
    secondary: red,
    warning: amber,
  },
});

const darkTheme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 15,
  },
  palette: {
    info: blueGrey,
    success: lightGreen,
    mode: THEME_NAME_DARK,
    secondary: red,
    warning: amber,
  },
});

export const useTheme = () => {
  const storedThemeName = localStorage.getItem(localStorageKey.currentTheme);

  const [currentTheme, setCurrentTheme] = useState<ThemeName>(
    Boolean(storedThemeName) ? (storedThemeName as ThemeName) : THEME_NAME_DARK
  );

  const [theme, setTheme] = useState<Theme>(
    currentTheme === THEME_NAME_LIGHT ? lightTheme : darkTheme
  );

  const [loading, setLoading] = useState<boolean>(false);

  const toggleTheme = async () => {
    setLoading(true);
    const newThemeName =
      currentTheme === THEME_NAME_DARK ? THEME_NAME_LIGHT : THEME_NAME_DARK;

    localStorage.setItem(localStorageKey.currentTheme, String(newThemeName));

    Promise.resolve()
      .then(() => {
        setCurrentTheme(newThemeName);
      })
      .then(() => {
        setLoading(false);
        window.location.reload();
      });
  };

  const isDarkMode = useMemo(() => {
    return currentTheme === THEME_NAME_DARK;
  }, [currentTheme]);

  useEffect(() => {
    setTheme(currentTheme === THEME_NAME_LIGHT ? lightTheme : darkTheme);
    return () => {};
  }, [currentTheme, setTheme]);

  return { isDarkMode, currentTheme, loading, theme, toggleTheme };
};
