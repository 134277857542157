export type Role =
  | "callProducer"
  | "callScreener"
  | "callScheduler"
  | "callBooker"
  | "queueAdmin"
  | "queueTech"
  | "stageDoor"
  | "multiboxController"
  | "audienceQueue";

export default function checkIfRoleChoicesAllows(
  _roleChoices: any,
  _desiredRole: Role
) {
  const matchingRole = _roleChoices.find((role) => {
    return (
      role.name.toLocaleLowerCase() === _desiredRole.toLocaleLowerCase() &&
      role.value === true
    );
  });
  return Boolean(matchingRole);
}
