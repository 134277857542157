import React from "react";
import { getRows, getSeats } from "./CrowdViewHelperFuncs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MultiboxStatusPreviewForScreenerCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      seatInfo: false,
      currentParticipant: false,
    };
    this.renderSeatStatus = this.renderCompleteSeatStatus.bind(this);
    this.renderIndividualSeatStatus =
      this.renderIndividualSeatStatus.bind(this);
    this.renderRowOfSeats = this.renderRowOfSeats.bind(this);
  }

  renderCompleteSeatStatus(_endpointName, _seatStatus) {
    const rows = getRows(_endpointName);
    const seats = getSeats(_endpointName);

    let callRowsToReturn = [];

    for (var i = 1; i < rows + 1; i++) {
      callRowsToReturn.push(
        <React.Fragment key={i}>
          {this.renderRowOfSeats(
            [i * seats + 1 - seats, i * seats],
            rows,
            seats,
            _seatStatus
          )}
        </React.Fragment>
      );
    }

    return (
      <div style={{ width: "100%", height: "100%" }}>{callRowsToReturn}</div>
    );
  }

  renderRowOfSeats(_range, _rows, _seats, _seatStatus) {
    let results = [];

    for (var i = _range[0]; i < _range[1] + 1; i++) {
      results.push(
        <React.Fragment key={i}>
          {this.renderIndividualSeatStatus(_seats, _seatStatus[i])}
        </React.Fragment>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          alignItems: "stretch",
          display: "flex",
          minWidth: "100%",
          maxHeight: `${100 / _rows}%`,
          minHeight: `${100 / _rows}%`,
        }}
      >
        {results}
      </div>
    );
  }

  renderIndividualSeatStatus(_seats, _seatInfo) {
    let styleProp = {
      width: `${100 / _seats}%`,
      height: "inherit",
      margin: "1px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor:
        _seatInfo.status === "unoccupied"
          ? "#9D9D9D"
          : _seatInfo.status === "calling"
          ? "#FF0000"
          : "#2196F3",
      color:
        _seatInfo.status === "unoccupied"
          ? "#000000"
          : _seatInfo.status === "calling"
          ? "#FFFFFF"
          : "#FFFFFF",
    };

    return (
      <div key={`${_seatInfo.id}`} style={styleProp}>
        <span style={{ height: "100%" }}>
          {_seatInfo.status === "unoccupied" ? (
            ""
          ) : (
            <FontAwesomeIcon icon={["fas", "user-alt"]} />
          )}
        </span>
      </div>
    );
  }

  render() {
    const { currentScreener } = this.props;
    const { name, multibox } = currentScreener;
    const { seatStatus } = multibox;

    return (
      <div
        className="text-center"
        style={{ width: "100%", height: "12vh", maxWidth: "100% !important" }}
      >
        {this.renderCompleteSeatStatus(name, seatStatus)}
      </div>
    );
  }
}

export default MultiboxStatusPreviewForScreenerCard;
