import type { Role } from "../utilities/checkIfRoleChoicesAllows";

import { useCallback, useMemo } from "react";
import { useSelector } from "./redux";
import { intersection } from "ramda";

type LowercaseRole = Lowercase<Role>;

export const useAuthorizer = () => {
  const roleChoices = useSelector((state) => state.queue.roleChoices);

  const userRoles: LowercaseRole[] = useMemo(
    () =>
      roleChoices
        .filter(({ value }) => value)
        .map(({ name }) => name.toLowerCase() as LowercaseRole),
    [roleChoices]
  );

  return useCallback(
    (required: LowercaseRole | LowercaseRole[]) => {
      if (Array.isArray(required)) {
        return intersection(required, userRoles).length > 0;
      }

      return Boolean(userRoles.find((role) => role === required));
    },
    [userRoles]
  );
};

export const useProductionEventAuthorization = () => {
  const authorize = useAuthorizer();

  return useMemo(
    () => ({
      authorizedToScheduleAirEvents: authorize("callbooker"),
      authorizedToScheduleTestEvents: authorize("callscheduler"),
    }),
    [authorize]
  );
};
