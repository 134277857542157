import type { FunctionComponent } from "react";
import { memo, useEffect, useMemo, useState } from "react";
import OpenInBrowser from "@mui/icons-material/OpenInBrowser";
import { CircularProgress } from "@mui/material";
import { useTheme } from "../../../hooks/theme";
import CustomTooltip from "../../../components/Tooltip";

type Props = {
  allowPopout?: boolean;
  id: string;
  feed?: string;
  friendlyName: string;
};

const MonitoringFeed: FunctionComponent<Props> = (props) => {
  const { isDarkMode } = useTheme();
  const { allowPopout = false, id, feed = undefined, friendlyName } = props;

  const [loading, setLoading] = useState<boolean>(Boolean(feed));

  const handleOpenStreamClick = () => {
    var w = window.open(
      feed,
      `${friendlyName}-feed`,
      `width=${window.innerWidth},height=${window.innerHeight},resizable,scrollbars,status`
    );
  };

  useEffect(() => {
    // Cleanup loading spinner when switching between Control Panels
    return () => {
      if (loading) {
        setLoading(false);
      }
    };
  }, []);

  const hasFeed = useMemo(() => {
    return Boolean(feed);
  }, [feed]);

  return (
    <div
      id="monitoring-feed-container"
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {loading && (
        <CircularProgress
          style={{
            margin: "1.5px",
            position: "absolute",
            zIndex: 499,
          }}
        />
      )}
      {hasFeed && (
        <iframe
          className="monitoring-feed"
          id={id}
          src={feed}
          style={{
            border: `0.5px solid ${isDarkMode ? "white" : "black"}`,
          }}
          onLoad={() => {
            setLoading(false);
          }}
        />
      )}
      {/*!hasFeed && (
        <div
          className="monitoring-feed"
          style={{
            alignItems: "center",
            backgroundColor: "black",
            border: `0.5px solid ${isDarkMode ? "white" : "black"}`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ color: "white" }}>No Preview Stream</div>
        </div>
      )*/}
      {hasFeed && (
        <CustomTooltip title={`Click to open stream for ${friendlyName}`}>
          <OpenInBrowser
            fontSize="medium"
            style={{
              backgroundColor: isDarkMode ? "black" : "white",
              border: `1.5px solid ${isDarkMode ? "white" : "black"}`,
              color: isDarkMode ? "white" : "black",
              display: !allowPopout || loading ? "none" : undefined,
              margin: "1.5px",
              position: "absolute",
              right: "1.5px",
              top: "1.5px",
              zIndex: 500,
            }}
            onClick={handleOpenStreamClick}
          />
        </CustomTooltip>
      )}
    </div>
  );
};

export default memo(MonitoringFeed);
