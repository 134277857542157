import {
  FunctionComponent,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormHelperText, Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TooltipButton from "../../components/TooltipButton";
import CQ from "../../utilities/socket/CQ";
import * as template from "./LowerThirdTab.template";
import mustache from "mustache";

const useStyles = makeStyles(() => ({
  form: {
    maxWidth: "450px",
  },
  fullscreenWarning: {
    color: "#d9534f",
  },
  lineChangeWarning: {
    color: "#0275d8",
  },
  lineField: {
    display: "flex",
    flexGrow: 1,
  },
  lineFieldContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  toggleDisplayBtn: {
    height: "100px",
    width: "100px",
  },
}));

const schema = yup.object().shape(
  {
    line1: yup.string().trim(),
    line2: yup.string().trim(),
  },
  [["line1", "line2"]]
);

type Props = {
  cmp: any;
};

const LowerThird: FunctionComponent<Props> = (props) => {
  const { cmp } = props;
  const { isAwake, isCallFullscreen, isUpdatingSession, lowerThird } = cmp;
  const { format, status } = lowerThird;
  const { isDisplaying, line1, line2 } = status;

  const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);

  const classes = useStyles();

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: useMemo(
      () => ({
        line1: line1,
        line2: line2,
      }),
      [line1, line2]
    ),
    resolver: yupResolver(schema),
  });

  const [watchedLine1, watchedLine2] = watch(["line1", "line2"]);

  useEffect(() => {
    if (line1 !== watchedLine1 || line2 !== watchedLine2) {
      setHasMadeChanges(true);
    } else {
      setHasMadeChanges(false);
    }
  }, [line1, line2, watchedLine1, watchedLine2]);

  // reset default form values
  useEffect(() => {
    reset({
      line1: line1,
      line2: line2,
    });
  }, [line1, line2, reset]);

  function toggleDisplayClick() {
    CQ.emit("setL3Display", { display: !isDisplaying });
  }

  function onSubmit(data) {
    CQ.emit("updateL3Lines", {
      line1: data.line1 ?? "",
      line2: data.line2 ?? "",
    });
  }

  const toggleDisplayButtonTooltip = useMemo(() => {
    if (!isAwake || isUpdatingSession) {
      return isDisplaying
        ? template.buttons.hide.tooltips.noSession
        : template.buttons.show.tooltips.noSession;
    } else if (isCallFullscreen) {
      return isDisplaying
        ? template.buttons.hide.tooltips.fullscreen
        : template.buttons.show.tooltips.fullscreen;
    }

    return isDisplaying
      ? template.buttons.hide.tooltips.default
      : template.buttons.show.tooltips.default;
  }, [isAwake, isCallFullscreen, isDisplaying, isUpdatingSession]);

  const updateLinesButtonTooltip = useMemo(() => {
    return hasMadeChanges
      ? template.buttons.submit.tooltips.default
      : template.buttons.submit.tooltips.noChanges;
  }, [hasMadeChanges]);

  return (
    <Fragment>
      {format ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid alignItems="center" container item md={3} sm={4} xs={12}>
              <TooltipButton
                className={classes.toggleDisplayBtn}
                clickTimeout={{
                  seconds: 3,
                }}
                color={isDisplaying ? "secondary" : "success"}
                isDisabled={!isAwake || isCallFullscreen || isUpdatingSession}
                text={
                  isDisplaying
                    ? template.buttons.hide.text
                    : template.buttons.show.text
                }
                title={toggleDisplayButtonTooltip}
                onClick={toggleDisplayClick}
              />
            </Grid>
            <Grid
              className={classes.form}
              container
              item
              justifyContent="flex-start"
              md={9}
              sm={8}
              spacing={2}
              xs={12}
            >
              {hasMadeChanges && (
                <Grid container item justifyContent="flex-start" xs={12}>
                  <FormHelperText className={classes.lineChangeWarning}>
                    {template.lineChangeWarning}
                  </FormHelperText>
                </Grid>
              )}
              <Grid
                alignItems="flex-end"
                container
                item
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography>
                    <b>{template.fields.line1.label}</b>
                  </Typography>
                </Grid>
                <Grid className={classes.lineFieldContainer} item>
                  {hasMadeChanges && (
                    <div>
                      <FormHelperText>
                        {mustache.render(template.fields.line1.previousValue, {
                          value: line1,
                        })}
                      </FormHelperText>
                    </div>
                  )}
                  <Controller
                    control={control}
                    name="line1"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={classes.lineField}
                        placeholder={template.fields.line1.placeholder}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                alignItems="flex-end"
                container
                item
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Typography>
                    <b>{template.fields.line2.label}</b>
                  </Typography>
                </Grid>
                <Grid className={classes.lineFieldContainer} item>
                  {hasMadeChanges && (
                    <FormHelperText>
                      {mustache.render(template.fields.line2.previousValue, {
                        value: line2,
                      })}
                    </FormHelperText>
                  )}
                  <Controller
                    control={control}
                    name="line2"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={classes.lineField}
                        placeholder={template.fields.line2.placeholder}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="flex-end">
                <TooltipButton
                  htmlType="submit"
                  isDisabled={!hasMadeChanges}
                  text={template.buttons.submit.text}
                  title={updateLinesButtonTooltip}
                  onClick={() => {}}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      ) : (
        <strong>{template.notConfigured}</strong>
      )}
    </Fragment>
  );
};

export default LowerThird;
