import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CQ from "../../../../utilities/socket/CQ";
import AlertDialog from "../../../../components/AlertDialog";
import AlertDialogButton from "../../../../components/AlertDialogButton";
import TooltipButton from "../../../../components/TooltipButton";
import FixCMPCallMenu from "../../CallButtons/FixCMPCallMenu";
import { branding } from "../../../../utilities/definitions";
import { icons } from "../../../../definitions/icons";
import {
  determineMutedParticipantCount,
  determineSeatedParticipantCount,
} from "../../../../utilities/crowdViewStatus";

const MultiboxParticipantControls = (props) => {
  const { canControlMultibox, currentScreener } = props;
  const {
    allocatedCMP,
    assignedMultiboxParticipants,
    id,
    inCall,
    multibox,
    muteControlsEnabled,
    popControlsEnabled,
    waitingForCallStateChange,
  } = currentScreener;
  const { poppedParticipantID, seatStatus } = multibox;
  const { enableCrowdViewMute, enableCrowdViewPop } = useSelector(
    (state) => state.queue.features
  );

  const displayUnmuteAllParticipantsWarning = Boolean(poppedParticipantID);

  const hasParticipantsAssigned =
    Object.keys(assignedMultiboxParticipants).length > 0;

  const [unmuteAllParticipantsAlertOpen, setUnmuteAllParticipantsAlertOpen] =
    useState(false);

  const determineMuteAllButtonIcon = () => {
    if (allParticipantsMuted) {
      return icons.muted;
    }
    return icons.notMuted;
  };

  const removePoppedParticipant = () => {
    CQ.emit("setPoppedCrowdViewParticipant", {
      id: undefined,
    });
  };

  const toggleAllParticipantsMuted = () => {
    if (allParticipantsMuted) {
      if (displayUnmuteAllParticipantsWarning) {
        setUnmuteAllParticipantsAlertOpen(true);
      } else {
        CQ.emit("unmuteAllParticipants");
      }
    } else {
      CQ.emit("muteAllParticipants");
    }
  };

  const seatedParticipantCount = determineSeatedParticipantCount(seatStatus);

  const mutedParticipantCount = determineMutedParticipantCount(seatStatus);

  // don't count popped participant if there is one
  const allParticipantsMuted = poppedParticipantID
    ? seatedParticipantCount - 1 === mutedParticipantCount
    : seatedParticipantCount === mutedParticipantCount;

  const muteAllButtonIcon = determineMuteAllButtonIcon();

  const muteAllButtonColor = allParticipantsMuted ? "secondary" : "error";
  const muteAllButtonTitle = `${
    allParticipantsMuted
      ? `Unmute All${
          poppedParticipantID
            ? ` (${branding.bop}ped participant will stay unmuted)`
            : ""
        }`
      : `Mute All${
          poppedParticipantID
            ? ` (${branding.bop}ped participant will stay unmuted)`
            : ""
        }`
  }${muteControlsEnabled ? "" : " - Please Enable Muting Controls"}`;

  const unPopButtonColor = "info";
  const unPopButtonTitle = ` Un-${branding.bop}${
    popControlsEnabled
      ? poppedParticipantID
        ? ""
        : ` - Please ${branding.bop} a participant`
      : !poppedParticipantID
      ? ` - Please Enable ${branding.bop} Controls `
      : ""
  }`;

  const displayPopWarningTitle = `Change ${branding.bop}`;
  const displayPopWarningText = `Are you sure you want to ${
    poppedParticipantID ? `Un-${branding.bop}` : branding.bop
  } this participant?`;

  const displayUnmuteAllParticipantsWarningTitle = "Unmute All?";
  const displayUnmuteAllParticipantsWarningText = `Are you sure you want to unmute all participants while there is an active ${branding.bop}?`;

  const isUnassignAllButtonDisabled =
    !canControlMultibox ||
    !hasParticipantsAssigned ||
    inCall ||
    waitingForCallStateChange;

  return (
    <React.Fragment>
      {enableCrowdViewMute && (
        <Grid item>
          <TooltipButton
            color={muteAllButtonColor}
            icon={muteAllButtonIcon}
            isDisabled={!muteControlsEnabled || seatedParticipantCount === 0}
            title={muteAllButtonTitle}
            onClick={toggleAllParticipantsMuted}
          />
        </Grid>
      )}
      {enableCrowdViewPop && (
        <Grid item>
          <AlertDialogButton
            actions={[
              {
                label: "Confirm",
                onClick: () => removePoppedParticipant(),
              },
            ]}
            color={unPopButtonColor}
            dialogText={displayPopWarningText}
            dialogTitle={displayPopWarningTitle}
            icon={icons.participantPopped}
            iconFlip="vertical"
            isDisabled={!poppedParticipantID}
            style={
              poppedParticipantID ? { color: "yellow" } : { color: "#C0C0C0" }
            }
            title={unPopButtonTitle}
          />
        </Grid>
      )}
      <Grid item>
        <FixCMPCallMenu
          appName="Multibox"
          endpointID={id}
          isDisabled={!(allocatedCMP && canControlMultibox && inCall)}
        />
      </Grid>
      <Grid item>
        <TooltipButton
          color="secondary"
          icon={["fas", "users-slash"]}
          isDisabled={isUnassignAllButtonDisabled}
          title={
            !inCall
              ? hasParticipantsAssigned
                ? "End All Assignments"
                : "No Assigned Participants"
              : `Cannot End All Assignments while ${branding.crowdview} is active`
          }
          onClick={() => CQ.emit("unassignAllFromMultibox", {})}
        />
      </Grid>
      <AlertDialog
        actions={[
          {
            label: "Confirm",
            onClick: () => {
              CQ.emit("unmuteAllParticipants");
            },
          },
        ]}
        children={null}
        dialogText={displayUnmuteAllParticipantsWarningText}
        dialogTitle={displayUnmuteAllParticipantsWarningTitle}
        disableBackdropClick={true}
        handleClose={() => {
          setUnmuteAllParticipantsAlertOpen(false);
        }}
        isOpen={unmuteAllParticipantsAlertOpen}
      />
    </React.Fragment>
  );
};

export default MultiboxParticipantControls;
