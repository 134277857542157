import React from "react";
import { useSelector } from "react-redux";
import {
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CrowdPreviewer from "./CrowdPreviewer";
import MultiboxCallButton from "./Controls/MultiboxCallButton";
import MultiboxParticipantControls from "./Controls/MultiboxParticipantControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branding } from "../../../utilities/definitions";
import { icons } from "../../../definitions/icons";
import CQ from "../../../utilities/socket/CQ";
import { determineMutedParticipantCount } from "../../../utilities/crowdViewStatus";
import { RootState } from "../../../store";

const useStyles = makeStyles({
  root: {
    padding: 10,
  },
  topControls: {
    marginBottom: 5,
  },
});

type Props = {
  callButtonFuncs: any;
  currentScreener: any;
  canControlMultibox: boolean;
};

const MultiboxControls: React.FC<Props> = (props) => {
  const { callButtonFuncs, currentScreener, canControlMultibox } = props;
  const {
    alias,
    assignedMultiboxParticipants,
    inCall,
    multibox,
    muteControlsEnabled,
    popControlsEnabled,
  } = currentScreener;

  const classes = useStyles();

  const { poppedParticipantID, seatStatus } = multibox;
  const { enableCrowdViewMute, enableCrowdViewPop } = useSelector(
    (state: RootState) => state.queue.features
  );

  const hasParticipantsAssigned =
    Object.keys(assignedMultiboxParticipants).length > 0;
  const hasAssignedSeats =
    Object.values(seatStatus).findIndex((seat: any) => seat.participant) > -1;

  const handleMuteControlsToggled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    CQ.emit("setMuteControlsEnabledState", {
      muteControlsEnabled: event.target.checked,
    });
  };

  const handlePopControlsToggled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    CQ.emit("setPopControlsEnabledState", {
      popControlsEnabled: event.target.checked,
    });
  };

  const mutedParticipantCount = determineMutedParticipantCount(seatStatus);

  return (
    <Paper className={classes.root}>
      <Grid
        className={classes.topControls}
        container
        item
        justifyContent="space-between"
        xs={12}
      >
        <Grid container item xs={2}>
          <MultiboxCallButton
            callButtonFuncs={callButtonFuncs}
            canControlMultibox={canControlMultibox}
            currentScreener={currentScreener}
          />
        </Grid>

        <Grid container item justifyContent="flex-end" spacing={1} xs={5}>
          <MultiboxParticipantControls
            canControlMultibox={canControlMultibox}
            currentScreener={currentScreener}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {enableCrowdViewPop && (
          <FormControlLabel
            control={
              <Switch
                checked={popControlsEnabled}
                className="crowdview-control-switch"
                color="primary"
                disabled={
                  poppedParticipantID ||
                  !inCall ||
                  (!hasParticipantsAssigned && !hasAssignedSeats)
                }
                id="popControlsToggle"
                size="small"
                onChange={handlePopControlsToggled}
              />
            }
            label={
              <React.Fragment>
                <FontAwesomeIcon
                  flip="vertical"
                  icon={icons.participantPopped}
                />{" "}
                <Typography display="inline" variant="subtitle1">
                  <b>{branding.bop} Controls:</b>
                </Typography>{" "}
                <Typography display="inline" variant="subtitle2">
                  {`${
                    inCall
                      ? popControlsEnabled
                        ? "On"
                        : "Off"
                      : `Please launch ${branding.crowdview}`
                  }`}
                </Typography>
              </React.Fragment>
            }
          />
        )}
      </Grid>
      <Grid container item xs={12}>
        {enableCrowdViewMute && (
          <FormControlLabel
            checked={muteControlsEnabled}
            control={
              <Switch
                className="crowdview-control-switch"
                color="primary"
                disabled={
                  (mutedParticipantCount > 0 && muteControlsEnabled) ||
                  !inCall ||
                  (!hasParticipantsAssigned && !hasAssignedSeats)
                }
                id="muteControlsToggle"
                size="small"
              />
            }
            label={
              <React.Fragment>
                <FontAwesomeIcon icon={icons.muted} />{" "}
                <Typography display="inline" variant="subtitle1">
                  <b>Muting Controls:</b>
                </Typography>{" "}
                <Typography display="inline" variant="subtitle2">
                  {`${
                    inCall
                      ? muteControlsEnabled
                        ? "On"
                        : "Off"
                      : `Please launch ${branding.crowdview}`
                  }`}
                </Typography>
              </React.Fragment>
            }
            onChange={handleMuteControlsToggled}
          />
        )}
      </Grid>
      <Grid container id="crowd-previewer-container" item xs={12}>
        <CrowdPreviewer
          alias={alias}
          canControlMultibox={canControlMultibox}
          currentScreener={currentScreener}
        />
      </Grid>
    </Paper>
  );
};

export default MultiboxControls;
