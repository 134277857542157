import type { FunctionComponent, MouseEventHandler } from "react";
import type { SubmitHandler } from "react-hook-form";
import type { InferType, AnySchema } from "yup";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Typography } from "@mui/material";
import TextField from "./TextField";
import TooltipButton from "./TooltipButton";
import { convertPhoneNumberToE164 } from "../utilities/utilityFunctions";

export interface TextFieldFormProps {
  className?: string;
  disabled?: boolean;
  initialValue: any;
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  validationSchema: AnySchema;
  createRequest?: (name: any, value: any) => Promise<void>;
  deleteRequest?: (name: any) => Promise<void>;
  updateRequest: (name: any, value: any) => Promise<void>;
  onSave?: () => Promise<void> | void;
}

const TextFieldForm: FunctionComponent<TextFieldFormProps> = (props) => {
  const {
    className = undefined,
    disabled = false,
    initialValue,
    label,
    name,
    placeholder,
    required = false,
    validationSchema,
    createRequest,
    deleteRequest,
    updateRequest,
    onSave,
  } = props;

  const [editing, setEditing] = useState<boolean>(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      [name]: initialValue,
    },
    resolver: yupResolver(
      yup.object().shape({
        [name]: validationSchema,
      })
    ),
  });

  useEffect(() => {
    setValue(name, initialValue);
  }, [initialValue, name, setValue]);

  const onSubmit: SubmitHandler<InferType<typeof validationSchema>> = async (
    data
  ) => {
    let value = data[name];
    if (name === "phone") {
      const parsedPhoneValue = convertPhoneNumberToE164(value);
      value = parsedPhoneValue;
    }

    if (initialValue) {
      if (initialValue !== value) {
        if (value) {
          await updateRequest?.(name, value);
        } else {
          await deleteRequest?.(name);
        }
      }
    } else {
      await createRequest(name, value);
    }

    await onSave?.();
    setEditing(false);
  };

  const handleCancelClick: MouseEventHandler<HTMLButtonElement> = () => {
    setValue(name, initialValue);
    setEditing(false);
  };

  const handleEditClick: MouseEventHandler<HTMLButtonElement> = () => {
    setEditing(true);
  };

  const handleSaveClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    handleSubmit(onSubmit)(e);
  };

  const value = watch(name);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {editing ? (
        <TextField
          className={className}
          control={control}
          disabled={disabled}
          errors={errors[name]}
          InputProps={{
            endAdornment: (
              <Box sx={{ display: "inline-flex" }}>
                <TooltipButton
                  color="success"
                  component="icon"
                  disabled={
                    disabled || value === initialValue || (!value && required)
                  }
                  icon={["fas", "check"]}
                  size="small"
                  title={
                    disabled
                      ? "You cannot change or delete entry at this time."
                      : value === initialValue || (!value && required)
                      ? "Please make changes or Cancel Editing."
                      : "Save Changes."
                  }
                  onClick={handleSaveClick}
                />

                <TooltipButton
                  color="error"
                  component="icon"
                  disabled={disabled}
                  icon={["fas", "times"]}
                  size="small"
                  title={
                    disabled
                      ? "You cannot change or delete entry at this time."
                      : "Cancel Editing."
                  }
                  variant="text"
                  onClick={handleCancelClick}
                />
              </Box>
            ),
          }}
          label={label}
          name={name}
          placeholder={placeholder}
          size="small"
        />
      ) : (
        <Box alignItems="center" sx={{ display: "inline-flex" }}>
          <Typography fontWeight="bold">{label}</Typography>
          :&nbsp;
          <Typography>{initialValue || "Not Provided"}</Typography>
          <TooltipButton
            color={disabled ? undefined : "primary"}
            component="icon"
            disabled={disabled}
            icon={["fas", "edit"]}
            iconSize="xs"
            size="small"
            title={
              disabled
                ? "You cannot change or delete entry at this time."
                : "Change or delete entry."
            }
            onClick={handleEditClick}
          />
        </Box>
      )}
    </form>
  );
};

export default TextFieldForm;
