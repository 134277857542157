export const buttons = {
  copyLink: {
    tooltip: "Copy {{app}} link",
  },
  sendLink: {
    tooltip: "Send link via {{transport}}",
  },
  edit: {
    text: "Edit Contact Info",
    canEditTooltip: "Edit Contact Information",
    cannotEditTooltip: "Cannot edit participant at this time",
  },
  editSave: {
    text: "Save Changes",
    tooltip: "Save Changes",
  },
  editCancel: {
    text: "Cancel Editing",
    tooltip: "Cancel Editing",
  },
};

export const notProvidedText = "Not Provided";

export const formFields = {
  fullName: {
    label: "Full Name (Required)",
    placeholder: "ex., John Smith",
  },
  email: {
    label: "Email Address (Optional)",
    placeholder: "ex., welcome@nextologies.com",
  },
  facetime: {
    label: "Apple/FaceTime ID (Optional)",
    placeholder: "Can be an email address or phone number",
  },
  phone: {
    label: "Phone Number (Optional)",
    placeholder: "ex, +12122357019",
  },
  phone_number: {
    label: "Phone Number (Optional)",
    placeholder: "ex, +12122357019",
  },
};

export const snackbars = {
  copyLink: "{{app}} call link was copied to your clipboard.",
  sendLink: "{{app}} call link was sent via {{transport}} to {{contact}}.",
};
