import { Alert, AlertTitle } from "@mui/material";

const ActivationRequired = () => {
  return (
    <div className="layout-noCustomization" id="activationRequiredContent">
      <br />
      <Alert icon={false} severity="error">
        <AlertTitle>
          <h2 style={{ margin: "auto" }}>
            <strong>Activation Required</strong>
          </h2>
        </AlertTitle>
        <span>
          It seems your account creation is not complete. Please contact your
          VCC business point of contact.
        </span>
        <br />
        <span>
          If you believe you have received this message in error, please
          contact&nbsp;
          <a href="mailto:vcc-support@nextologies.com">
            VCC tech support (vcc-support@nextologies.com)
          </a>
          &nbsp;(vcc-support@nextologies.com)
        </span>
      </Alert>
    </div>
  );
};

export default ActivationRequired;
