import React from "react";
import { Col, Container, Form } from "react-bootstrap";
import CustomTooltip from "../../components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branding } from "../../utilities/definitions";

const styles = {
  done: { color: "#66E21A" },
  error: { color: "#FF0000" },
  pending: { color: "#FFFF00" },
  other: { color: "#F0F0F0" },
};

const onMouseOver = (event) => {
  event.target.style.backgroundColor = "#FFFF80";
};
const onMouseOut = (event) => {
  event.target.style.backgroundColor = "#FFFFFF";
};

class CMPFileListEntry extends React.Component {
  constructor(props) {
    super(props);

    this.renderStatusDisplay = this.renderStatusDisplay.bind(this);
    this.renderErrorDisplay = this.renderErrorDisplay.bind(this);
  }

  renderStatusDisplay(_status, _timecode_required) {
    let recordingStatusStyle = styles.other;
    let timecodeStatusStyle = styles.other;
    let uploadStatusStyle = styles.other;

    let recordingStatusText = "Recording pending";
    let timecodeStatusText = _timecode_required
      ? "Timecode pending"
      : "Timecode not required";
    let uploadStatusText = "Upload pending";

    switch (_status) {
      case "upload_done":
        recordingStatusStyle = styles.done;
        timecodeStatusStyle = _timecode_required ? styles.done : styles.other;
        uploadStatusStyle = styles.done;
        recordingStatusText = "Recording complete";
        timecodeStatusText = _timecode_required
          ? "Timecode complete"
          : "Timecode not required";
        uploadStatusText = "Upload complete";
        break;
      case "upload_pending":
        recordingStatusStyle = styles.done;
        timecodeStatusStyle = _timecode_required ? styles.done : styles.other;
        uploadStatusStyle = styles.pending;
        recordingStatusText = "Recording complete";
        timecodeStatusText = _timecode_required
          ? "Timecode complete"
          : "Timecode not required";
        uploadStatusText = "Upload pending";
        break;
      case "upload_error":
        recordingStatusStyle = styles.done;
        timecodeStatusStyle = _timecode_required ? styles.done : styles.other;
        uploadStatusStyle = styles.error;
        recordingStatusText = "Recording complete";
        timecodeStatusText = _timecode_required
          ? "Timecode complete"
          : "Timecode not required";
        uploadStatusText = "Upload error";
        break;
      case "timecode_done":
        recordingStatusStyle = styles.done;
        timecodeStatusStyle = _timecode_required ? styles.done : styles.other;
        uploadStatusStyle = styles.other;
        recordingStatusText = "Recording complete";
        timecodeStatusText = _timecode_required
          ? "Timecode complete"
          : "Timecode not required";
        uploadStatusText = "Upload pending";
        break;
      case "timecode_pending":
        recordingStatusStyle = styles.done;
        timecodeStatusStyle = _timecode_required
          ? styles.pending
          : styles.other;
        uploadStatusStyle = styles.other;
        recordingStatusText = "Recording complete";
        timecodeStatusText = _timecode_required
          ? "Timecode pending"
          : "Timecode not required";
        uploadStatusText = "Upload pending";
        break;
      case "timecode_error":
        recordingStatusStyle = styles.done;
        timecodeStatusStyle = _timecode_required ? styles.error : styles.other;
        uploadStatusStyle = styles.other;
        recordingStatusText = "Recording complete";
        timecodeStatusText = _timecode_required
          ? "Timecode error"
          : "Timecode not required";
        uploadStatusText = "Upload pending";
        break;
      case "recording_done":
        recordingStatusStyle = styles.done;
        timecodeStatusStyle = styles.other;
        uploadStatusStyle = styles.other;
        recordingStatusText = "Recording complete";
        timecodeStatusText = _timecode_required
          ? "Timecode pending"
          : "Timecode not required";
        uploadStatusText = "Upload pending";
        break;
      case "recording_error":
        recordingStatusStyle = styles.error;
        timecodeStatusStyle = styles.other;
        uploadStatusStyle = styles.other;
        recordingStatusText = "Recording error";
        timecodeStatusText = _timecode_required
          ? "Timecode pending"
          : "Timecode not required";
        uploadStatusText = "Upload pending";
        break;
      default:
        // Do nothing
        break;
    }

    return (
      <div className="flex-container space-between">
        <CustomTooltip placement={"bottom"} title={recordingStatusText}>
          <span>
            <FontAwesomeIcon
              icon={["fas", "file-video"]}
              style={recordingStatusStyle}
            />
          </span>
        </CustomTooltip>
        &nbsp;
        <CustomTooltip placement={"bottom"} title={timecodeStatusText}>
          <span>
            <FontAwesomeIcon
              icon={["fas", "toolbox"]}
              style={timecodeStatusStyle}
            />
          </span>
        </CustomTooltip>
        &nbsp;
        <CustomTooltip placement={"bottom"} title={uploadStatusText}>
          <span>
            <FontAwesomeIcon
              icon={["fas", "cloud-upload-alt"]}
              style={uploadStatusStyle}
            />
          </span>
        </CustomTooltip>
      </div>
    );
  }

  renderErrorDisplay(_status, _error) {
    let errorMessage = "No error reported";
    if (_error !== false) {
      switch (_status.substring(0, _status.indexOf("_"))) {
        case "recording":
          errorMessage = "Error with recording";
          break;
        case "timecode":
          errorMessage = "Error with timecode emplacement";
          break;
        case "upload":
          errorMessage = "Error with upload";
          break;
        default:
          errorMessage = "Unknown error";
          break;
      }
    }

    return (
      <React.Fragment>
        &nbsp;
        <CustomTooltip placement={"bottom"} title={errorMessage}>
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            style={
              _error === false ? { color: "#F0F0F0" } : { color: "#FF0000" }
            }
          />
        </CustomTooltip>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Form.Row
        style={
          this.props.key === this.props.filesCount
            ? {
                borderTop: "1px solid #F0F0F0",
                borderBottom: "1px solid #F0F0F0",
                backgroundColor: "#FFFFFF",
              }
            : { borderTop: "1px solid #F0F0F0", backgroundColor: "#FFFFFF" }
        }
        onMouseEnter={(event) => onMouseOver(event)}
        onMouseOut={(event) => onMouseOut(event)}
      >
        <Col className="text-left" xs={9}>
          {this.props.file.name.replace(".mov", "")}
        </Col>
        <Col className="text-left" xs={2}>
          {this.renderStatusDisplay(
            this.props.file.status,
            this.props.timecode_required
          )}
        </Col>
        <Col className="text-center" xs={1}>
          {this.renderErrorDisplay(
            this.props.file.status,
            this.props.file.error
          )}
        </Col>
      </Form.Row>
    );
  }
}

class CMPFileStatusDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.renderCMPFileList = this.renderCMPFileList.bind(this);
  }

  renderCMPFileList(_files, _timecode_required) {
    let fileListEntries = [];

    for (var i = 0; i < _files.length; i++) {
      fileListEntries.push(
        <CMPFileListEntry
          file={_files[i]}
          filesCount={_files.length - 1}
          key={i}
          timecode_required={_timecode_required}
        />
      );
    }

    return (
      <div style={{ width: "100% !important" }}>
        <Container
          fluid
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            minHeight: "17vh",
            height: "17vh",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {fileListEntries}
        </Container>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Form.Row>
          <Col className="text-left" xs={9}>
            <b>Recording Name</b>
          </Col>
          <Col className="text-left" xs={2}>
            <b>Status</b>
          </Col>
          <Col className="text-left" xs={1}>
            <b>Error</b>
          </Col>
        </Form.Row>
        {
          /* TODO probably move paginator down here */
          this.props.files?.length > 0 &&
            this.renderCMPFileList(
              this.props.files,
              this.props.timecode_required
            )
        }
        {this.props.files?.length === 0 && (
          <Form.Row>
            <Col xs={12}>No {branding.eavesdrop} Recording Files</Col>
          </Form.Row>
        )}
      </React.Fragment>
    );
  }
}

export default CMPFileStatusDisplay;
