import { branding } from "../../../utilities/definitions";

export const endpointList = {
  filterAndSort: {
    sortButtonAlphabetical: "Sorting Alphabetically.  Click to reverse.",
    sortButtonReverseAlphabetical:
      "Sorting Reverse Alphabetically.  Click to sort alphabetically.",
    title: "Control Panel Filtering",
    options: {
      crowdview: `${branding.crowdview} Control Panels`,
      prescreen: "Prescreen Control Panels",
      transmission: "Air Control Panels",
    },
  },
  noEndpoints: {
    title: "No Control Panels",
    text: "No Control Panels can be displayed based on your current filter selections.",
  },
};
