import { Menu, MenuItem } from "@mui/material";
import CQ from "../../utilities/socket/CQ.js";

const CMPList = (props) => {
  const {
    anchorEl,
    cmps,
    currentEpisode,
    currentScreener,
    currentShow,
    inCall,
    onClick,
  } = props;

  const disabledBecauseEndpointIsInCall = currentScreener && Boolean(inCall);
  const cmpList = Object.values(cmps);

  const items =
    cmpList.length > 0
      ? Object.values(cmps)
          .sort((cmp1, cmp2) =>
            cmp1.friendlyName.localeCompare(cmp2.friendlyName)
          )
          .map((cmp, i) => ({
            children: `Allocate ${cmp.friendlyName}`,
            key: i,
            onClick: (event) => {
              event.stopPropagation();
              CQ.emit("allocateCMP", {
                cmp: cmp,
                screener: currentScreener,
                show: currentShow,
                episode: currentEpisode,
              });
              onClick();
            },
          }))
      : [
          {
            children: "No machines available",
            onClick: (event) => {
              event.stopPropagation();
              onClick();
            },
          },
        ];

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClick}>
      {items?.map((item, index) => (
        <MenuItem
          disabled={disabledBecauseEndpointIsInCall}
          key={index}
          style={{
            "& .MuiListItem-root.Mui-disabled": {
              opacity: 0.4,
              color: "black !important",
            },
          }}
          onClick={(event) => {
            if (!disabledBecauseEndpointIsInCall) {
              item.onClick?.(event);
            } else {
              onClick();
            }
          }}
        >
          {item.children}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CMPList;
