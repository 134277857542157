import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { initSentryConfiguration } from "@wolzienllc/vcc-react-commons";

//CSS
import "bootstrap/dist/css/bootstrap.css";
import "./css/App.css";
import "./css/index.css";
import "./css/oktaWidget.css";

// custom icon lib
import "./customIconLibrary";

import { Provider } from "react-redux";
import { store } from "./store";
import { SocketContext, SocketService } from "./utilities/socket";
import {
  ENVIRONMENT_NAME,
  PACKAGE_NAME,
  PACKAGE_VERSION,
  SENTRY_DSN,
} from "./config";

// Initialize Sentry client SDK configuration.
initSentryConfiguration({
  appName: PACKAGE_NAME,
  appVersion: PACKAGE_VERSION,
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT_NAME,
});

const socketService: SocketService = new SocketService();

ReactDOM.render(
  <SocketContext.Provider value={socketService}>
    <Provider store={store}>
      <Router>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </Router>
    </Provider>
  </SocketContext.Provider>,
  document.getElementById("root")
);
