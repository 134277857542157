import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import CQ from "../../../utilities/socket/CQ.js";
import CustomTooltip from "../../../components/Tooltip";
import { confirmAlert } from "react-confirm-alert";

const EavesDrop = (props) => {
  const { cmp } = props;
  const {
    programEavesDropper: eavesdropper = false,
    macAddress,
    network,
  } = cmp;

  const emitData = {
    macAddress,
    network,
    type: "program",
  };

  if (eavesdropper) {
    let recordingButtonTitle =
      "Program Recording Status: " +
      eavesdropper.status.toUpperCase() +
      (eavesdropper.status === "Recording"
        ? ", click to STOP recording"
        : ", click to START recording");

    return (
      <Button
        variant="outlined"
        onClick={() => {
          if (eavesdropper.status === "Recording") {
            confirmAlert({
              message: "Are you sure you want to STOP recording PROGRAM?",
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    CQ.emit("stopEavesDropping", emitData);
                  },
                },
                {
                  label: "No",
                  onClick: () => {
                    /* Do nothing */
                  },
                },
              ],
            });
          } else {
            CQ.emit("startEavesDropping", emitData);
          }
        }}
      >
        <CustomTooltip
          placement={"bottom"}
          style={{ width: "100%", paddingRight: "3px" }}
          title={recordingButtonTitle}
        >
          <span>
            {eavesdropper.status === "Recording" && (
              <Spinner animation="grow" size="sm" variant="danger" />
            )}
            {eavesdropper.status !== "Recording" &&
              !(eavesdropper.status.indexOf("Error") > -1) && (
                <FontAwesomeIcon icon={["fas", "file-video"]} />
              )}
            {eavesdropper.status !== "Recording" &&
              eavesdropper.status.indexOf("Error") > -1 && (
                <FontAwesomeIcon icon={["fas", "exclamation"]} />
              )}
          </span>
        </CustomTooltip>
      </Button>
    );
  } else {
    return <div />;
  }
};

//Function to map the redux state to object properties
const mapStateToProps = (state) => {
  const { programCMP } = state.queue;

  return {
    cmp: programCMP,
  };
};

export default connect(mapStateToProps)(EavesDrop);
