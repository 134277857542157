import type { FunctionComponent } from "react";

import { useMemo, useState } from "react";
import mustache from "mustache";
import { useSelector } from "../../hooks/redux";
import { parseISO } from "date-fns";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TooltipButton from "../TooltipButton";
import AlertDialog from "../AlertDialog";
import NoteField from "./NoteField";
import {
  DeleteParticipantNoteData,
  UpdateParticipantNoteData,
} from "../../REST/notesRequestsDefinitions";
import { useDateFormatter, useHttpRequest, useSnackbar } from "../../hooks";
import * as template from "./template";

enum SelectionChoiceCommands {
  EDIT = "edit",
  DELETE = "delete",
}

const noteModificationOptions = [
  {
    action: SelectionChoiceCommands.EDIT,
    text: template.viewNote.selections.edit,
  },
  {
    action: SelectionChoiceCommands.DELETE,
    text: template.viewNote.selections.delete,
  },
];

const useStyles = makeStyles((_theme) => ({
  container: {
    marginRight: "5px",
    marginTop: "5px",
    maxWidth: "100%",
  },
  contents: {
    marginTop: "1vh",
    paddingBottom: "2vh",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    height: "fit-content",
    maxHeight: "250px",
    overflowY: "auto",
    width: "100%",
  },
  contentsMini: {
    marginTop: "1vh",
    paddingBottom: "1vh",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    height: "fit-content",
    maxHeight: "180px",
    overflowY: "auto",
  },
  textDisplay: {
    overflowWrap: "anywhere",
  },
  top: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    margin: 0,
    maxWidth: "100%",
    padding: "2px",
    width: "100%",
  },
  topMini: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    margin: 0,
    maxWidth: "100%",
    padding: "1px",
    paddingBottom: 0,
    width: "100%",
  },
}));

export interface ParticipantNoteItem {
  guid: number;
  author: string;
  content: string;
  createdAt: string;
  modifiedAt: string;
}

interface Props {
  mini?: boolean;
  noteBeingModified: number | null;
  participantNote: ParticipantNoteItem;
  participantGuid: string;
  refetch: () => void;
  setNoteBeingModified: any;
}

const ViewNote: FunctionComponent<Props> = (props) => {
  const {
    mini = false,
    noteBeingModified = null,
    participantNote,
    participantGuid,
    refetch,
  } = props;
  const classes = useStyles();
  const { guid, author, content, createdAt, modifiedAt } = participantNote;

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );
  const pageView = useSelector((state) => state.queue.page);
  const userInfo = useSelector((state) => state.queue.userInfo);

  const { enqueueSnackbar } = useSnackbar();

  const formatDate = useDateFormatter();

  const [deleteRequest] = useHttpRequest<DeleteParticipantNoteData>(
    `admin_proxy/participants/${participantGuid}/notes/${guid}`,
    {
      method: "delete",
      searchParams: {
        accountGuid,
        productionGuid,
      },
      onSuccess: () => {
        enqueueSnackbar(template.viewNote.snackbars.noteRemoved, {
          variant: "info",
        });

        refetch();
      },
    }
  );

  const [updateRequest] = useHttpRequest<
    EmptyObject,
    UpdateParticipantNoteData
  >(`admin_proxy/participants/${participantGuid}/notes/${guid}`, {
    method: "put",
    body: {
      accountGuid,
      productionGuid,
    },
    onSuccess: () => {
      enqueueSnackbar(template.viewNote.snackbars.noteUpdated, {
        variant: "info",
      });

      refetch();
    },
  });

  const { displayName: currentUser } = userInfo;

  const currentUserIsAuthor = currentUser === author;

  const [noteModificationDisabled, setNoteModificationDisabled] =
    useState<boolean>(currentUserIsAuthor);

  const formattedNoteCreationOrEditDateAndTime = formatDate(
    parseISO(modifiedAt || createdAt)
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [displayDeletionConfirmation, setDisplayDeletionConfirmation] =
    useState<boolean>(false);

  function handleSelect(choice: SelectionChoiceCommands): void {
    switch (choice) {
      case SelectionChoiceCommands.EDIT:
        props.setNoteBeingModified(guid, false);
        setIsEditing(true);
        setDisplayDeletionConfirmation(false);
        break;
      case SelectionChoiceCommands.DELETE:
        props.setNoteBeingModified(guid, false);
        setIsEditing(false);
        setDisplayDeletionConfirmation(true);
        break;
      default:
        break;
    }

    handleClose();
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorEl(null);
  }

  function handleCloseDeletionAlert(): void {
    props.setNoteBeingModified(null, false);
    setDisplayDeletionConfirmation(false);
  }

  function handleEditingClose(newContents: string): void {
    if (isEditing) {
      setIsEditing(false);
      if (newContents.length === 0) {
        setDisplayDeletionConfirmation(true);
      } else {
        props.setNoteBeingModified(null, false);
      }
    }
  }

  const handleEditingSave = async (newContents: string) => {
    if (newContents !== content) {
      if (newContents.length === 0) {
        setIsEditing(false);
        setDisplayDeletionConfirmation(true);
      } else {
        props.setNoteBeingModified(guid, true);
        await sendUpdatedNote(newContents);
      }
    }
  };

  useMemo(() => {
    if (currentUserIsAuthor) {
      if (noteBeingModified === null) {
        setNoteModificationDisabled(false);
      } else {
        setNoteModificationDisabled(true);
      }
    } else {
      setNoteModificationDisabled(true);
    }
  }, [currentUserIsAuthor, noteBeingModified]);

  const sendDeleteNote = async () => {
    await deleteRequest({
      searchParams: {
        id: guid,
        participant: participantGuid,
        user: currentUser,
      },
    });

    handleCloseDeletionAlert();
  };

  const sendUpdatedNote = async (newContents: string) => {
    await updateRequest({
      body: {
        user: currentUser,
        content: newContents,
      },
    });

    props.setNoteBeingModified(null, false);
    setIsEditing(false);
  };

  const onlyAuthorCanModifyToolTip = mustache.render(
    template.viewNote.tooltips.onlyAuthorCanModifyBase,
    {
      noteAuthor: author,
    }
  );

  const noteModificationTooltip = noteModificationDisabled
    ? currentUserIsAuthor
      ? template.viewNote.tooltips.notOkToModify
      : onlyAuthorCanModifyToolTip
    : template.viewNote.tooltips.okToModify;

  return (
    <TableRow>
      <Paper className={classes.container} variant="outlined">
        <Grid className="fullWidth" container direction="column">
          <Grid
            className={`${mini ? classes.topMini : classes.top} fullWidth`}
            direction={
              pageView === "producer" ? "row" : mini ? "column" : "row"
            }
            item
          >
            <Grid className="fullWidth" item>
              <Typography gutterBottom variant="subtitle2">
                <Box alignContent="left" fontWeight="fontWeightBold">
                  {author}
                </Box>
              </Typography>
            </Grid>
            <Grid className="fullWidth" item>
              <Typography gutterBottom variant="subtitle2">
                <Box alignContent="right" fontWeight="fontWeightBold">
                  {formattedNoteCreationOrEditDateAndTime}
                </Box>
              </Typography>
            </Grid>
            <Grid
              alignItems="flex-start"
              container
              direction="row"
              item
              justifyContent="flex-end"
            >
              <TooltipButton
                color={currentUserIsAuthor ? "primary" : "info"}
                icon={["fas", "ellipsis-h"]}
                isDisabled={noteModificationDisabled}
                size="small"
                title={noteModificationTooltip}
                onClick={handleClick}
              />
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handleClose}
              >
                {noteModificationOptions.map((option, i) => {
                  const { action, text } = option;
                  return (
                    <MenuItem key={i} onClick={() => handleSelect(action)}>
                      {text}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>
          </Grid>
          <Grid className={mini ? classes.contentsMini : classes.contents} item>
            {!isEditing && (
              <Typography className={classes.textDisplay}>{content}</Typography>
            )}
            {isEditing && (
              <NoteField
                contents={content}
                mini={mini}
                onClose={handleEditingClose}
                onSave={handleEditingSave}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <AlertDialog
        actions={[
          {
            label: template.viewNote.deleteNoteDialog.confirm,
            onClick: sendDeleteNote,
          },
        ]}
        children={null}
        dialogText={template.viewNote.deleteNoteDialog.text}
        dialogTitle={template.viewNote.deleteNoteDialog.title}
        disableBackdropClick={true}
        handleClose={handleCloseDeletionAlert}
        isOpen={displayDeletionConfirmation}
      />
    </TableRow>
  );
};

export default ViewNote;
