import type { FunctionComponent } from "react";
import type { RouteProps } from "react-router-dom";
import type { ExtendedUser } from "../utilities/okta";

import { Fragment, useCallback, useEffect, useState } from "react";
import { intersection } from "ramda";
import { Redirect, useRouteMatch } from "react-router-dom";
import {
  SecureRoute as AuthenticatedRoute,
  useOktaAuth,
} from "@okta/okta-react";
import { useMemoCompare } from "../hooks/memo";

export interface AuthorizedRouteProps extends RouteProps {
  requiredRoles: string[];
}

const AuthorizedRoute: FunctionComponent<AuthorizedRouteProps> = (props) => {
  const { requiredRoles, ...routeProps } = props;

  const { oktaAuth, authState } = useOktaAuth();

  const [authorized, setAuthorized] = useState<boolean | null>(null);

  const match = useRouteMatch(routeProps);
  const memoMatch = useMemoCompare(match);

  const checkAuthorization = useCallback(async () => {
    try {
      const user = (await oktaAuth?.getUser()) as ExtendedUser;

      const userRoles =
        user?.groups?.filter((group) => group.startsWith("Role_")) ?? [];

      const fulfilledRoles = intersection(userRoles, requiredRoles);

      if (fulfilledRoles.length === 0) {
        throw new Error("User doesn't have required roles.");
      }

      setAuthorized(true);
    } catch (err) {
      setAuthorized(false);
    }
  }, [oktaAuth, requiredRoles]);

  useEffect(() => {
    if (!memoMatch || !authState?.isAuthenticated) {
      setAuthorized(null);
    } else {
      checkAuthorization();
    }
  }, [authState, checkAuthorization, memoMatch]);

  if (typeof authorized !== "boolean") {
    return <Fragment />;
  }

  return authorized ? (
    <AuthenticatedRoute {...routeProps} />
  ) : (
    <Redirect to="/" />
  );
};

export default AuthorizedRoute;
