import type { FunctionComponent } from "react";
import type { Requester } from "../../../../hooks/rest";

import { useContext, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import { VideoCameraFront } from "@mui/icons-material";
import { facetimeValidationSchema } from "../../../../utilities/validators";
import { branding } from "../../../../utilities/definitions";
import TextFieldForm from "../../../../components/TextFieldForm";
import TooltipButton from "../../../../components/TooltipButton";
import { DraggableDialogContext } from "../../../../components/DraggableDialog";

type Props = {
  contacts: any[];
  deviceInfo: any;
  hasFacetimeContact: boolean;
  facetimeContact: any;
  isDisabled: boolean;
  isLoading: boolean;
  createContactRequest: Requester<unknown, unknown, unknown>;
  deleteContactRequest: Requester<unknown, unknown, unknown>;
  updateContactRequest: Requester<unknown, unknown, unknown>;
};

const DialogContent: FunctionComponent<Props> = (props) => {
  const {
    contacts,
    deviceInfo,
    hasFacetimeContact,
    facetimeContact,
    isDisabled,
    isLoading,
    createContactRequest,
    deleteContactRequest,
    updateContactRequest,
  } = props;

  const dialogContext = useContext(DraggableDialogContext);

  const titles = {
    email: "Email",
    phone: "Phone #",
  };

  const subtitles = {
    none: `${branding.airfirst} cannot find a possible Apple/FaceTime ID to suggest, or has determined that the participant is not on a FaceTime capable device.`,
    facetime: `${branding.airfirst} recommends you use the participant's Apple/FaceTime ID.`,
    phone: `${branding.airfirst} recommends you try to use the participant's ${titles.phone} for FaceTime calls, but this has not been verified.  If you know the participant's Apple/FaceTime ID, you can edit it in below.`,
    email: `${branding.airfirst} recommends you try use the participant's ${titles.email} for FaceTime calls, but this has not been verified.  If you know the participant's Apple/FaceTime ID, you can edit it in below.`,
  };

  const hasEmailContact = contacts?.some((contact) => {
    return contact.type === "email" && contact.value !== "";
  });

  const hasPhoneContact = contacts?.some((contact) => {
    return contact.type === "phone" && contact.value !== "";
  });

  const isAppleDevice =
    deviceInfo?.deviceOs?.toLowerCase() === "macos" ||
    deviceInfo?.deviceOs?.toLowerCase() === "ios";

  const recommendedContact = useMemo(() => {
    if (isAppleDevice && hasFacetimeContact) {
      return "facetime";
    } else if (isAppleDevice && !hasFacetimeContact) {
      if (deviceInfo?.deviceType === "mobile") {
        if (hasPhoneContact) {
          return "phone";
        } else {
          if (hasEmailContact) {
            return "email";
          }
        }
      } else {
        if (hasEmailContact) {
          return "email";
        } else {
          if (hasPhoneContact) {
            return "phone";
          }
        }
      }
    }
    return "none";
  }, [
    deviceInfo,
    hasEmailContact,
    hasFacetimeContact,
    hasPhoneContact,
    isAppleDevice,
  ]);

  return (
    <Grid container direction="column" spacing={1} xs={12}>
      <Grid item>
        <Typography variant="subtitle2">
          {subtitles[recommendedContact]}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextFieldForm
          createRequest={async (name, value) => {
            await createContactRequest({
              body: {
                type: name,
                value,
              },
            });
          }}
          deleteRequest={async (_name) => {
            if (hasFacetimeContact) {
              await deleteContactRequest({
                route: facetimeContact?.guid,
              });
            }
          }}
          disabled={isDisabled || isLoading}
          initialValue={facetimeContact?.value || ""}
          label={"Apple/FaceTime ID"}
          name="facetime"
          placeholder={"Enter an Apple/FaceTime ID"}
          updateRequest={async (_name, value) => {
            if (hasFacetimeContact) {
              await updateContactRequest({
                route: facetimeContact?.guid,
                body: {
                  value,
                },
              });
            } else {
              await createContactRequest({
                body: {
                  type: "facetime",
                  value,
                },
              });
            }
          }}
          validationSchema={facetimeValidationSchema.optional()}
          onSave={() => {
            dialogContext.close();
          }}
        />
      </Grid>
      <hr className="fullWidth" style={{ margin: 0 }} />
      {contacts?.length > 0 ? (
        contacts
          ?.filter((contact: any) => {
            return (
              contact.value !== "" &&
              (contact.type === "email" || contact.type === "phone")
            );
          })
          .map((contact: any) => {
            const { type, value } = contact;

            const optionIsDisabled =
              isLoading || facetimeContact?.value === value;

            const tooltip =
              !hasFacetimeContact && recommendedContact === type
                ? `${branding.airfirst} recommends you use this ${titles[type]} as an Apple/FaceTime ID, though it has not been verified.`
                : value !== facetimeContact?.value
                ? `Switch to using the participant's ${titles[type]} contact`
                : "Already being used as Apple/FaceTime ID";

            return (
              <Grid item>
                <TooltipButton
                  className="fullWidth"
                  color="primary"
                  endIcon={
                    recommendedContact === type ? (
                      <VideoCameraFront fontSize="small" />
                    ) : undefined
                  }
                  isDisabled={isDisabled || optionIsDisabled}
                  text={
                    <span>
                      <b>Use {titles[type]}:</b>&nbsp;
                      {value}
                    </span>
                  }
                  title={tooltip}
                  variant="contained"
                  onClick={async () => {
                    if (hasFacetimeContact) {
                      await updateContactRequest({
                        route: facetimeContact?.guid,
                        body: {
                          value: value,
                        },
                      });
                    } else {
                      await createContactRequest({
                        body: {
                          type: "facetime",
                          value: value,
                        },
                      });
                    }

                    dialogContext.close();
                  }}
                />
              </Grid>
            );
          })
      ) : (
        <Grid item>
          <Typography variant="subtitle2">
            No Email or Phone Number contact options found.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DialogContent;
