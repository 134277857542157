import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import SeatsList from "./SeatsList";
import { getParticipantTypeBranding } from "../../../utilities/utilityFunctions";
import { branding } from "../../../utilities/definitions";

const MultiboxAvailableAssignments: React.FC<any> = (props) => {
  const {
    assignedMultiboxParticipants,
    assignParticipantToSeat,
    canControlMultibox,
    unassignParticipantFromMultiboxEndpoint,
    seatStatus,
    inCall,
  } = props;

  const participantsArray = Object.entries(assignedMultiboxParticipants);

  return (
    <div className="layout-screener-crowdviewSeats">
      <Typography variant="h6">Assigned Participants</Typography>
      <Divider></Divider>
      {participantsArray.length > 0 ? (
        <List>
          {participantsArray.map(([key, participant]: any) => (
            <React.Fragment>
              <ListItem key={key}>
                <ListItemText>
                  {participant.fullName} - (
                  {getParticipantTypeBranding(
                    participant.type?.toLowerCase() ?? ""
                  )}
                  )
                </ListItemText>
                <ListItemSecondaryAction>
                  <SeatsList
                    assignParticipantToSeat={assignParticipantToSeat}
                    canControlMultibox={canControlMultibox}
                    inCall={inCall}
                    participant={participant}
                    seatStatus={seatStatus}
                    unassignParticipantFromMultiboxEndpoint={
                      unassignParticipantFromMultiboxEndpoint
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component="li" variant="inset" />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <div key={0}>No Participants Assigned to this {branding.crowdview}</div>
      )}
    </div>
  );
};

export default MultiboxAvailableAssignments;
