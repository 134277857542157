import { useEffect, useState } from "react";
import { useHttpRequest } from "../../hooks/rest";
import { useSelector } from "../../hooks/redux";
import { useSnackbar } from "../../hooks/snackbar";
import { CircularProgress, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Table from "../Table";
import TooltipButton from "../TooltipButton";
import ViewNote, { ParticipantNoteItem } from "./ViewNote";
import NoteField from "./NoteField";
import {
  AddParticipantNoteData,
  GetNotesQuery,
} from "../../REST/notesRequestsDefinitions";
import * as template from "./template";

const useStyles = makeStyles(() => ({
  main: {
    position: "relative",
  },
  blurred: { filter: "blur(3px)" },
  waiting: {
    justifyContent: "center",
    left: "50%",
    position: "absolute",
    top: "10%",
    zIndex: 9999,
  },
}));

interface Props {
  mini?: boolean;
  participantGuid: string;
}

const ParticipantNoteListView: React.FunctionComponent<Props> = (props) => {
  const { mini = false, participantGuid } = props;

  const classes = useStyles();
  const [awaitingUpdate, setAwaitingUpdate] = useState<boolean>(false);
  const [noteBeingModified, setNoteBeingModified] = useState<number | null>(
    null
  );
  const [addingNote, setAddingNote] = useState<boolean>(false);

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );
  const userInfo = useSelector((state: any) => state.queue.userInfo);
  const { displayName: currentUser } = userInfo;

  const addNoteTooltip =
    noteBeingModified === null
      ? template.participantNoteListView.tooltips.addNote
      : template.participantNoteListView.tooltips.cannotAddNote;

  const { enqueueSnackbar } = useSnackbar();

  const [getNotesRequest, getNotesRequestState] = useHttpRequest<GetNotesQuery>(
    `admin_proxy/participants/${participantGuid}/notes`,
    {
      method: "get",
    }
  );

  const [createNoteRequest] = useHttpRequest<
    EmptyObject,
    AddParticipantNoteData
  >(`admin_proxy/participants/${participantGuid}/notes`, {
    method: "post",
    body: {
      accountGuid,
      productionGuid,
    },
    onSuccess: () => {
      enqueueSnackbar(template.participantNoteListView.snackbars.noteAdded, {
        variant: "success",
      });

      getNotesRequest();
    },
  });

  useEffect(() => {
    getNotesRequest();
  }, [getNotesRequest]);

  const handleNoteBeingModified = (
    noteBeingModified: number | null,
    newAwaitingUpdate: boolean
  ): void => {
    setNoteBeingModified(noteBeingModified);
    setAwaitingUpdate(newAwaitingUpdate);
  };

  const handleAddNoteClick = (): void => {
    setNoteBeingModified(-1);
    setAddingNote(true);
  };

  const handleAddNoteClose = (): void => {
    setNoteBeingModified(null);
    setAddingNote(false);
  };

  const handleAddNoteSave = async (newContents: string) => {
    if (newContents.length > 0) {
      setAwaitingUpdate(true);

      await createNoteRequest({
        body: {
          user: currentUser,
          content: newContents,
        },
      });

      setAwaitingUpdate(false);
    }
    setNoteBeingModified(null);
    setAddingNote(false);
  };

  const notes: ParticipantNoteItem[] = getNotesRequestState.data ?? [];

  return (
    <Grid className={classes.main} container direction="column">
      <Grid
        className={
          awaitingUpdate || getNotesRequestState.loading
            ? classes.blurred
            : undefined
        }
        item
      >
        <Table
          columns={[]}
          data={notes}
          initialOrder={"desc"}
          initialOrderByProperty="modifiedAt"
          initialRowsPerPage={mini ? 5 : 10}
          rowRenderer={(baseProps) => {
            const { rowItem: noteItem } = baseProps;
            return (
              <ViewNote
                mini={mini}
                noteBeingModified={noteBeingModified}
                participantGuid={participantGuid}
                participantNote={noteItem as ParticipantNoteItem}
                refetch={getNotesRequest}
                setNoteBeingModified={handleNoteBeingModified}
              />
            );
          }}
          rowsPerPageOptions={mini ? [5, 10, 15] : [10, 15, 20]}
          style={{ height: "100%" }}
          toolbarChildren={
            <Grid
              container
              direction="row"
              item
              justifyContent={addingNote ? "flex-start" : "flex-end"}
            >
              {addingNote && (
                <Grid className="fullWidth" item>
                  <NoteField
                    contents=""
                    mini={mini}
                    onClose={handleAddNoteClose}
                    onSave={handleAddNoteSave}
                  />
                </Grid>
              )}
              {!addingNote && (
                <TooltipButton
                  color="primary"
                  icon={["fas", "edit"]}
                  isDisabled={noteBeingModified !== null}
                  size="small"
                  text={template.participantNoteListView.buttons.addNote}
                  title={addNoteTooltip}
                  onClick={handleAddNoteClick}
                />
              )}
            </Grid>
          }
        />
      </Grid>

      {(awaitingUpdate || getNotesRequestState.loading) && (
        <div className={classes.waiting}>
          <CircularProgress />
        </div>
      )}
    </Grid>
  );
};

export default ParticipantNoteListView;
