import { useState } from "react";
import TooltipButton from "../../../components/TooltipButton";
import { useSocket } from "../../../hooks";

const UnlockButton = (props) => {
  const {
    qManagerInControl,
    currentRole,
    endpointIsLockedByRoles,
    endpointName,
  } = props;

  const socketService = useSocket();

  const [hasHovered, setHasHovered] = useState(false);

  const inUseByText = qManagerInControl
    ? `In Use by: ${qManagerInControl.username}${
        endpointIsLockedByRoles ? "" : " (click to unlock)"
      }`
    : "";

  function handleClick() {
    socketService.emit("kickFromEndpoint", {
      endpointID: endpointName,
    });
  }

  return (
    <TooltipButton
      className="fullWidth"
      color={hasHovered ? "secondary" : "warning"}
      icon={hasHovered ? ["fas", "unlock"] : ["fas", "lock"]}
      isDisabled={
        endpointIsLockedByRoles ? true : currentRole.name === "queueAdmin"
      }
      title={inUseByText}
      onClick={handleClick}
      onMouseOut={() => {
        if (!endpointIsLockedByRoles) {
          setHasHovered(false);
        }
      }}
      onMouseOver={() => {
        if (!endpointIsLockedByRoles) {
          setHasHovered(true);
        }
      }}
    />
  );
};
export default UnlockButton;
