import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Suspense,
} from "react";
import { connect } from "react-redux";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  DialogContentText,
  Tab,
  Tabs,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getDay } from "date-fns";
import { branding } from "../../utilities/definitions";
import ParticipantModal from "./Caller/CallerModal.jsx";
import EavesDropSettingsTab from "./EavesDropSettingsTab";
import TabPanel from "../../components/TabPanel";
import { useAuthorizer } from "../../hooks/authorization";
import { useProductionEventAuthorization } from "../../hooks/authorization";
import { useHttpRequest } from "../../hooks/rest";
import { usePrevious } from "../../hooks/memo";
import { useSnackbar } from "../../hooks/snackbar";
import { useDispatch, useSelector } from "../../hooks/redux";
import {
  critical_error,
  housekeeping_acknowledged,
  setParticipants,
  setServerMessage,
} from "../../store/queue/actions";
import AlertDialog from "../../components/AlertDialog";
const QueueControlsTab = React.lazy(() => import("./QueueControlsTab"));
const DeletionRequestsTab = React.lazy(() => import("./DeletionRequestsTab"));
const QueueParticipantTab = React.lazy(() => import("./QueueParticipantTab"));
const ProductionEventsTab = React.lazy(() => import("../ProductionEventsTab"));
const StageDoorTab = React.lazy(() => import("./StageDoorTab"));

//const TWO_MINUTES_IN_MS = 120000;

const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: theme.typography.h6.fontSize,
  },
}));

const determineStartingTabIndex = (
  qmCanAccessAudienceQueue,
  qmCanAccessScheduler,
  qmCanAccessStageDoorList
) => {
  if (qmCanAccessScheduler) {
    return 2;
  } else if (qmCanAccessStageDoorList) {
    return 1;
  } else if (qmCanAccessAudienceQueue) {
    return 3;
  }
  return 2; // We do not have an "no compatible participant interfaces" screen, so must default to scheduled event list view
};

const Queue = (props) => {
  const {
    callers: participants,
    currentRole,
    currentScreener: currentEndpoint,
    isEpisodeSelected = false,
    parentFuncs,
    screeners: endpoints,
    serverTimeOffset,
    showInfo,
    totalCallersInQueue: totalParticipantsInQueue,
    uid,
    view,
  } = props;

  const prevView = usePrevious(view);

  const dispatch = useDispatch();
  const classes = useStyles();

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );

  const {
    criticalError,
    serverMessage,
    housekeepingAcknowledged,
    housekeepingPerformed,
  } = useSelector((state) => state.queue);

  const { displayName } = useSelector((state) => state.queue.userInfo);

  const [fetchParticipants] = useHttpRequest("admin_proxy/participants", {
    method: "get",
    searchParams: {
      accountGuid,
      productionGuid,
    },
  });

  const [fetchProductionEvents] = useHttpRequest(
    `admin_proxy/productions/${productionGuid}/events`,
    {
      method: "get",
    }
  );

  const selectionRange = useSelector((state) => state.queue.scheduleRange);

  const [activeTab, setActiveTab] = useState(2);

  const [isFirstLoad, setIsFirstLoad] = useState(prevView === null);

  useEffect(() => {
    if (isFirstLoad && accountGuid && productionGuid) {
      (async () => {
        const participants = await fetchParticipants();
        if (participants) {
          dispatch(setParticipants({ participants }));
        }

        setIsFirstLoad(false);
      })();
    }
  }, [
    accountGuid,
    isFirstLoad,
    productionGuid,
    selectionRange,
    dispatch,
    fetchParticipants,
    fetchProductionEvents,
    setIsFirstLoad,
  ]);

  useEffect(() => {
    if (!isFirstLoad && accountGuid && productionGuid) {
      (async () => {
        if (activeTab === 3) {
          const participants = await fetchParticipants();
          if (participants) {
            dispatch(setParticipants({ participants }));
          }
        }
      })();
    }
  }, [
    accountGuid,
    activeTab,
    productionGuid,
    selectionRange,
    dispatch,
    fetchParticipants,
    fetchProductionEvents,
    setIsFirstLoad,
  ]);

  function handleTabChange(_event, value) {
    setActiveTab(value);
  }

  const [currentParticipantID, setCurrentParticipantID] = useState();
  const [isParticipantModalOpen, setIsParticipantModalOpen] = useState(false);
  const openCallerModal = (_id) => {
    setIsParticipantModalOpen(true);
    setCurrentParticipantID(_id);
  };

  const closeCallerModal = () => {
    setIsParticipantModalOpen(false);
    setCurrentParticipantID(undefined);
  };

  const authorize = useAuthorizer();

  const qmIsPrescreener = authorize("callscreener");
  const qmIsFullScreener = authorize("callproducer");
  const qmCanAccessStageDoorList = authorize("stagedoor");
  const { authorizedToScheduleAirEvents, authorizedToScheduleTestEvents } =
    useProductionEventAuthorization();
  const qmCanAccessScheduler =
    authorizedToScheduleAirEvents || authorizedToScheduleTestEvents;

  const qmCanAccessAudienceQueue = authorize("audiencequeue");

  useMemo(() => {
    const updatedStartingTabIndex = determineStartingTabIndex(
      qmCanAccessAudienceQueue,
      qmCanAccessScheduler,
      qmCanAccessStageDoorList
    );
    setActiveTab(updatedStartingTabIndex);
  }, [
    qmCanAccessAudienceQueue,
    qmCanAccessScheduler,
    qmCanAccessStageDoorList,
  ]);

  const loadingSpinner = (
    <span>
      <CircularProgress />
      Loading...
    </span>
  );

  const isFriday = getDay(new Date()) === 5;
  const welcomeTextEasterEgg = isFriday ? "Happy Friday" : "Welcome";

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const [errorSnackbarKey, setErrorSnackbarKey] = useState(undefined);
  const [messageSnackbarKey, setMessageSnackbarKey] = useState(undefined);

  const handleCloseErrorSnackbar = useCallback(() => {
    if (errorSnackbarKey) {
      closeSnackbar(errorSnackbarKey);
      setErrorSnackbarKey(undefined);
    }
  }, [errorSnackbarKey]);

  const handleCloseMessageSnackbar = useCallback(() => {
    if (messageSnackbarKey) {
      closeSnackbar(messageSnackbarKey);
      setMessageSnackbarKey(undefined);
    }
  }, [messageSnackbarKey]);

  useEffect(() => {
    const clearError = () => {
      if (criticalError) {
        dispatch(critical_error(null));
      }
    };

    if (criticalError) {
      const key = enqueueSnackbar(criticalError, {
        hideIconVariant: true,
        persist: true,
        variant: "error",
        onClose: clearError,
      });
      setErrorSnackbarKey(key);
    } else {
      handleCloseErrorSnackbar();
    }

    return () => {};
  }, [criticalError, setErrorSnackbarKey]);

  useEffect(() => {
    const clearServerMessage = () => {
      if (serverMessage) {
        dispatch(setServerMessage(null));
      }
    };

    if (serverMessage) {
      const key = enqueueSnackbar(serverMessage, {
        hideIconVariant: true,
        persist: true,
        variant: "success",
        onClose: clearServerMessage,
      });
    } else {
      handleCloseMessageSnackbar();
    }

    return () => {};
  }, [serverMessage, setMessageSnackbarKey]);

  return (
    <Fragment>
      <div id="view-selection-tabs-container">
        {!isEpisodeSelected ? (
          <Alert icon={false} severity="info">
            <AlertTitle>
              <h2 style={{ margin: "auto" }}>
                {welcomeTextEasterEgg}, {displayName}!
              </h2>
            </AlertTitle>
            Please select a Production from the above dropdown.
            <br />
            If you do not see any Productions or need any other assistance,
            please contact{" "}
            <a href="mailto:vcc-support@nextologies.com">VCC tech support</a>
            &nbsp;(vcc-support@nextologies.com)
          </Alert>
        ) : (
          <Fragment>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={activeTab}
              variant="scrollable"
              onChange={handleTabChange}
            >
              {qmCanAccessScheduler && (
                <Tab
                  classes={{ root: classes.tab }}
                  label="Schedule"
                  value={2}
                />
              )}

              {qmCanAccessStageDoorList && (
                <Tab
                  classes={{ root: classes.tab }}
                  label="Contacts"
                  value={3}
                />
              )}

              {qmCanAccessAudienceQueue && (
                <Tab
                  classes={{ root: classes.tab }}
                  label={`${branding.callerqueue} (${
                    totalParticipantsInQueue || 0
                  })`}
                  value={1}
                />
              )}
              {/**@todo Rework and relocate these controls to ../../pages/admin */}
              {currentRole.name === "queueAdmin" && [
                <Tab
                  classes={{ root: classes.tab }}
                  label="Queue Controls"
                  value={4}
                />,
                <Tab
                  classes={{ root: classes.tab }}
                  disabled={!showInfo}
                  label={`${branding.eavesdrop} Settings`}
                  value={5}
                />,
                <Tab
                  classes={{ root: classes.tab }}
                  label="Deletion Requests"
                  value={6}
                />,
              ]}
            </Tabs>

            <TabPanel index={3} value={activeTab}>
              <Suspense fallback={loadingSpinner}>
                <StageDoorTab />
              </Suspense>
            </TabPanel>

            <TabPanel index={2} value={activeTab}>
              <Suspense fallback={loadingSpinner}>
                <ProductionEventsTab />
              </Suspense>
            </TabPanel>

            <TabPanel index={1} value={activeTab}>
              <Suspense fallback={loadingSpinner}>
                <QueueParticipantTab
                  openCallerModal={openCallerModal}
                  parentFuncs={parentFuncs}
                  view={view}
                />
              </Suspense>
            </TabPanel>

            {currentRole.name === "queueAdmin" && [
              <TabPanel index={4} value={activeTab}>
                <Suspense fallback={loadingSpinner}>
                  <QueueControlsTab />
                </Suspense>
              </TabPanel>,
              <TabPanel index={5} value={activeTab}>
                <EavesDropSettingsTab
                  uploadStrategy={showInfo.uploadStrategy}
                />
              </TabPanel>,
              <TabPanel index={6} value={activeTab}>
                <Suspense fallback={loadingSpinner}>
                  <DeletionRequestsTab uid={uid} />
                </Suspense>
              </TabPanel>,
            ]}
          </Fragment>
        )}
      </div>
      {participants && (
        <ParticipantModal
          caller={participants[currentParticipantID]}
          callers={participants}
          closeModal={closeCallerModal}
          currentScreener={currentEndpoint}
          open={isParticipantModalOpen}
          parentFuncs={parentFuncs}
          qmIsFullScreener={qmIsFullScreener}
          qmIsPrescreener={qmIsPrescreener}
          screeners={endpoints}
          serverTimeOffset={serverTimeOffset}
        />
      )}
      {(qmCanAccessStageDoorList || qmCanAccessScheduler) && (
        <AlertDialog
          cancelText="OK"
          children={
            <DialogContentText>
              An automatic housekeeping action was performed. It is recommended
              that you refresh this page at this time.
              <br />
              If now is not a good time, you can close this alert and refresh
              later. Please be advised that your Schedule or Contact list may
              not be accurate until you refresh.
            </DialogContentText>
          }
          dialogTitle={"Housekeeping Performed"}
          handleClose={() => {
            dispatch(housekeeping_acknowledged());
          }}
          isOpen={housekeepingPerformed && !housekeepingAcknowledged}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    callers,
    currentEpisode,
    currentScreener,
    currentShow,
    isEpisodeSelected,
    network,
    screeners,
    serverTimeOffset,
    showInfo,
    targetZip,
    totalCallersInQueue,
  } = state.queue;

  const uid = `${network}-${currentShow}-${currentEpisode}`
    .toLowerCase()
    .replaceAll(" ", "_");

  return {
    callers,
    currentScreener,
    isEpisodeSelected,
    screeners,
    serverTimeOffset,
    showInfo,
    targetZip,
    totalCallersInQueue,
    uid,
  };
};

export default connect(mapStateToProps)(Queue);
