import { branding } from "../../utilities/definitions";

export const assignmentAlerts = {
  title: "Assign Participant",
  changeAssignment: {
    text: "Are you sure you want to change assignments?",
  },
  crowdViewIncompatible: {
    text: `Participant cannot be assigned to ${branding.crowdview} Control Panel.`,
  },
  inCall: {
    text: "You cannot change assignment while a Control Panel is in a call",
  },
};

export const currentEndpointButton = {
  text: "Assign to {{{endpoint}}}",
  tooltip: {
    assigned: "Participant is already assigned to {{{endpoint}}}",
    inCall: "Cannot assign to {{{endpoint}}} while it is in a call",
    notAssigned: "Assign to {{{endpoint}}}",
    notInvited:
      "Participant invitation must be generated before assignment to a Control Panel",
    quickParticipant:
      "Cannot override assignment while Control Panel has a Quick Participant assigned",
  },
};

export const enterEndpointButton = {
  text: "Enter {{{endpoint}}}",
  tooltip: {
    default: "Take over usage of Control Panel {{{endpoint}}}",
    inUse: "Control Panel {{{endpoint}}} is in use by {{{producer}}}",
    notPermitted: "You don't have the permissions to enter {{{endpoint}}}",
  },
};

export const selectEndpointButton = {
  text: "Assign to Control Panel",
  tooltip: {
    assigned: "Participant is already assigned to a Control Panel",
    notAssigned: "Select Control Panel to assign participant",
    notInvited:
      "Participant invitation must be generated before assignment to a Control Panel",
  },
};
