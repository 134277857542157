import { Seat, SeatStatus } from "../store/types";

export const determineCrowdviewSeatsAvailable = (
  seatStatus: SeatStatus
): number => {
  if (!seatStatus) return 0;

  const reducer = (acc: number, seat: Seat): number =>
    seat?.participant ? acc : ++acc;

  return Object.values(seatStatus).reduce(reducer, 0);
};

export const determineCrowdviewParticipantsWaiting = (
  assignedMultiboxParticipants
) => {
  if (!assignedMultiboxParticipants) return 0;
  return assignedMultiboxParticipants?.reduce((acc, participant) => {
    return !participant.assignedMultiboxSeat ? ++acc : acc;
  }, 0);
};

export const determineMutedParticipantCount = (
  seatStatus: SeatStatus
): number => {
  if (!seatStatus) return 0;

  const reducer = (acc: number, seat: Seat) => {
    const { participant, muted } = seat;

    return Boolean(participant) && muted ? ++acc : acc;
  };

  return Object.values(seatStatus).reduce(reducer, 0);
};

export const determineSeatedParticipantCount = (
  seatStatus: SeatStatus
): number => {
  if (!seatStatus) return 0;

  const reducer = (acc: number, seat: Seat): number =>
    seat?.participant ? ++acc : acc;

  return Object.values(seatStatus).reduce(reducer, 0);
};
