export const buttons = {
  selectShow: {
    productionsAvailable: "Select Production",
    noProductionsAvailable: "No Productions Found",
  },
  userMenu: {
    logout: "Logout",
    selectNetwork: "Selected Network",
    selectRole: "Selected Role",
    themeDark: "Change to Light Mode",
    themeLight: "Change to Dark Mode",
  },
};

export const options = {
  clockMode: {
    military: "24-hour",
    standard: "12-hour",
    tooltip: "Select clock display mode.  Currently set: {{selectedClockMode}}",
  },
};
