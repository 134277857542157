import type { MenuButtonItem } from "../../../components/MenuButton";

import React, { Fragment, useState } from "react";
import AlertDialog from "../../../components/AlertDialog";
import MenuButton from "../../../components/MenuButton";
import CQ from "../../../utilities/socket/CQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branding } from "../../../utilities/definitions";
import { icons } from "../../../definitions/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  endpointName: string;
  inCall: boolean;
  muteControlsEnabled: boolean;
  popControlsEnabled: boolean;
  poppedParticipantID?: string;
  seatInfo: any;
};

const AssignedSeatDropdown: React.FC<Props> = (props) => {
  const {
    endpointName,
    inCall,
    muteControlsEnabled,
    popControlsEnabled,
    poppedParticipantID,
    seatInfo,
  } = props;

  const [
    changePoppedParticipantAlertOpen,
    setChangePoppedParticipantAlertOpen,
  ] = useState<boolean>(false);
  const [unmuteParticipantAlertOpen, setUnmuteParticipantAlertOpen] =
    useState<boolean>(false);

  const { participant = {}, muted, status } = seatInfo;
  const { id } = participant;

  const isPoppedParticipant = id === poppedParticipantID;
  const isParticipantInCall = status === "calling";
  const isParticipantMuted = muted;
  const participantIdToPop = isPoppedParticipant ? undefined : id;

  const displayChangePopWarning = Boolean(poppedParticipantID);
  const displayPopWarningTitle = `Change ${branding.bop}`;
  const displayPopWarningText = `Are you sure you want to ${
    isPoppedParticipant ? `Un-${branding.bop}` : branding.bop
  } this participant?`;

  const displayUnmuteParticipantWarning =
    Boolean(poppedParticipantID) && !isPoppedParticipant && isParticipantMuted;
  const displayUnmuteParticipantWarningTitle = "Unmute Participant?";
  const displayUnmuteParticipantWarningText = `Are you sure you want to unmute this participant while there is an active ${branding.bop}?`;

  function endCall(): void {
    CQ.emit("endCrowdViewCallToParticipant", {
      participantID: participant.id,
      participantType: participant.type,
      seatID: seatInfo.id,
    });
  }

  function removeFromSeat(): void {
    CQ.emit("removeParticipantFromSeat", {
      endpointName: endpointName,
      participantID: participant.id,
      participantType: participant.type,
      seatID: seatInfo.id,
    });
  }

  function startCall(): void {
    CQ.emit("startCrowdViewCallToParticipant", {
      participantID: participant.id,
      participantType: participant.type,
      seatID: seatInfo.id,
    });
  }

  function unassignFromEndpoint(): void {
    CQ.emit("unassignParticipantFromMultiboxEndpoint", {
      participant: participant,
    });
  }

  function determineHighLevelButtonColor() {
    if (isParticipantInCall) {
      if (isPoppedParticipant) {
        return "info";
      } else {
        return isParticipantMuted ? "info" : "secondary";
      }
    }

    return "warning";
  }

  function determineHighLevelButtonIcon(): IconProp {
    if (isParticipantInCall) {
      if (isPoppedParticipant) {
        return icons.participantPopped;
      } else {
        return isParticipantMuted ? icons.muted : icons.participantCalling;
      }
    }

    return icons.participantNone;
  }

  function determineMuteButtonIcon(): IconProp {
    return isParticipantMuted ? icons.muted : icons.notMuted;
  }

  function determinePopButtonIcon(): IconProp {
    return isPoppedParticipant
      ? icons.participantAssigned
      : icons.participantPopped;
  }

  function emitSetParticipantMutedState(): void {
    CQ.emit("setParticipantMutedState", {
      seatID: seatInfo?.id,
      mutedState: !isParticipantMuted,
    });
  }

  function emitSetPoppedCrowdViewParticipant(): void {
    CQ.emit("setPoppedCrowdViewParticipant", {
      id: participantIdToPop,
    });
  }

  function toggleParticipantMutedState(): void {
    if (displayUnmuteParticipantWarning) {
      setUnmuteParticipantAlertOpen(true);
    } else {
      emitSetParticipantMutedState();
    }
  }

  function setPoppedParticipantId(): void {
    if (displayChangePopWarning) {
      setChangePoppedParticipantAlertOpen(true);
    } else {
      emitSetPoppedCrowdViewParticipant();
    }
  }

  const highLevelButtonIcon: IconProp = determineHighLevelButtonIcon();
  const highLevelButtonColor = determineHighLevelButtonColor();

  const mutedIcon: IconProp = determineMuteButtonIcon();
  const popIcon: IconProp = determinePopButtonIcon();
  const flipHighLevelIcon =
    highLevelButtonIcon === icons.participantPopped ? "vertical" : undefined;

  const menuButtonItems: MenuButtonItem[] = [];

  if ((isPoppedParticipant || popControlsEnabled) && isParticipantInCall) {
    menuButtonItems.push({
      children: (
        <Fragment>
          <FontAwesomeIcon
            flip={isPoppedParticipant ? undefined : "vertical"}
            icon={popIcon}
          />
          &nbsp;
          {`${isPoppedParticipant ? `Un-${branding.bop}` : branding.bop}`}
        </Fragment>
      ),
      className: "cv-seat-dropdown-item",
      color: highLevelButtonColor,

      onClick: setPoppedParticipantId,
    });
  }

  if (muteControlsEnabled && isParticipantInCall) {
    menuButtonItems.push({
      children: (
        <Fragment>
          <FontAwesomeIcon icon={mutedIcon} />
          &nbsp;
          {`${
            isParticipantMuted
              ? "Unmute"
              : `Mute${isPoppedParticipant ? " - Cannot Mute" : ""}`
          }`}
        </Fragment>
      ),
      color: highLevelButtonColor,
      className: "cv-seat-dropdown-item",
      disabled: isPoppedParticipant,
      onClick: toggleParticipantMutedState,
    });
  }

  if (isParticipantInCall) {
    menuButtonItems.push({
      children: (
        <Fragment>
          <FontAwesomeIcon icon={icons.participantCalling} />
          &nbsp;
          {`End Call${isPoppedParticipant ? " - Cannot End Call" : ""}`}
        </Fragment>
      ),
      color: "secondary",
      className: "cv-seat-dropdown-item",
      disabled: isPoppedParticipant,
      onClick: endCall,
    });
  } else {
    menuButtonItems.push(
      ...[
        {
          children: (
            <Fragment>
              <FontAwesomeIcon icon={icons.participantAssigned} />
              &nbsp; Start Call
            </Fragment>
          ),
          className: `cv-seat-dropdown-item ${inCall ? "" : "start-button"}`,
          color: inCall ? undefined : "warning",
          disabled: !inCall,
          onClick: startCall,
        },
        {
          children: `Remove from Seat #${seatInfo?.id}`,
          className: "remove-button cv-seat-dropdown-item",
          onClick: removeFromSeat,
        },
        {
          children: (
            <Fragment>
              <FontAwesomeIcon icon={icons.participantNone} />
              &nbsp; Unassign from {branding.crowdview}
            </Fragment>
          ),
          className: "unassign-button cv-seat-dropdown-item",
          onClick: unassignFromEndpoint,
        },
      ]
    );
  }

  return (
    <Fragment>
      <MenuButton
        className="fullWidth crowdview-seat-dropdown"
        color={highLevelButtonColor}
        icon={highLevelButtonIcon}
        iconFlip={flipHighLevelIcon}
        items={menuButtonItems}
        text={participant.fullName}
        title={participant.fullName}
      />
      <AlertDialog
        actions={[
          {
            label: "Confirm",
            onClick: emitSetPoppedCrowdViewParticipant,
          },
        ]}
        children={null}
        dialogText={displayPopWarningText}
        dialogTitle={displayPopWarningTitle}
        disableBackdropClick={true}
        handleClose={() => {
          setChangePoppedParticipantAlertOpen(false);
        }}
        isOpen={changePoppedParticipantAlertOpen}
      />
      <AlertDialog
        actions={[
          {
            label: "Confirm",
            onClick: emitSetParticipantMutedState,
          },
        ]}
        children={null}
        dialogText={displayUnmuteParticipantWarningText}
        dialogTitle={displayUnmuteParticipantWarningTitle}
        disableBackdropClick={true}
        handleClose={() => {
          setUnmuteParticipantAlertOpen(false);
        }}
        isOpen={unmuteParticipantAlertOpen}
      />
    </Fragment>
  );
};

export default React.memo(AssignedSeatDropdown);
