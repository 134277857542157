import {
  FunctionComponent,
  Fragment,
  MouseEvent,
  useMemo,
  useState,
} from "react";
import { Grid, Popover, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { IconProp, RotateProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NetworkStatRating, NetworkDiagnosticStatus } from "./types";
import { statisticState } from "./templates";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    badRating: {
      color: "red",
      marginLeft: "5px",
    },
    goodRating: {
      color: "#5cb85c",
      marginLeft: "5px",
    },
  })
);

type Props = {
  description: string | string[];
  icon: IconProp;
  iconRotation?: RotateProp;
  stat?: number;
  testStatus: NetworkDiagnosticStatus;
  thresholds?: {
    bad: number;
    good: number;
  };
  title: string;
  unit: string;
};

const Statistic: FunctionComponent<Props> = (props) => {
  const {
    description,
    icon,
    iconRotation,
    stat,
    testStatus,
    thresholds,
    title,
    unit,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const open = Boolean(anchorEl);

  const classes = useStyles();

  function handlePopoverOpen(
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const formattedStat = useMemo(() => {
    if (testStatus === "not-started") {
      return statisticState.notTested;
    } else if (testStatus === "pending") {
      return statisticState.pending;
    } else if (typeof stat !== "number") {
      return statisticState.noResult;
    }

    return `${stat.toFixed(1)} ${unit}`;
  }, [unit, stat, testStatus]);

  const rating: NetworkStatRating = useMemo(() => {
    if (typeof stat !== "number" || !thresholds) return "average";

    if (thresholds.good > thresholds.bad) {
      if (stat >= thresholds.good) {
        return "good";
      } else if (stat <= thresholds.bad) {
        return "bad";
      }
    } else {
      if (stat <= thresholds.good) {
        return "good";
      } else if (stat >= thresholds.bad) {
        return "bad";
      }
    }

    return "average";
  }, [stat, thresholds]);

  return (
    <Fragment>
      <Grid className="text-center" item>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: classes.paper,
          }}
          className={classes.popover}
          open={open}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
        >
          {typeof description === "string" ? (
            <div>{description}</div>
          ) : (
            description.map((line) => <div>{line}</div>)
          )}
          <hr />
          <p>
            Ideal Range
            <br />
            Excellent: {thresholds.good > thresholds.bad ? "Above" : "Below"}
            &nbsp;
            {`${thresholds.good} ${unit}`}
            <br />
            Poor: {thresholds.bad > thresholds.good ? "Above" : "Below"}
            &nbsp;
            {`${thresholds.bad} ${unit}`}
          </p>
        </Popover>

        <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          <div>
            <FontAwesomeIcon icon={icon} rotation={iconRotation} size="xs" />
            <Typography variant="caption">
              <b>&nbsp;{title}:</b>
            </Typography>
            <Typography
              color={"textSecondary"}
              display="inline"
              variant="caption"
            >
              <b>&nbsp;{formattedStat}</b>
            </Typography>
            {testStatus === "completed" && (
              <Fragment>
                {rating === "good" && (
                  <FontAwesomeIcon
                    className={classes.goodRating}
                    icon={["fas", "check"]}
                  />
                )}
                {rating === "bad" && (
                  <FontAwesomeIcon
                    className={classes.badRating}
                    icon={["fas", "times"]}
                  />
                )}
              </Fragment>
            )}
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};

export default Statistic;
