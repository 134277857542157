import { io } from "socket.io-client";
import { SERVER_ROOT, DEBUG_MODE } from "../../config";

const CQ = {
  DEBUG: (_logType, _message, _displayType, _color, _dataObj) => {
    if (DEBUG_MODE) {
      let color = "color: #" + _color || "000";
      const displayType = _displayType || "log";
      if (_dataObj)
        console[displayType](
          "%c " + _logType + ": " + _message,
          color,
          _dataObj
        );
      else console[displayType]("%c " + _logType + ": " + _message, color);
    }
  },
  //Function for emitting messages to the server
  emit: (_emitName, _emitParams) => {
    CQ.DEBUG(
      "CQ Log",
      'Emit event "' + _emitName + '" to server',
      "log",
      "07B200",
      _emitParams
    );
    CQ.socket.emit(_emitName, _emitParams);
  },
  //Initialize connection to the socket
  connectToSocket: (accessToken, _callback) => {
    CQ.socket = io(`${SERVER_ROOT}/queueManager`, {
      reconnection: true,
      secure: true,
      query: {
        accessToken,
        appName: "queueManager",
      },
    });

    return CQ.socket;
  },
};

export default CQ;
