import type { FunctionComponent, MouseEvent } from "react";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Fragment, useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  Grid,
  Popover,
  Typography,
  Link,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector } from "../../hooks/redux";
import SelectNetwork from "./SelectNetwork";
import SelectRole from "./SelectRole";
import { buttons } from "./templates";
import CustomTooltip from "../../components/Tooltip";
import { useTheme } from "./../../hooks/theme";
const { userMenu } = buttons;

const useStyles = makeStyles({
  divider: {
    width: "100%",
  },
  grid: {
    padding: "5px",
    maxWidth: "500px",
  },
  popover: {
    overflowY: "hidden",
    padding: "5px",
  },
});

const UserMenuButton: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { loading: themeIsChanging, isDarkMode, toggleTheme } = useTheme();

  const { oktaAuth } = useOktaAuth();

  const classes = useStyles();

  const currentEndpoint = useSelector((state) => state.queue.currentScreener);

  const endpoints = useSelector((state) => state.queue.screeners);
  const hostedNetworks = useSelector((state) => state.queue.hostedNetworks);
  const network = useSelector((state) => state.queue.network);

  const userInfo = useSelector((state: any) => state.queue.userInfo);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  async function handleThemeToggleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    await toggleTheme();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleLogoutClick() {
    await oktaAuth.signOut();
  }

  const isOpen = Boolean(anchorEl);

  const isInCall =
    endpoints && currentEndpoint && endpoints[currentEndpoint]
      ? endpoints[currentEndpoint].inCall
      : false;

  return (
    <Fragment>
      <CustomTooltip title="Click for user options and support">
        <IconButton color="info" size="large" onClick={handleClick}>
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </CustomTooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{
          paper: classes.popover,
        }}
        open={isOpen}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <Grid className={classes.grid} container spacing={3}>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            xs={12}
          >
            <Typography>{userInfo ? userInfo.displayName : ""}</Typography>
            <Button
              color="secondary"
              disabled={isInCall}
              variant="contained"
              onClick={handleLogoutClick}
            >
              {userMenu.logout}
            </Button>
          </Grid>
          <Divider className={classes.divider} component="div" />
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            xs={12}
          >
            <Typography>
              Display Mode:&nbsp;{isDarkMode ? "Dark" : "Light"}
            </Typography>
            <Button
              color="primary"
              disabled={themeIsChanging}
              variant="contained"
              onClick={handleThemeToggleClick}
            >
              {isDarkMode ? userMenu.themeDark : userMenu.themeLight}
            </Button>
          </Grid>
          <Divider className={classes.divider} component="div" />
          <Grid container item justifyContent="space-between" spacing={3}>
            {hostedNetworks && userInfo && (
              <Grid className="text-center" item xs={6}>
                <Typography>{userMenu.selectNetwork}</Typography>
                <SelectNetwork
                  authorizedNetworks={userInfo.networks}
                  currentNetwork={network}
                  disabled={currentEndpoint}
                  hostedNetworks={hostedNetworks}
                />
              </Grid>
            )}
            <Grid className="text-center" item xs={6}>
              <Typography>{userMenu.selectRole}</Typography>
              <SelectRole disabled={currentEndpoint} />
            </Grid>
          </Grid>
          <Grid container item justifyContent="center">
            <Typography>
              <SupportAgentIcon fontSize="medium" />
              <Link
                href="mailto:vcc-support@nextologies.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                Click to contact VCC Support
              </Link>
              &nbsp;(vcc-support@nextologies.com)
            </Typography>
          </Grid>
        </Grid>
      </Popover>
    </Fragment>
  );
};

export default UserMenuButton;
