import type { FunctionComponent, MouseEventHandler } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as Ramda from "ramda";
import { AppBar, Box, Button, Grid, Link, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Help as HelpIcon,
  Public as PublicIcon,
  WatchLater as WatchLaterIcon,
} from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useSelector } from "../../hooks";
import ProgramEavesDrop from "./ProgramEavesDrop";
import SelectShow from "./SelectShow";
import UserMenuButton from "./UserMenuButton";
import SelectTimeZone from "./SelectTimeZone";
import SelectClockMode from "./SelectClockMode";

import { selectNetwork } from "../../store/queue/actions";
import CustomTooltip from "../../components/Tooltip";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 35,
    marginRight: 25,
    width: "auto",
  },
  userOptions: {
    marginLeft: "auto",
  },
  root: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.info.light,
    display: "flex",
  },
}));

const MenuBar: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useOktaAuth();

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.queue.userInfo);
  const hostedNetworks = useSelector((state) => state.queue.hostedNetworks);
  const network = useSelector((state) => state.queue.network);
  const currentEpisode = useSelector((state) => state.queue.currentEpisode);
  const disconnected = useSelector(
    (state) => state.queue.disconnected || false
  );
  const programCMP = useSelector((state) => state.queue.programCMP || null);
  const roleChoices = useSelector((state) => state.queue.roleChoices || []);

  const classes = useStyles();

  const isOnAdminPage = location.pathname.startsWith("/admin");
  const isAuthenticated =
    !location.pathname.startsWith("/login") && authState?.isAuthenticated;

  const handleAdminClick: MouseEventHandler<HTMLButtonElement> = (_event) => {
    if (isOnAdminPage) {
      history.push("/");
    } else {
      history.push("/admin/users");
    }
  };

  useEffect(() => {
    if (isAuthenticated && !network) {
      if (userInfo && hostedNetworks) {
        //console.log("MenuBar: No default network/account selected.");
        const authorizedNetworks = userInfo.networks;
        const networkOptions = Ramda.intersection(
          hostedNetworks,
          authorizedNetworks
        );

        if (networkOptions.length > 0) {
          /*console.log(
            "MenuBar: Attempting to choose a default network/account."
          );*/
          const defaultNetwork = networkOptions.includes("VCC")
            ? "VCC"
            : networkOptions[0];
          /*console.log(
            `MenuBar: Default network set: ${defaultNetwork}; Retrieving productions.`
          );*/
          dispatch(selectNetwork(defaultNetwork));
        } else {
          //console.log("MenuBar: Awaiting network/account(s) to parse.");
        }
      } else {
        //console.log("MenuBar: Awaiting login completion.");
      }
    }
  }, [hostedNetworks, isAuthenticated, network, userInfo]);

  return (
    <AppBar className={classes.root} position="sticky">
      <Toolbar>
        <img
          alt="AirFirst logo"
          className={classes.logo}
          src="/img/airfirst_logo_white.png"
        />

        {isAuthenticated && !disconnected && <SelectShow />}

        {isAuthenticated && !disconnected && currentEpisode && programCMP && (
          <ProgramEavesDrop />
        )}

        <Box className={classes.userOptions}>
          {isAuthenticated && !disconnected && (
            <Grid
              alignItems="center"
              container
              justifyContent="flex-end"
              spacing={1}
            >
              {roleChoices.find(
                (role) => role.name === "helpDesk" && role.value
              ) && (
                <Grid item>
                  <Button
                    color="info"
                    variant="contained"
                    onClick={handleAdminClick}
                  >
                    SWITCH TO {isOnAdminPage ? "QUEUE" : "ADMIN"}
                  </Button>
                </Grid>
              )}

              <Grid item>
                <WatchLaterIcon color="info" />
              </Grid>

              <Grid item>
                <SelectClockMode />
              </Grid>

              <Grid item>
                <PublicIcon color="info" />
              </Grid>

              <CustomTooltip title="Select timezone display mode">
                <Grid item>
                  <SelectTimeZone />
                </Grid>
              </CustomTooltip>

              <CustomTooltip title="Click to access AirFirst™ FAQ">
                <Grid item>
                  <Link
                    color="inherit"
                    href="https://thevcc.zohodesk.com/portal/en/kb/airfirst"
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                  >
                    <HelpIcon />
                  </Link>
                </Grid>
              </CustomTooltip>

              <Grid item>
                <UserMenuButton />
              </Grid>
            </Grid>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
