export const isBeta =
  "The grading scale used is subject to revision while in Beta. Past ratings may be inconsistent with future ones.";

export const buttons = {
  startTest: {
    text: "Re-test",
    tooltip: "Run a new test",
  },
  stopTest: {
    text: "Stop Test",
    tooltip: "Stop test in progress",
  },
  testDisabled: {
    tooltip: "Cannot run test without an active connection",
  },
};

export const networkQuality = {
  title: "Overall",

  state: {
    completed: {
      title: "{{score}} / 5",
      tooltip:
        "This is the calculated grade of the participant's network quality.",
    },
    error: {
      title: "No Result",
      tooltip:
        "Insufficient data collected in order to assign a grade for this participant's connection.",
    },
    notTested: {
      title: "Not Tested",
      tooltip: "The participant has yet to complete a network test.",
    },
    pending: {
      title: "Pending",
      tooltip: "A network test is currently in progress.",
    },
  },
};

export const statisticState = {
  notTested: "Not Tested",
  noResult: "No Result",
  pending: "Pending",
};

export const resultsAge = {
  state: {
    error: "Error",
    notTested: "Not Tested",
    pending: "Pending",
  },
  title: "Last Tested",
};

export const statistics = {
  jitter: {
    description: [
      "Average audio packet delay.",
      "The higher this number is, the more out-of-sync the participant's audio and video will be.",
    ],
    title: "Lipsync Jitter",
    unit: "ms",
  },
  bitrate: {
    description: [
      "Media Bitrate shows the limits of the participant's connection to be able to receive and send media.",
      "A higher number is generally better and reflects a more robust two-way connection.",
    ],
    title: "Media Bitrate",
    unit: "Mbps",
  },
  packetLoss: {
    description: [
      "Average amount of video lost in transit.",
      "The lower this number is, the smoother the participant's video will be.",
    ],
    title: "Packet Loss",
    unit: "ms",
  },
  rtt: {
    description: [
      "The average amount of time it takes data to get to the media server and back to the participant.",
      "The higher this number is, the more delay there will be in conversation.",
    ],
    title: "RTT",
    unit: "ms",
  },
};
