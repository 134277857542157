import React, { useEffect, useState } from "react";
import TooltipButton from "../../../../components/TooltipButton";
import { branding } from "../../../../utilities/definitions";
import { NODE_ENV } from "../../../../config";

const MultiboxCallButton = ({
  canControlMultibox,
  callButtonFuncs,
  currentScreener: {
    allocatedCMP,
    inCall,
    multibox,
    waitingForCallStateChange,
    assignedCaller: { twilio },
  },
}) => {
  const [hasClickedButton, setHasClickedButton] = useState(false);
  const hasActiveCMP =
    allocatedCMP &&
    allocatedCMP.isAwake &&
    allocatedCMP.isUpdatingSession === false
      ? true
      : false;
  const canLaunch = NODE_ENV === "production" ? hasActiveCMP : true;
  const buttonClickTimeoutMilliseconds = 5000;

  useEffect(() => {
    if (hasClickedButton && !waitingForCallStateChange) {
      const timeout = setTimeout(() => {
        setHasClickedButton(false);
        clearTimeout(timeout);
      }, buttonClickTimeoutMilliseconds);
    } else if (hasClickedButton && waitingForCallStateChange) {
      setHasClickedButton(false);
    }
  }, [hasClickedButton, waitingForCallStateChange]);

  function handleCallClick(
    appName,
    allocatedCMP,
    multiboxName,
    inCall,
    twilio,
    callButtonFuncs
  ) {
    const chatApp = appName.toLowerCase();
    const isUsingCMP = allocatedCMP ? true : false;

    if (!inCall) {
      setHasClickedButton(true);
      callButtonFuncs.startAndEndCall.startCall(
        {
          id: twilio,
          accountName: twilio,
          chatApp,
          cmp: isUsingCMP ? { macAddress: allocatedCMP.macAddress } : false,
          endpointID: multiboxName,
        },
        !isUsingCMP
      );
    } else {
      callButtonFuncs.startAndEndCall.endCall(
        {
          id: multiboxName,
          chatApp,
          cmp: isUsingCMP ? { macAddress: allocatedCMP.macAddress } : false,
          endpointID: multiboxName,
        },
        !isUsingCMP
      );
    }
  }

  const isLaunchDisabled =
    !canControlMultibox ||
    !canLaunch ||
    waitingForCallStateChange ||
    hasClickedButton;

  const isCloseDisabled = !canControlMultibox || waitingForCallStateChange;

  return (
    <TooltipButton
      color={inCall ? "secondary" : "success"}
      icon={["fas", "th"]}
      isDisabled={inCall ? isCloseDisabled : isLaunchDisabled}
      title={
        !inCall ? `Launch ${branding.crowdview}` : `Close ${branding.crowdview}`
      }
      onClick={() => {
        handleCallClick(
          "Twilio",
          allocatedCMP,
          multibox.name,
          inCall,
          twilio,
          callButtonFuncs
        );
      }}
    />
  );
};

export default MultiboxCallButton;
