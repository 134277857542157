import type { Socket } from "socket.io-client";

import { store } from "../../store";
import { eventListeners } from "./eventListeners";
import cq from "./CQ";
import { oktaClient } from "../okta";

// TODO: makes this class initialize the socket connection and get rid of CQ
// Service can be used by React components that subscribe to its context
export class SocketService {
  private socket?: Socket;
  private _initialized: boolean;

  constructor() {
    this._initialized = false;
  }

  get initialized(): boolean {
    return this._initialized;
  }

  /**
   * @description Initializes connection to the server.
   */
  public init(): SocketService {
    const accessToken = oktaClient.getAccessToken();
    this.socket = cq.connectToSocket(accessToken);

    this.attachEventListeners();
    this._initialized = true;

    return this;
  }

  private attachEventListeners(): void {
    Object.entries(eventListeners).forEach(([event, listener]) =>
      this.socket?.on(event, listener)
    );
  }

  public emit(eventName: string, data: any): void {
    this.socket?.emit(eventName, data);
  }

  public selectShow(show: string): void {
    store.dispatch({ type: "SELECT_SHOW", show });
  }

  public getSocketId(): string {
    return this.socket?.id;
  }

  // disconnect - used when unmounting
  public disconnect(): void {
    Object.entries(eventListeners).forEach(([event, listener]) =>
      this.socket?.off(event, listener)
    );
    this.socket?.disconnect();
    this.socket = undefined;
  }
}
