import { FunctionComponent, Fragment } from "react";
import { Grid, Switch } from "@mui/material";
import CQ from "../../utilities/socket/CQ";
import { recordingDestinations } from "../../utilities/definitions";
import { useClickTimeout, useTimer } from "../../utilities/customHooks";
import AlertDialogButton from "../../components/AlertDialogButton";
import TooltipButton from "../../components/TooltipButton";
import CustomTooltip from "../../components/Tooltip";

type Props = {
  cmp: any;
  eavesdropper: any;
  serverTimeOffset: number;
  type: "participant" | "program";
};

const EavesdropTab: FunctionComponent<Props> = (props) => {
  const { cmp, eavesdropper, serverTimeOffset, type } = props;
  const { isConnected, status, timeStarted } = eavesdropper;
  const { inCall, macAddress, network } = cmp;

  const isRecording = status === "Recording";

  const recordingDestination =
    recordingDestinations[cmp.recordingDestination] ?? "Unknown";

  const {
    triggerTimeout: triggerRecordTimeout,
    isTimeoutActive: isRecordTimeoutActive,
  } = useClickTimeout(5);

  const startingTime = timeStarted && isRecording ? timeStarted : null;

  const { durationString } = useTimer(startingTime, "second", serverTimeOffset);

  const emitData = {
    macAddress: macAddress,
    network: network,
    type: type,
  };

  function handleCloudButtonClick() {
    CQ.emit("transferEavesDropRecordingsToCloud");
  }

  function handleRecordButtonClick() {
    triggerRecordTimeout();
    const event = isRecording ? "stopEavesDropping" : "startEavesDropping";
    CQ.emit(event, emitData);
  }

  function handleToggleAutoRecordingClick() {
    CQ.emit("toggleEavesDropAutoRecording");
  }

  function getStatusColor(status) {
    if (isRecordTimeoutActive) {
      return "secondary";
    } else {
      if (status.indexOf("Error - ") > -1) {
        return "warning";
      } else {
        switch (status) {
          case "Recording":
            return "secondary";
          case "Idle":
            return "success";
          default:
            return "secondary";
        }
      }
    }
  }

  const getToolMessage = (action) => {
    if (!isConnected) {
      return `Cannot ${action} while the EavesDrop device is offline.`;
    } else if (isRecording) {
      return `Cannot ${action} while a recording is in progress`;
    } else {
      const capitalizedAction = `${action
        .charAt(0)
        .toUpperCase()}${action.slice(1)}`;
      return `${capitalizedAction}. Use at your own risk!`;
    }
  };

  const isToolDisabled = !isConnected || isRecording;

  return (
    <Fragment>
      {eavesdropper && (
        <Grid container spacing={1}>
          <Grid container direction="column" item>
            <Grid item>
              <strong>ID:</strong>&nbsp;
              {eavesdropper.systemName.toUpperCase()}
            </Grid>
            <Grid item>
              <strong>Media:</strong>&nbsp;
              {recordingDestination.toUpperCase()}
            </Grid>
            <Grid item>
              <strong>Status:</strong>&nbsp;
              {eavesdropper.status.toUpperCase()}
            </Grid>
            <Grid item>
              <strong>Duration:</strong>&nbsp;
              {isRecording ? durationString : "N/A"}
            </Grid>
            {type === "participant" && (
              <Grid item>
                <strong>Auto Record:</strong>&nbsp;
                <CustomTooltip
                  color="primary"
                  placement="top"
                  title={
                    isRecording
                      ? "Cannot change Auto Record settings while a recording is in progress"
                      : "Toggle Auto Record On Call Stop/Start."
                  }
                >
                  <Switch
                    checked={eavesdropper.isAutoRecording}
                    color="primary"
                    disabled={isRecording}
                    onChange={handleToggleAutoRecordingClick}
                  />
                </CustomTooltip>
              </Grid>
            )}
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <AlertDialogButton
                actions={[
                  {
                    label: "Confirm",
                    onClick: handleRecordButtonClick,
                  },
                ]}
                className="eavesdrop-control-button"
                color={getStatusColor(status)}
                dialogText={
                  !inCall && status !== "Recording"
                    ? "You are not in a call.  Starting a recording at this point will result in your file not having an accurate name or timestamp.  Are you sure you want to continue?"
                    : ""
                }
                dialogTitle=""
                isDisabled={isRecordTimeoutActive || !isConnected}
                text={isRecording ? "Stop Recording" : "Start Recording"}
                title={isRecording ? "Stop Recording" : "Start Recording"}
              />
            </Grid>
            <Grid item>
              <AlertDialogButton
                actions={[
                  {
                    label: "Confirm",
                    onClick: () => {
                      CQ.emit("reauthorizeEavesDropperDevice", emitData);
                    },
                  },
                ]}
                className="eavesdrop-control-button"
                color="warning"
                dialogText={`This will reset authentication for your ${type} EavesDrop device.  Are you sure you wish to continue?`}
                dialogTitle="Authenticate Device"
                isDisabled={isToolDisabled}
                text="Authenticate"
                title={getToolMessage("reauthenticate device")}
              />
            </Grid>
            <Grid item>
              <TooltipButton
                className="eavesdrop-control-button"
                isDisabled={isRecording}
                text="Upload"
                title={
                  isRecording
                    ? "Cannot trigger upload while a recording is in progress"
                    : "Manually trigger upload process.  Use at your own risk!"
                }
                onClick={handleCloudButtonClick}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default EavesdropTab;
