import {
  Fragment,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from "react";
import AlertDialog, { AlertDialogProps } from "../AlertDialog";
import TooltipButton from "../TooltipButton";
import SelectEndpoint from "./SelectEndpoint";
import { parseMultiboxName } from "../../utilities/utilityFunctions";
import { ParticipantType } from "../../store/types";
import { useSelector } from "../../hooks/redux";
import { useSocket } from "../../hooks/socket";
import { useAuthorizer } from "../../hooks";
import EnterEndpoint from "./EnterEndpoint";
import mustache from "mustache";
import * as templates from "./templates";

type Props = {
  assignmentCallback?: () => void;
  participant: any;
  productionEventGuid?: string;
  sessionGuid?: string;
};

const AssignParticipant: FunctionComponent<Props> = (props) => {
  const { assignmentCallback, participant, productionEventGuid, sessionGuid } =
    props;

  const { temporary: isQuickParticipant, type } = participant;

  const [alertDialogProps, setAlertDialogProps] = useState<AlertDialogProps>({
    actions: [],
    children: null,
    dialogTitle: templates.assignmentAlerts.title,
    handleClose: () => {
      setAlertDialogProps((prev) => ({ ...prev, isOpen: false }));
    },
    isOpen: false,
  });

  const currentEndpointId = useSelector(
    (state) => state.queue.currentScreener as string | undefined
  );

  const endpoints = useSelector(
    (state) => state.queue.screeners as Record<string, any>
  );

  const authorize = useAuthorizer();

  const isUserAdmin = authorize("queueadmin");
  const isUserPrescreener = authorize("callscreener");
  const isUserFullscreener = authorize("callproducer");
  const canUserControlCrowdViews = authorize("multiboxcontroller");

  const currentEndpoint = currentEndpointId
    ? endpoints[currentEndpointId]
    : null;

  const {
    assignedEndpoints = [],
    id,
    guid,
    type: participantType = ParticipantType.STAGEDOOR,
  } = participant;

  const participantId = guid ?? id;

  const isParticipantAssigned = Boolean(assignedEndpoints.length);
  const assignedEndpoint = endpoints[assignedEndpoints?.[0]];

  const isEndpointCrowdView =
    currentEndpoint?.name?.includes?.("multibox_") ?? false;

  const socketService = useSocket();

  const handleAssignmentClick = useCallback(
    (endpoint: any) => {
      const { assignedCaller, name: endpointId, inCall } = endpoint;

      const emitAssignParticipant = () => {
        socketService.emit("assignParticipant", {
          endpointID: endpointId,
          participantID: participantId,
          participantType,
          productionEventGuid,
          sessionGuid,
        });

        assignmentCallback?.();
      };

      const isParticipantCrowdViewCompatible =
        participantType === ParticipantType.QUEUE ||
        participantType === ParticipantType.STAGEDOOR;

      if (isEndpointCrowdView) {
        if (isParticipantCrowdViewCompatible) {
          emitAssignParticipant();
        } else {
          setAlertDialogProps((prev) => ({
            ...prev,
            actions: [],
            dialogText: templates.assignmentAlerts.crowdViewIncompatible.text,
            isOpen: true,
          }));
        }
      } else {
        if (assignedCaller && !inCall) {
          setAlertDialogProps((prev) => ({
            ...prev,
            actions: [{ label: "Yes", onClick: emitAssignParticipant }],
            dialogText: templates.assignmentAlerts.changeAssignment.text,

            isOpen: true,
          }));
        } else if (inCall) {
          setAlertDialogProps((prev) => ({
            ...prev,
            actions: [],
            dialogText: templates.assignmentAlerts.inCall.text,
            isOpen: true,
          }));
        } else {
          emitAssignParticipant();
        }
      }
    },
    [
      assignmentCallback,
      isEndpointCrowdView,
      participantId,
      participantType,
      productionEventGuid,
      sessionGuid,
      socketService,
    ]
  );

  const isNotQueueAndDoesntHaveSession =
    type !== ParticipantType.QUEUE && !sessionGuid;

  const isInCall = Boolean(currentEndpoint?.inCall);

  const assignButtonTooltip = useMemo(() => {
    if (isQuickParticipant) {
      return templates.currentEndpointButton.tooltip.quickParticipant;
    }
    if (isInCall) {
      return mustache.render(templates.currentEndpointButton.tooltip.inCall, {
        endpoint: parseMultiboxName(currentEndpointId),
      });
    } else {
      if (isNotQueueAndDoesntHaveSession) {
        return templates.currentEndpointButton.tooltip.notInvited;
      }
      if (assignedEndpoint) {
        return mustache.render(
          templates.currentEndpointButton.tooltip.assigned,
          {
            endpoint: parseMultiboxName(assignedEndpoint.name),
          }
        );
      }
      return mustache.render(
        templates.currentEndpointButton.tooltip.notAssigned,
        {
          endpoint: parseMultiboxName(currentEndpointId),
        }
      );
    }
  }, [
    assignedEndpoint,
    currentEndpointId,
    isInCall,
    isNotQueueAndDoesntHaveSession,
    isQuickParticipant,
  ]);

  const isDirectAssignmentDisabled = useMemo(() => {
    if (isEndpointCrowdView) {
      return (
        isQuickParticipant ||
        isParticipantAssigned ||
        isNotQueueAndDoesntHaveSession
      );
    } else {
      return (
        isQuickParticipant ||
        isInCall ||
        isParticipantAssigned ||
        isNotQueueAndDoesntHaveSession
      );
    }
  }, [
    isEndpointCrowdView,
    isQuickParticipant,
    isInCall,
    isParticipantAssigned,
    isNotQueueAndDoesntHaveSession,
  ]);

  return (
    <Fragment>
      {currentEndpoint && (
        <TooltipButton
          color="warning"
          isDisabled={isDirectAssignmentDisabled}
          text={mustache.render(templates.currentEndpointButton.text, {
            endpoint: parseMultiboxName(currentEndpointId),
          })}
          title={assignButtonTooltip}
          onClick={() => handleAssignmentClick(currentEndpoint)}
        />
      )}
      {!currentEndpoint && isParticipantAssigned && assignedEndpoint && (
        <EnterEndpoint
          canUserControlCrowdViews={canUserControlCrowdViews}
          endpoint={assignedEndpoint}
          isUserAdmin={isUserAdmin}
          isUserFullscreener={isUserFullscreener}
          isUserPrescreener={isUserPrescreener}
        />
      )}
      {!currentEndpoint && !isParticipantAssigned && (
        <SelectEndpoint
          currentEndpointId={currentEndpointId}
          disabled={isNotQueueAndDoesntHaveSession}
          endpoints={endpoints}
          handleAssignmentClick={handleAssignmentClick}
          isParticipantAssigned={isParticipantAssigned}
          isUserFullscreener={isUserFullscreener}
          isUserPrescreener={isUserPrescreener}
          participantType={participantType}
        />
      )}

      <AlertDialog {...alertDialogProps}></AlertDialog>
    </Fragment>
  );
};

export default AssignParticipant;
