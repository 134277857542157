import type { Context } from "react";

import { createContext } from "react";
import { SocketService } from "./socketService";

export * from "./socketService";

export const SocketContext: Context<SocketService> = createContext(
  new SocketService()
);
