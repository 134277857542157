import React from "react";
import { Form, FormGroup, FormControl } from "react-bootstrap";
import TooltipButton from "../../../components/TooltipButton";
import { compareNewPropsBasedOnState } from "../../../utilities/utilityFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ChatDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callerID: props.callerID,
      chatMessages: props.chatMessages,
      chatOpened: this.props.chatOpened,
    };

    this.sendChat = this.sendChat.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  scrollToBottom() {
    let d = document.getElementById("messageHistory");
    if (d) {
      d.scrollTop = d.scrollHeight;
    }
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    compareNewPropsBasedOnState(
      this.state,
      prevProps,
      this.props,
      (_updatedState) => {
        this.scrollToBottom();
      }
    );
  }

  sendChat(e) {
    e.preventDefault();
    this.props.chatToServer({
      message: document.getElementById("chatText").value || "",
      callerID: this.state.callerID,
    });
    document.getElementById("chatText").value = "";
  }

  renderChatHistory(chatMessages) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {chatMessages.map((i, j) => {
          let entry = i;

          let bubbleClass = "you";
          let bubbleDirection = "bubble-direction-reverse";
          let senderText = entry.sender;
          let senderColor = "red";

          // If not the participant's name, then be red
          if (entry.sender === "Call Producer") {
            bubbleClass = "me";
            bubbleDirection = "";
            senderText = "Me";
            senderColor = "blue";
          }

          return (
            <div
              className={`bubble-container ${bubbleDirection}`}
              key={j}
              style={{ display: "inline-block" }}
            >
              <div
                className={`bubble ${bubbleClass} text-left`}
                style={{ overflowWrap: "break-word", color: "#000000" }}
              >
                <p>
                  <b style={{ color: senderColor }}>
                    <small>{senderText}:</small>
                  </b>
                  &nbsp;{entry.message}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div>
        <div>
          <FontAwesomeIcon icon={["fas", "comments"]} />{" "}
          <b>Chat with this Participant</b>
        </div>
        <div
          id="messageHistory"
          style={{
            height: "40vh",
            overflowY: "scroll",
            overflowWrap: "break-word",
          }}
        >
          {this.renderChatHistory(this.props.chatMessages)}
        </div>
        <div style={{ width: "100% !important" }}>
          <Form
            inline
            onSubmit={(e) => {
              this.sendChat(e);
            }}
          >
            <FormGroup>
              <FormControl
                id="chatText"
                placeholder="Type a message"
                type="text"
              />
            </FormGroup>{" "}
            <TooltipButton
              color="primary"
              icon={["fas", "paper-plane"]}
              id="btnSendChat"
              title={"Send"}
              type="submit"
            />
          </Form>
        </div>
      </div>
    );
  }
}

export default ChatDisplay;
