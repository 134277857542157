import type { FunctionComponent } from "react";
import type { SxProps } from "@mui/material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export type Action = {
  label: string;
  onClick: any;
  isDisabled?: boolean;
  closeOnClick?: boolean;
};

export interface AlertDialogProps {
  actions?: Action[];
  cancelText?: string;
  children?: any;
  dialogText?: string;
  dialogTitle: string;
  disableBackdropClick?: boolean;
  isOpen: boolean;
  handleClose: () => void;
  sx?: SxProps;
}

const AlertDialog: FunctionComponent<AlertDialogProps> = (props) => {
  const {
    actions = [],
    cancelText = "Cancel",
    children,
    dialogText,
    dialogTitle,
    isOpen,
    handleClose,
    sx,
  } = props;

  return (
    <Dialog
      open={isOpen}
      sx={sx}
      onClick={(event) => event.stopPropagation()}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        <div>{dialogTitle}</div>
      </DialogTitle>
      <DialogContent>
        {children}
        <DialogContentText id="alert-dialog-description">
          {dialogText ?? ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button key="cancel" onClick={handleClose}>
          {cancelText}
        </Button>
        {actions?.map((action) => (
          <Button
            disabled={action.isDisabled ?? false}
            key={action.label}
            onClick={() => {
              const { closeOnClick = true } = action;
              action.onClick();

              if (closeOnClick) {
                handleClose();
              }
            }}
          >
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
