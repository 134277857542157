import type { FunctionComponent } from "react";
import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pluralize from "pluralize";
import MultiboxStatusPreviewForScreenerCard from "../../Screener/Multibox/MultiboxStatusPreviewForScreenerCard";
import ParticipantEavesDropRecordingStatus from "./ParticipantEavesDropRecordingStatus";
import Selfie from "../Caller/Selfie";
import UnlockButton from "./UnlockButton";
import { parseMultiboxName } from "../../../utilities/utilityFunctions";
import { branding, participantTypeEnums } from "../../../utilities/definitions";
import checkIfRoleChoicesAllows from "../../../utilities/checkIfRoleChoicesAllows";
import {
  determineCrowdviewSeatsAvailable,
  determineCrowdviewParticipantsWaiting,
} from "../../../utilities/crowdViewStatus";
import TooltipButton from "../../../components/TooltipButton";
import MonitoringFeed from "./MonitoringFeed";

const useStyles = makeStyles((theme) => ({
  actions: {
    display: "block",
    flexGrow: 1,
    maxWidth: "100%",
    padding: 0,
    paddingTop: 3,
    paddingBottom: 6,
  },
  root: {
    overflow: "visible",
    padding: 5,
    position: "relative",
  },
  content: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 5,
    paddingRight: 5,
    textOverflow: "wrap",
    "& .detail": {
      maxWidth: "100%",
      overflowWrap: "break-word",
    },
  },
  crowdViewText: {
    fontSize: "smaller",
    marginTop: theme.spacing(0.5),
    textOverflow: "ellipsis",
  },
  media: {
    "& img": {
      borderRadius: 3,
      maxWidth: "100%",
      width: "auto",
    },
  },
}));

type Props = {
  controlPanel: any;
  currentRole: any;
  parentFuncs: any;
  roleChoices: any;
};

const EndpointCard: FunctionComponent<Props> = (props) => {
  const { controlPanel, currentRole, roleChoices } = props;

  const {
    allocatedCMP = {},
    assignedCaller = {},
    inCall = false,
    name = "Unknown",
    qManagerInControl = false,
  } = controlPanel;

  const isQueue = assignedCaller?.type === participantTypeEnums.QUEUE;

  const classes = useStyles();

  const isPrescreenEndpoint = name.toLowerCase().includes("prescreen");

  const endpointIsCrowdView = name.includes("multibox_");

  const multibox = endpointIsCrowdView ? controlPanel.multibox : false;

  const seatStatus =
    endpointIsCrowdView && multibox ? multibox.seatStatus : false;

  const assignedMultiboxParticipants = endpointIsCrowdView
    ? controlPanel.assignedMultiboxParticipants
    : false;

  const [qmIsPrescreener, setQmIsPrescreener] = useState(false);
  const [qmIsFullScreener, setQmIsFullscreener] = useState(false);
  const [qmCanControlCrowdView, setQmCanControlCrowdView] = useState(false);

  const endpointIsLockedByRoles = useMemo(() => {
    if (endpointIsCrowdView) {
      return !qmCanControlCrowdView;
    }
    if (isPrescreenEndpoint) {
      return !qmIsPrescreener;
    }
    return !qmIsFullScreener;
  }, [
    endpointIsCrowdView,
    isPrescreenEndpoint,
    qmCanControlCrowdView,
    qmIsFullScreener,
    qmIsPrescreener,
  ]);

  const endpointIsLockedOut =
    endpointIsLockedByRoles ||
    qManagerInControl !== false ||
    currentRole.name === "queueAdmin";

  const screenerDisplayName = parseMultiboxName(name);

  const assignedMultiboxParticipantsCount = endpointIsCrowdView
    ? Object.keys(assignedMultiboxParticipants).length
    : 0;

  const assignedParticipantDisplayText = useMemo(() => {
    if (endpointIsCrowdView) return screenerDisplayName;
    if (assignedCaller)
      return (
        assignedCaller.fullName ??
        `${assignedCaller.given_name} ${assignedCaller.family_name}`
      );
    return "No Assigned Participant";
  }, [assignedCaller, endpointIsCrowdView, screenerDisplayName]);

  const allocatedCMPtext = useMemo(() => {
    return `${allocatedCMP?.friendlyName ?? "None"}`;
  }, [allocatedCMP]);

  const takeAssignmentButtonColor = useMemo(() => {
    if (inCall) {
      return "error";
    }
    if (qManagerInControl) {
      return "warning";
    }

    return "primary";
  }, [inCall, qManagerInControl]);

  const endpointDescriptionText = useMemo(() => {
    const isInCall = Boolean(inCall); // inCall can be false or an object, sorry.
    const isInUse = Boolean(qManagerInControl);

    const isInUseText = isInUse
      ? ` is in use by ${qManagerInControl?.username}`
      : " is not in use";

    let participantCount = 0;

    if (endpointIsCrowdView) {
      participantCount = assignedMultiboxParticipantsCount;
    } else if (assignedCaller) {
      participantCount = 1;
    }

    const hasParticipantText = `, and has ${pluralize(
      "participant",
      participantCount,
      true
    )} assigned`;

    let isInCallText = "";

    if (isInCall) {
      isInCallText = endpointIsCrowdView
        ? `, and has an active ${branding.crowdview} session`
        : ", and is in a call";
    }

    return `Control Panel ${screenerDisplayName}${isInUseText}${hasParticipantText}${isInCallText}${
      isInUse ? ";  Unlock to enter, if allowed" : ";  Click to enter"
    }`;
  }, [
    assignedCaller,
    assignedMultiboxParticipantsCount,
    endpointIsCrowdView,
    inCall,
    screenerDisplayName,
    qManagerInControl,
  ]);

  const endpointStatusIcon: IconProp = useMemo(() => {
    if (endpointIsCrowdView) {
      return ["fas", "th"];
    } else {
      if (inCall) {
        return ["fas", "video"];
      } else if (assignedCaller) {
        return ["fas", "user"];
      }

      return ["fas", "user-alt-slash"];
    }
  }, [endpointIsCrowdView, inCall, assignedCaller]);

  const locationText = assignedCaller?.location || "No Location Provided";
  const notAllowedText = "Your role prevents you from accessing this Endpoint";
  const participantSnapshotUrl = assignedCaller?.snapshotURL || "";
  const topicText = assignedCaller?.topic || "No Topic Selected";

  const crowdviewParticipantsWaitingCount =
    determineCrowdviewParticipantsWaiting(assignedMultiboxParticipants);

  const crowdviewSeatsAvailableCount =
    determineCrowdviewSeatsAvailable(seatStatus);

  const [expanded, setExpanded] = useState<boolean>(false);

  const monitoringFeed = allocatedCMP?.monitoringFeed ?? undefined;
  const hasMonitoringFeed = monitoringFeed?.length > 0;

  useEffect(() => {
    const tempQmIsPrescreener = checkIfRoleChoicesAllows(
      roleChoices,
      "callScreener"
    );

    const tempQmIsFullScreener = checkIfRoleChoicesAllows(
      roleChoices,
      "callProducer"
    );

    const tempQmCanControlCrowdView = checkIfRoleChoicesAllows(
      roleChoices,
      "multiboxController"
    );

    setQmIsPrescreener(tempQmIsPrescreener);
    setQmIsFullscreener(tempQmIsFullScreener);
    setQmCanControlCrowdView(tempQmCanControlCrowdView);
  }, [roleChoices]); // roleChoices can change asynchronously upstream, so the var values that have basis in its properties can change (from all false while awaiting a response from Okta following login success).  This hook should only fire if roleChoices changes subsequently, which is improbable at best.

  return (
    <Card className={classes.root} variant="outlined">
      <CardActions className={classes.actions}>
        <Grid container direction="column">
          {endpointIsLockedByRoles && (
            <Grid item>
              <small>{notAllowedText}</small>
            </Grid>
          )}

          <Grid item>
            <div
              className="fullWidth"
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width:
                    currentRole.name !== "queueAdmin" && qManagerInControl
                      ? "60%"
                      : "79.8%",
                }}
              >
                <TooltipButton
                  className="fullWidth"
                  color="primary"
                  isDisabled={endpointIsLockedOut}
                  style={{
                    justifyContent: "space-between",
                    textAlign: "left",
                  }}
                  text={
                    <span>
                      {screenerDisplayName}&nbsp;
                      <Chip
                        color={takeAssignmentButtonColor}
                        label={<FontAwesomeIcon icon={endpointStatusIcon} />}
                        size="small"
                        style={{ textTransform: "none" }}
                      />
                    </span>
                  }
                  title={endpointDescriptionText}
                  onClick={() => {
                    props.parentFuncs.screenerSelected(name);
                  }}
                />
              </div>

              {currentRole.name !== "queueAdmin" && qManagerInControl && (
                <div style={{ width: "19%" }}>
                  <UnlockButton
                    currentRole={currentRole}
                    endpointIsLockedByRoles={endpointIsLockedByRoles}
                    endpointName={name}
                    qManagerInControl={qManagerInControl}
                  />
                </div>
              )}

              <div style={{ width: "19%" }}>
                <TooltipButton
                  className="fullWidth"
                  color={"primary"}
                  icon={expanded ? ["fas", "caret-up"] : ["fas", "caret-down"]}
                  title={`Click to show ${expanded ? "less" : "more"} details${
                    !expanded && hasMonitoringFeed ? " and preview stream" : ""
                  }`}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </CardActions>
      {expanded && (
        <>
          <CardMedia className={classes.media}>
            <div className="endpointCardPreviewContainer">
              {endpointIsCrowdView && (
                <MultiboxStatusPreviewForScreenerCard
                  currentScreener={controlPanel}
                />
              )}
              {!endpointIsCrowdView && hasMonitoringFeed && (
                <MonitoringFeed
                  feed={monitoringFeed}
                  friendlyName={allocatedCMP?.friendlyName}
                  id={`${allocatedCMP?.friendlyName}-monitoring-summary-list`}
                />
              )}
              {!endpointIsCrowdView &&
                !hasMonitoringFeed &&
                assignedCaller &&
                isQueue && (
                  <Selfie
                    id={`${screenerDisplayName}-selfie`}
                    snapshotURL={participantSnapshotUrl}
                    style={{}}
                    onClick={false}
                  />
                )}
              <div className="endpointCardEavesdropStatus">
                {allocatedCMP?.participantEavesDropper && (
                  <ParticipantEavesDropRecordingStatus
                    participantEavesDropper={
                      allocatedCMP?.participantEavesDropper
                    }
                  />
                )}
              </div>
            </div>
          </CardMedia>
          <CardContent className={classes.content}>
            {endpointIsCrowdView ? (
              <>
                <Typography className={classes.crowdViewText}>
                  Seats Available: {crowdviewSeatsAvailableCount}
                </Typography>
                <Typography className={classes.crowdViewText}>
                  Participants Waiting: {crowdviewParticipantsWaitingCount}
                </Typography>
                <Typography className={classes.crowdViewText}>
                  <b>Machine</b>: {allocatedCMPtext}
                </Typography>
              </>
            ) : (
              <>
                <Typography gutterBottom variant="h6">
                  {assignedParticipantDisplayText}
                </Typography>

                <Typography className={classes.crowdViewText}>
                  <b>Machine</b>: {allocatedCMPtext}
                </Typography>

                {assignedCaller && isQueue && (
                  <>
                    <div className="detail">
                      <FontAwesomeIcon icon={["fas", "map-marker"]} />
                      &nbsp;
                      {locationText}
                    </div>

                    <div className="detail">
                      <FontAwesomeIcon icon={["fas", "lightbulb"]} />
                      &nbsp;
                      {topicText}
                    </div>
                  </>
                )}
              </>
            )}
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default EndpointCard;
