import type { FunctionComponent } from "react";

import { Fragment, useCallback, useState } from "react";
import { Dialog, DialogTitle, Grid } from "@mui/material";
import { useHttpRequest } from "../hooks/rest";
import * as template from "./BaseParticipantExpandedRow/template";
import LimitedBackdrop from "../components/LimitedBackdrop";
import TextFieldForm from "../components/TextFieldForm";
import {
  emailValidationSchema,
  facetimeValidationSchema,
  nameValidationSchema,
  phoneValidationSchema,
} from "../utilities/validators";
import DuplicateParticipantsWarning from "./CreateParticipant/DuplicateParticipantsWarning";
import { useSnackbar } from "../hooks";
import { StatusCodes } from "http-status-codes";

type Contact = {
  guid: string;
  type: string;
  value: string;
};

type Props = {
  contactInfo: {
    accountGuid: string;
    productionGuid: string;
    email?: Contact;
    facetime?: Contact;
    phone?: Contact;
    onSave: () => Promise<void>;
  };
  contactOnly?: boolean;
  participant: any;
  participantAlreadyAssignedSomewhere?: boolean;
};

const EditParticipantForm: FunctionComponent<Props> = (props) => {
  const {
    contactInfo,
    contactOnly,
    participant,
    participantAlreadyAssignedSomewhere = false,
  } = props;

  const { fullName, guid } = participant;

  const {
    accountGuid,
    productionGuid,
    email: emailContact,
    facetime: facetimeContact,
    phone: phoneContact,
    onSave,
  } = contactInfo;

  const { enqueueSnackbar } = useSnackbar();

  const [duplicateParticipantGuids, setDuplicateParticipantGuids] = useState<
    string[]
  >([]);
  const [desiredFullName, setDesiredFullName] = useState<string | null>(null);
  const foundDuplicates = duplicateParticipantGuids.length > 0;

  const resetDuplicateTracker = useCallback(() => {
    setDuplicateParticipantGuids([]);
    setDesiredFullName(null);
  }, []);

  const email = emailContact?.value ?? "";
  const phone_number = phoneContact?.value ?? "";
  const facetime = facetimeContact?.value ?? "";

  const [createContactRequest, createContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}/contacts`,
    {
      method: "post",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const [deleteContactRequest, deleteContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}/contacts/`,
    {
      method: "delete",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const [updateContactRequest, updateContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}/contacts/`,
    {
      method: "put",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const [updateFullNameRequest, updateFullNameRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}/`,
    {
      method: "put",
      body: {
        accountGuid,
        productionGuid,
      },
      onError: async (error) => {
        enqueueSnackbar("Failed to update participant name.", {
          variant: "error",
        });

        if (error?.response?.status === StatusCodes.CONFLICT) {
          const json = await error?.response?.json?.();
          const duplicates = json?.data?.duplicates;

          if (duplicates) {
            setDuplicateParticipantGuids(duplicates);
          }
        }
      },
      onSuccess: () => {
        resetDuplicateTracker();
        enqueueSnackbar("Successfully updated participant name.", {
          variant: "success",
        });
      },
    }
  );

  const isRequestingContactChange =
    createContactRequestState.loading ||
    deleteContactRequestState.loading ||
    updateContactRequestState.loading ||
    updateFullNameRequestState.loading;

  return (
    <Fragment>
      <Grid container direction="column" spacing={1}>
        <LimitedBackdrop open={isRequestingContactChange} />

        {!contactOnly && (
          <Grid item>
            <TextFieldForm
              disabled={participantAlreadyAssignedSomewhere}
              initialValue={fullName}
              label={template.formFields.fullName.label}
              name="fullName"
              placeholder={template.formFields.fullName.placeholder}
              required
              updateRequest={async (name, value) => {
                await updateFullNameRequest({ body: { [name]: value } });
                setDesiredFullName(value);
              }}
              validationSchema={nameValidationSchema}
              onSave={onSave}
            />
          </Grid>
        )}

        <Grid item>
          <TextFieldForm
            createRequest={(name, value) =>
              createContactRequest({
                body: {
                  type: name,
                  value,
                },
              })
            }
            deleteRequest={(_name) =>
              deleteContactRequest({
                route: emailContact?.guid,
              })
            }
            disabled={participantAlreadyAssignedSomewhere}
            initialValue={email}
            label={template.formFields.email.label}
            name="email"
            placeholder={template.formFields.email.placeholder}
            updateRequest={(_name, value) =>
              updateContactRequest({
                route: emailContact?.guid,
                body: {
                  value,
                },
              })
            }
            validationSchema={emailValidationSchema.optional()}
            onSave={onSave}
          />
        </Grid>

        <Grid item>
          <TextFieldForm
            createRequest={(name, value) =>
              createContactRequest({
                body: {
                  type: name,
                  value,
                },
              })
            }
            deleteRequest={(_name) =>
              deleteContactRequest({
                route: phoneContact?.guid,
              })
            }
            disabled={participantAlreadyAssignedSomewhere}
            initialValue={phone_number}
            label={template.formFields.phone_number.label}
            name="phone"
            placeholder={template.formFields.phone_number.placeholder}
            updateRequest={(_name, value) =>
              updateContactRequest({
                route: phoneContact?.guid,
                body: {
                  value,
                },
              })
            }
            validationSchema={phoneValidationSchema.optional()}
            onSave={onSave}
          />
        </Grid>

        <Grid item>
          <TextFieldForm
            createRequest={(name, value) =>
              createContactRequest({
                body: {
                  type: name,
                  value,
                },
              })
            }
            deleteRequest={(_name) =>
              deleteContactRequest({
                route: facetimeContact?.guid,
              })
            }
            disabled={participantAlreadyAssignedSomewhere}
            initialValue={facetime}
            label={template.formFields.facetime.label}
            name="facetime"
            placeholder={template.formFields.facetime.placeholder}
            updateRequest={(_name, value) =>
              updateContactRequest({
                route: facetimeContact?.guid,
                body: {
                  value,
                },
              })
            }
            validationSchema={facetimeValidationSchema.optional()}
            onSave={onSave}
          />
        </Grid>
      </Grid>

      <Dialog open={foundDuplicates}>
        <DialogTitle>Potential Duplicate Participant(s) Found</DialogTitle>

        <DuplicateParticipantsWarning
          duplicateParticipantGuids={duplicateParticipantGuids}
          handleCancelClick={resetDuplicateTracker}
          handleProceedClick={async () => {
            await updateFullNameRequest({
              body: {
                bypassDuplicateCheck: true,
                fullName: desiredFullName,
              },
            });
          }}
        />
      </Dialog>
    </Fragment>
  );
};

export default EditParticipantForm;
