import type { FunctionComponent } from "react";
import type { BackdropProps } from "@mui/material";

import { Backdrop, CircularProgress } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    zIndex: 99999,
  },
});

const LimitedBackdrop: FunctionComponent<BackdropProps> = (props) => {
  const classes = useStyles();

  return (
    <Backdrop {...props} classes={classes}>
      <CircularProgress />
    </Backdrop>
  );
};

export default LimitedBackdrop;
