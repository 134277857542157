import type { FunctionComponent } from "react";
import { Badge } from "@mui/material";
import MenuButton from "../MenuButton";
import {
  sortStringsLikeHuman,
  parseMultiboxName,
} from "../../utilities/utilityFunctions";
import { ParticipantType } from "../../store/types";
import * as templates from "./templates";

type Props = {
  currentEndpointId: string;
  disabled?: boolean;
  endpoints: Record<string, any>;
  isParticipantAssigned: boolean;
  isUserFullscreener: boolean;
  isUserPrescreener: boolean;
  participantType: ParticipantType;
  handleAssignmentClick: (endpoint: any) => void;
};

const SelectEndpoint: FunctionComponent<Props> = (props) => {
  const {
    currentEndpointId,
    disabled = false,
    endpoints,
    isParticipantAssigned,
    isUserFullscreener,
    isUserPrescreener,
    participantType,
    handleAssignmentClick,
  } = props;

  const availableEndpoints = Object.values(endpoints)
    .filter((endpoint) => {
      const { assignedCaller, name } = endpoint;
      const isEndpointCrowdView = name.includes("multibox_");

      if (
        name === currentEndpointId ||
        (!isEndpointCrowdView && assignedCaller)
      ) {
        return false;
      }

      if (isEndpointCrowdView) {
        return (
          participantType === ParticipantType.QUEUE ||
          participantType === ParticipantType.STAGEDOOR
        );
      }

      return true;
    })
    .sort((endpoint1, endpoint2) => {
      return sortStringsLikeHuman(
        parseMultiboxName(endpoint1.name),
        parseMultiboxName(endpoint2.name)
      );
    });

  return (
    <>
      {(isUserPrescreener || isUserFullscreener) && (
        <Badge
          badgeContent={availableEndpoints.length}
          color="secondary"
          variant="standard"
        >
          <MenuButton
            color="warning"
            disabled={isParticipantAssigned || disabled}
            items={availableEndpoints.map((endpoint, index) => ({
              children: parseMultiboxName(endpoint.name),
              key: index,
              onClick: () => handleAssignmentClick(endpoint),
            }))}
            text={templates.selectEndpointButton.text}
            title={
              disabled && !isParticipantAssigned
                ? templates.selectEndpointButton.tooltip.notInvited
                : isParticipantAssigned
                ? templates.selectEndpointButton.tooltip.assigned
                : templates.selectEndpointButton.tooltip.notAssigned
            }
          />
        </Badge>
      )}
    </>
  );
};

export default SelectEndpoint;
