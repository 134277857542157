import type { FunctionComponent } from "react";
import type { MenuButtonItem } from "../components/MenuButton";

import { Fragment, useCallback, useEffect, useState } from "react";

import {
  useHttpRequest,
  useSelector,
  useSnackbar /*, useSocket*/,
} from "../hooks"; // Suppressed 10/3/2023 for merge purposes

import MenuButton from "../components/MenuButton";
import { branding } from "../utilities/definitions";
import SendInvitationDialog from "./SendInvitationDialog";

type ButtonSize = "small" | "medium" | "large";

type Props = {
  hasReceivedInvitation: boolean;
  isAssigned: boolean;
  participantGuid: string;
  screenerView?: boolean;
  size?: ButtonSize;
  //twoWaySmsEnabled: boolean; // Suppressed 10/3/2023 for merge purposes
  reloadContactsCallback?: () => Promise<void>;
  reloadSessionsCallback?: () => Promise<void>;
};

const InvitationOptions: FunctionComponent<Props> = (props) => {
  const {
    hasReceivedInvitation,
    isAssigned,
    participantGuid,
    screenerView = false,
    size = undefined,
    //twoWaySmsEnabled, // Suppressed 10/3/2023 for merge purposes
    reloadContactsCallback,
    reloadSessionsCallback,
  } = props;

  //const socketService = useSocket(); // Suppressed 10/3/2023 for merge purposes
  const { enqueueSnackbar } = useSnackbar();

  const [showInvitationDialog, setShowInvitationDialog] =
    useState<boolean>(false);
  const [sessions, setSessions] = useState<any[]>([]);

  const handleClose = () => {
    setShowInvitationDialog(false);
  };

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );

  const [fetchSessions, fetchSessionsState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/sessions`,
    {
      method: "get",
    }
  );

  const loadSessions = useCallback(async () => {
    const sessions = await fetchSessions();

    if (sessions) {
      setSessions(sessions);
    }
  }, [fetchSessions]);

  useEffect(() => {
    loadSessions();
  }, [loadSessions]);

  const session = sessions.find((s) => s.productionGuid === productionGuid);
  const hasSession = Boolean(session);

  const [createSessionRequest, createSessionState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/sessions`,
    {
      method: "post",
      body: {
        accountGuid,
        productionGuid,
      },
      onError: () => {
        enqueueSnackbar("Failed to generate invitation.", {
          variant: "error",
        });
      },
      onSuccess: () => {
        enqueueSnackbar(`New invitation generated.`, {
          variant: "info",
        });

        loadSessions();
        reloadSessionsCallback?.();
      },
    }
  );

  const [deleteSessionRequest, deleteSessionState] = useHttpRequest(
    `admin_proxy/participants/${participantGuid}/sessions/`,
    {
      method: "delete",
      body: {
        accountGuid,
        productionGuid,
      },
      onError: () => {
        enqueueSnackbar("Failed to cancel invitation.", {
          variant: "error",
        });
      },
      onSuccess: () => {
        enqueueSnackbar(`Invitation was cancelled.`, {
          variant: "info",
        });

        loadSessions();
        reloadSessionsCallback?.();
      },
    }
  );

  const isAlteringSession =
    createSessionState.loading ||
    deleteSessionState.loading ||
    fetchSessionsState.loading;

  const handleCancelInviteClick = useCallback(() => {
    deleteSessionRequest({ route: session.guid });
  }, [deleteSessionRequest, session]);

  const handleSendInviteClick = () => {
    setShowInvitationDialog(true);
  };

  /*const handleTwoWaySms = () => {
    socketService.emit(
      twoWaySmsEnabled ? "endTwoWaySms" : "initiateTwoWaySms",
      { participantGuid: participantGuid }
    );
    enqueueSnackbar(
      `Request to ${
        twoWaySmsEnabled ? "end" : "start"
      } two-way texting session has been made to SMS the service.`,
      {
        variant: "info",
      }
    );
  };*/ // Suppressed 10/3/2023 for merge purposes

  const handleInviteLinkCopy = useCallback(() => {
    enqueueSnackbar(`StageDoor invitation link was copied to your clipboard.`, {
      variant: "success",
    });
  }, [enqueueSnackbar]);

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(session?.invitationUrl);
    handleInviteLinkCopy();
  };

  let menuItems: MenuButtonItem[] = [
    {
      children: `${hasReceivedInvitation ? "Re-send" : "Send"} ${
        branding.stagedoor
      } Link`,
      disabled: !hasSession || isAlteringSession,
      onClick: handleSendInviteClick,
    },
    {
      children: `Copy ${branding.stagedoor} Link`,
      disabled: !session?.invitationUrl || isAlteringSession,
      onClick: copyLinkToClipboard,
    },
    /*{
      children: `${twoWaySmsEnabled ? "End" : "Start"} Two-Way SMS${
        hasPhoneContact ? "" : " - Phone Number Required"
      }`,
      disabled: !hasPhoneContact,
      onClick: handleTwoWaySms,
    },*/ // Suppressed 10/3/2023 for merge purposes
  ];

  if (!screenerView) {
    menuItems = [
      ...menuItems,
      {
        children: `Generate ${branding.stagedoor} Link`,
        disabled: Boolean(session) || isAlteringSession,
        onClick: () => createSessionRequest(),
      },
      {
        children: `Cancel ${branding.stagedoor} Invitation`,
        className: "importantText",
        disabled: isAssigned || !session || isAlteringSession,
        onClick: handleCancelInviteClick,
      },
    ];
  }

  return (
    <Fragment>
      <MenuButton
        color="primary"
        items={menuItems}
        size={size}
        text={`${branding.stagedoor} Options`}
        title={`Click for ${branding.stagedoor} Invitation Options`}
      />

      <SendInvitationDialog
        disableEditing={!screenerView && isAssigned}
        open={showInvitationDialog}
        participantGuid={participantGuid}
        reloadCallback={async () => {
          await reloadContactsCallback?.();
        }}
        onClose={handleClose}
      />
    </Fragment>
  );
};

export default InvitationOptions;
