export const noteField = {
  input: {
    placeholder: "Enter note",
  },
  saveButton: {
    text: "Save",
    tooltip: "Save note",
  },
};

export const participantNoteListView = {
  buttons: {
    addNote: "Add Note",
  },
  snackbars: {
    noteAdded: "Participant note created.",
  },
  tooltips: {
    addNote: "Add Note",
    cannotAddNote: "Please complete modifying a note.",
  },
};

export const viewNote = {
  deleteNoteDialog: {
    text: "This action cannot be undone.",
    title: "Delete Note?",
    confirm: "Confirm",
  },
  snackbars: {
    noteRemoved: "Participant note removed.",
    noteUpdated: "Participant note updated.",
  },
  tooltips: {
    okToModify: "Edit or Delete note.",
    onlyAuthorCanModifyBase:
      "Only the note's author ({{noteAuthor}}) can edit or delete this note.",
    notOkToModify: "Please complete modifying another note.",
  },
  selections: {
    delete: "Delete",
    edit: "Edit",
  },
};
