import React, { useCallback, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Menu, MenuItem } from "@mui/material";
import { SeatStatus } from "../../../store/types";
import { branding } from "../../../utilities/definitions";

type Props = {
  assignParticipantToSeat: any;
  inCall: boolean;
  participant: any;
  seatStatus: SeatStatus;
  unassignParticipantFromMultiboxEndpoint: any;
  canControlMultibox: boolean;
};

const SeatsList: React.FC<Props> = (props) => {
  const {
    participant,
    seatStatus,
    assignParticipantToSeat,
    unassignParticipantFromMultiboxEndpoint,
    inCall,
    canControlMultibox,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const availableSeats = Object.values(seatStatus).filter(
    ({ status }) => status === "unoccupied"
  );

  const renderAvailableSeatChoices = useCallback(
    (canControlMultibox) =>
      availableSeats.map((entry, index) => (
        <MenuItem
          disabled={!canControlMultibox}
          key={index}
          onClick={() => {
            assignParticipantToSeat(participant, entry.id);
            handleClose();
          }}
        >
          {`${inCall ? "Call on" : "Put in"} Seat #${entry.id}`}
        </MenuItem>
      )),
    [availableSeats]
  );

  const renderUnassignChoice = useCallback(
    () => (
      <MenuItem
        onClick={() => {
          unassignParticipantFromMultiboxEndpoint(participant);
          handleClose();
        }}
      >
        Unassign from {branding.crowdview}
      </MenuItem>
    ),
    [participant]
  );

  function renderDropdownTitle(_availableSeats) {
    if (!participant.assignedMultiboxSeat) {
      return (
        <span>
          <Badge variant={_availableSeats.length > 0 ? "light" : "danger"}>
            {availableSeats.length}
          </Badge>
          &nbsp;Seats Available
        </span>
      );
    } else {
      return (
        <span>{`${
          seatStatus[participant.assignedMultiboxSeat.seat].status ===
          "occupied"
            ? "Assigned to"
            : "In Call in"
        } Seat #${participant.assignedMultiboxSeat.seat}`}</span>
      );
    }
  }

  const dropdownButtonStyle: React.CSSProperties = {
    width: "100% !important",
    border: "0px transparent",
  };

  let buttonColor = "warning";
  if (!participant.assignedMultiboxSeat) {
    buttonColor = "primary";
  } else if (
    seatStatus[participant.assignedMultiboxSeat.seat].status === "calling"
  ) {
    buttonColor = "secondary";
  }

  return (
    <React.Fragment>
      <Button
        disabled={Boolean(participant.assignedMultiboxSeat)}
        style={dropdownButtonStyle}
        variant={buttonColor}
        onClick={handleClick}
      >
        {renderDropdownTitle(availableSeats)}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!participant.assignedMultiboxSeat && renderUnassignChoice()}
        {!participant.assignedMultiboxSeat &&
          renderAvailableSeatChoices(canControlMultibox)}
        <MenuItem onClick={handleClose}>Cancel</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default React.memo(SeatsList);
