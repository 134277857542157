import { Fragment, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Col, Row } from "react-bootstrap";
import EavesDropTab from "./EavesdropTab";
import LowerThirdTab from "./LowerThirdTab";
import CMPFileStatusDisplay from "./CMPFileStatusDisplay.jsx";
import { branding } from "../../utilities/definitions";
import TabPanel from "../../components/TabPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowX: "clip",
    overflowY: "auto",
    width: "100%",
  },
  tabs: {
    maxWidth: "100%",
  },
}));

const CMPInfo = (props) => {
  const { cmp, currentScreener, serverTimeOffset } = props;

  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();

  function handleTabChange(_event, value) {
    setActiveTab(value);
  }

  function renderCMPInfoTab(_cmp) {
    if (!currentScreener) return <div>No Current Screener</div>;

    return (
      <div className="fullWidth">
        {_cmp && (
          <div className="fullWidth">
            {
              /*!Boolean(_cmp.lowerThird.format) &&*/
              !_cmp.programEavesDropper && !_cmp.participantEavesDropper && (
                <Row>
                  <Col
                    className="text-muted"
                    style={{ display: "flex", justifyContent: "center" }}
                    xs={12}
                  >
                    <i>No additional features have been enabled.</i>
                  </Col>
                </Row>
              )
            }
            {
              /*_cmp.lowerThird.format ||*/
              (_cmp.programEavesDropper || _cmp.participantEavesDropper) && (
                <Row className="eavesdrop-panel-row">
                  <Col xs={4}>
                    <b>Control</b>
                  </Col>
                  <Col className="text-center" xs={4}>
                    <b>Property</b>
                  </Col>
                  <Col className="text-center" xs={4}>
                    <b>Status</b>
                  </Col>
                </Row>
              )
            }
            {/*_cmp.lowerThird.format && (
              <Row className="eavesdrop-panel-row">
                <Col xs={4}>Lower Third</Col>
                <Col className="text-center" xs={4}>
                  Displaying
                </Col>
                <Col className="text-center" xs={4}>
                  {_cmp.lowerThird
                    ? parseLowerThirdIntoSymbol(_cmp.lowerThird)
                    : "N/A"}
                </Col>
              </Row>
            )*/}
            {_cmp.programEavesDropper && (
              <Row className="eavesdrop-panel-row">
                <Col xs={4}>{branding.eavesdrop} Program</Col>
                <Col className="text-center" xs={4}>
                  Recording
                </Col>
                <Col className="text-center" xs={4}>
                  {parseEavesDropStatusIntoColoredText(
                    _cmp.programEavesDropper.status
                  )}
                </Col>
              </Row>
            )}
            {_cmp.participantEavesDropper && (
              <Row className="eavesdrop-panel-row">
                <Col xs={4}>{branding.eavesdrop} Participant</Col>
                <Col className="text-center" xs={4}>
                  Auto Recording
                </Col>
                <Col className="text-center" xs={4}>
                  {_cmp.participantEavesDropper
                    ? parseEavesDropAutoRecordingIntoSymbol(
                        _cmp.participantEavesDropper.isAutoRecording
                      )
                    : "N/A"}
                </Col>
              </Row>
            )}
            {_cmp.participantEavesDropper && (
              <Row className="eavesdrop-panel-row">
                <Col xs={4}>{branding.eavesdrop} Participant</Col>
                <Col className="text-center" xs={4}>
                  Recording
                </Col>
                <Col className="text-center" xs={4}>
                  {_cmp.participantEavesDropper
                    ? parseEavesDropStatusIntoColoredText(
                        _cmp.participantEavesDropper.status
                      )
                    : "N/A"}
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }

  function parseEavesDropAutoRecordingIntoSymbol(_b) {
    let recordingStatusSymbol = "✗";
    let fontColor = "red";

    if (_b) {
      recordingStatusSymbol = "✓";
      fontColor = "green";
    }

    return <b style={{ color: fontColor }}>{recordingStatusSymbol}</b>;
  }

  function parseEavesDropStatusIntoColoredText(_status) {
    let parsedStatus = "Idle";
    let fontColor = "green";
    if (Boolean(_status)) {
      if (_status.indexOf("Error") > -1 || _status.indexOf("Failed") > -1) {
        parsedStatus = "Error";
        fontColor = "orange";
      } else if (_status.indexOf("Recording") > -1) {
        parsedStatus = "Recording";
        fontColor = "red";
      } else if (_status.indexOf("Not Configured") > -1) {
        fontColor = "black";
      } else {
        parsedStatus = _status;
      }
    }
    return <b style={{ color: fontColor }}>&nbsp;{parsedStatus}</b>;
  }

  function parseLowerThirdIntoSymbol(_lowerThird) {
    return _lowerThird.status.isDisplaying ? (
      <b alt="Visible" style={{ color: "green" }}>
        {"✓"}
      </b>
    ) : (
      <b alt="Hidden" style={{ color: "red" }}>
        {"✗"}
      </b>
    );
  }

  function renderCMPFileStatus(_fileStatus, _timecode_required) {
    return (
      <CMPFileStatusDisplay
        files={_fileStatus}
        timecode_required={_timecode_required}
      />
    );
  }

  // const endpointIsPrescreener =
  //   currentScreener.toLowerCase().indexOf("prescreen") > -1 ? true : false;

  const shouldDisableTabs = !cmp || cmp.isAwake === false;

  return (
    <Fragment>
      {cmp && (
        <div className={classes.root}>
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            variant="scrollable"
            onChange={handleTabChange}
          >
            <Tab label="Additional Features" value={0} />

            {/*<Tab disabled={shouldDisableTabs} label="Lower Third" value={1} />*/}

            <Tab
              disabled={shouldDisableTabs}
              label={`${branding.eavesdrop}
                ${cmp.programEavesDropper ? " (Participant)" : " "}`}
              value={3}
            />

            <Tab
              disabled={shouldDisableTabs}
              label={`${branding.eavesdrop} (Program) Controls`}
              value={2}
            />

            <Tab
              disabled={shouldDisableTabs}
              label={`${branding.eavesdrop} Recordings`}
              value={4}
            />
          </Tabs>
          <TabPanel index={0} value={activeTab}>
            {renderCMPInfoTab(cmp)}
          </TabPanel>
          {/*cmp.lowerThird && cmp.lowerThird.format && (
            <TabPanel index={1} value={activeTab}>
              <LowerThirdTab cmp={cmp} />
            </TabPanel>
          )*/}

          {cmp.participantEavesDropper && (
            <TabPanel index={3} value={activeTab}>
              {
                <EavesDropTab
                  cmp={cmp}
                  eavesdropper={cmp.participantEavesDropper}
                  serverTimeOffset={serverTimeOffset}
                  type="participant"
                />
              }
            </TabPanel>
          )}

          {cmp.programEavesDropper && (
            <TabPanel index={2} value={activeTab}>
              <EavesDropTab
                cmp={cmp}
                eavesdropper={cmp.programEavesDropper}
                serverTimeOffset={serverTimeOffset}
                type="program"
              />
            </TabPanel>
          )}

          {(cmp.participantEavesDropper || cmp.programEavesDropper) && (
            <TabPanel index={4} value={activeTab}>
              {renderCMPFileStatus(cmp.fileStatus, cmp.emplaceTimecode)}
            </TabPanel>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default CMPInfo;
