import { Component, Fragment } from "react";
import { Badge, Chip, Grid, Paper, Typography } from "@mui/material";
import { Alert, ButtonGroup, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {
  sortStringsLikeHuman,
  compareNewPropsBasedOnState,
  parseMultiboxName,
} from "../utilities/utilityFunctions";
import CMPInfoAccordion from "../components/CMPInfoAccordion";
import BackButton from "../components/BackButton";
import MultiboxControls from "./Screener/Multibox/MultiboxControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import checkIfRoleChoicesAllows from "../utilities/checkIfRoleChoicesAllows";
import { branding, participantTypeEnums } from "../utilities/definitions";
import styles from "./Screener/CMPInfo.module.css";
import ParticipantSummary from "./Screener/ParticipantSummary";
import MenuButton from "../components/MenuButton";
import NetworkDiagnostics from "../components/NetworkDiagnostics";
import QuickParticipantButton from "./QuickParticipant";
import CustomTooltip from "../components/Tooltip";

class Screener extends Component {
  constructor(props) {
    super(props);

    let tempState = {
      callerModalOpen: false,
      callers: props.callers,
      currentScreener: props.currentScreener,
      gruveoLinkButtonPressed: false,
      key: 2,
      modalCaller: undefined,
      manualCaller: props.manualCaller,
      serverTimeOffset: props.serverTimeOffset,
      username: props.username,
    };

    this.state = tempState;

    this.closeCallerModal = this.closeCallerModal.bind(this);
    this.openCallerModal = this.openCallerModal.bind(this);
    this.renderMultipleEndpointAssignmentWarning =
      this.renderMultipleEndpointAssignmentWarning.bind(this);
  }

  openCallerModal(caller) {
    this.setState({ callerModalOpen: true, modalCaller: caller });
  }
  closeCallerModal() {
    this.setState({ callerModalOpen: false, modalCaller: undefined });
  }

  componentDidMount() {
    this.setState({
      key: 1,
    });
  }

  componentDidUpdate(prevProps) {
    compareNewPropsBasedOnState(
      this.state,
      prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState, () => {
          if (
            this.state.currentScreener &&
            this.state.currentScreener.qManagerInControl &&
            this.state.currentScreener.qManagerInControl.show
          ) {
            const { given_name, family_name, fullName } =
              this.state.currentScreener.assignedCaller;
            let participantName =
              fullName ??
              (
                (given_name ? given_name + " " : " ") +
                (family_name ? family_name : "")
              ).trim();

            let qmTitle = `${
              this.state.currentScreener.qManagerInControl.show
            } ${this.state.currentScreener.name} (${
              participantName ? participantName : "No Caller"
            })`;
            if (document.title !== qmTitle) {
              document.title = qmTitle;
            }
          } else {
            if (document.title !== "AirFirst™ Manager") {
              document.title = "AirFirst™ Manager";
            }
          }
        });
      }
    );
  }

  renderMultipleEndpointAssignmentWarning(
    _assignedEndpointsArray,
    _currentEndpointName
  ) {
    let warningString = "This caller is ALSO currently assigned to:";
    for (var i = 0; i < _assignedEndpointsArray.length; i++) {
      if (_assignedEndpointsArray[i] !== _currentEndpointName) {
        warningString = warningString + " " + _assignedEndpointsArray[i];
      }
    }
    return (
      <p>
        <b>
          <FontAwesomeIcon
            className="text-warning"
            icon={["fas", "exclamation-triangle"]}
          />
          {warningString}&nbsp;
          <FontAwesomeIcon
            className="text-warning"
            icon={["fas", "exclamation-triangle"]}
          />
        </b>
      </p>
    );
  }

  shouldTextButtonBeDisabled(_callApp) {
    if (
      this.state.currentScreener &&
      this.state.currentScreener.assignedCaller &&
      this.state.currentScreener.assignedCaller.phone_number
    ) {
      return !(
        this.state.currentScreener.assignedCaller.phone_number !==
          "+11111111111" && this.state.currentScreener.assignedCaller[_callApp]
      );
    }
    return true;
  }

  componentWillUnmount() {
    if (document.title !== "AirFirst™ Manager") {
      document.title = "AirFirst™ Manager";
    }
  }

  render() {
    const {
      parentFuncs,
      clientConfiguration,
      currentScreener,
      roleChoices,
      screeners,
    } = this.props;
    const screenerName = currentScreener
      ? parseMultiboxName(currentScreener.name)
      : "";

    const { manualCaller } = this.state;

    if (currentScreener) {
      const {
        Name,
        allocatedCMP: cmp,
        assignedCaller,
        isMultiboxController,
      } = currentScreener;

      const endpointIsPrescreener =
        currentScreener?.toLowerCase?.().indexOf("prescreen") > -1
          ? true
          : false;

      const isPrescreenWarningNeeded =
        (cmp?.participantEavesDropper || cmp?.programEavesDropper) &&
        endpointIsPrescreener
          ? true
          : false;
      const programWarningNeeded =
        (cmp?.participantEavesDropper || cmp?.programEavesDropper) &&
        !endpointIsPrescreener
          ? true
          : false;

      const qmIsPrescreener = checkIfRoleChoicesAllows(
        roleChoices,
        "callScreener"
      );

      const qmIsFullScreener = checkIfRoleChoicesAllows(
        roleChoices,
        "callProducer"
      );

      const qmIsCrowdViewOperator = checkIfRoleChoicesAllows(
        roleChoices,
        "multiboxController"
      );

      const availableControlPanels = Object.values(screeners).filter(
        (controlPanel) => controlPanel.name !== currentScreener.name
      );

      const availableScreenersSorted = availableControlPanels.sort(
        (controlPanel1, controlPanel2) =>
          sortStringsLikeHuman(
            parseMultiboxName(controlPanel1.name),
            parseMultiboxName(controlPanel2.name)
          )
      );

      const availableEndpointsCount = availableControlPanels.length;
      const hasEndpointsAvailable = availableEndpointsCount > 0;

      return (
        <div className="layout-screener-content">
          {!currentScreener && (
            <div id="no-screener-available">
              <p>ENDPOINT SELECTION ERROR</p>
              <br />
              The Show/Episode/Endpoint combination you have selected may no
              longer be available.
            </div>
          )}
          {currentScreener && (
            <Fragment>
              {assignedCaller &&
                assignedCaller.assignedEndpoints &&
                assignedCaller.assignedEndpoints.length > 1 &&
                !currentScreener.name.includes("multibox_") && (
                  <div>
                    {this.renderMultipleEndpointAssignmentWarning(
                      assignedCaller.assignedEndpoints,
                      Name
                    )}
                  </div>
                )}

              <div className="centerAlignedItems">
                <Grid
                  container
                  spacing={2}
                  style={{
                    // class name doesn't work
                    paddingBottom: 5,
                  }}
                >
                  <Grid item>
                    <BackButton
                      eventKey={1}
                      title={"Back to Producer View"}
                      onClick={() => {
                        this.props.parentFuncs.assignedCaller.changePage(
                          "Producer"
                        );
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Badge
                      badgeContent={availableEndpointsCount}
                      color={hasEndpointsAvailable ? "primary" : "secondary"}
                      showZero={false}
                    >
                      <MenuButton
                        color="info"
                        disabled={!hasEndpointsAvailable}
                        items={availableControlPanels.map((screener) => {
                          const endpointInUse = Boolean(
                            screener.qManagerInControl
                          );

                          const isPrescreenEndpoint = screener.name
                            .toLowerCase()
                            .includes("prescreen");

                          const isCrowdviewEndpoint = screener.name
                            .toLowerCase()
                            .includes("multibox_");

                          const isCompatibleWithUser =
                            ((qmIsPrescreener || qmIsFullScreener) &&
                              isPrescreenEndpoint) ||
                            (qmIsCrowdViewOperator && isCrowdviewEndpoint) ||
                            (qmIsFullScreener &&
                              !isCrowdviewEndpoint &&
                              !isPrescreenEndpoint);

                          const shouldDisableEndpointOption =
                            !isCompatibleWithUser || endpointInUse;

                          const notEnabledText = shouldDisableEndpointOption
                            ? endpointInUse
                              ? ` - In use by ${screener.qManagerInControl?.username}`
                              : " - Incompatible User Role(s)"
                            : " - Available";

                          return {
                            children: `${parseMultiboxName(
                              screener.name
                            )}${notEnabledText}`,
                            disabled: shouldDisableEndpointOption,
                            key: screener.id,
                            style: shouldDisableEndpointOption
                              ? { color: "#C0C0C0" }
                              : {},
                            onClick: (e) => {
                              e.preventDefault();
                              parentFuncs.currentScreener.switchScreeners(
                                screener.name
                              );
                            },
                          };
                        })}
                        text="Switch Control Panel"
                        title="Switch Control Panel"
                      />
                    </Badge>
                  </Grid>

                  <Grid item>
                    <Chip
                      color={
                        screenerName.toLowerCase().includes("prescreen")
                          ? undefined
                          : "error"
                      }
                      label={
                        <Typography variant="h5">{screenerName}</Typography>
                      }
                      variant={
                        screenerName.toLowerCase().includes("prescreen")
                          ? "outlined"
                          : "filled"
                      }
                    />
                  </Grid>
                </Grid>

                <div className="layout-screener-title-recordingInfo">
                  <div
                    className="justify-content-start"
                    style={{
                      marginLeft: "10px",
                      marginTop: "8px",
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    {currentScreener.allocatedCMP &&
                      currentScreener.allocatedCMP.participantEavesDropper &&
                      currentScreener.allocatedCMP.participantEavesDropper
                        .status === "Recording" && (
                        <Fragment>
                          <Spinner animation="grow" variant="danger" />
                        </Fragment>
                      )}
                  </div>
                </div>
                <div>
                  {(programWarningNeeded || isPrescreenWarningNeeded) && (
                    <ButtonGroup>
                      {programWarningNeeded && (
                        <CustomTooltip
                          placement={"bottom"}
                          title={`Calls from this Endpoint **WILL** trigger ${branding.eavesdrop} participant and/or program recordings`}
                        >
                          <span>
                            <h2 className={styles.endpointWarningText}>
                              <FontAwesomeIcon
                                icon={["fas", "file-video"]}
                                style={{ color: "green" }}
                              />
                            </h2>
                          </span>
                        </CustomTooltip>
                      )}
                      {isPrescreenWarningNeeded && !programWarningNeeded && (
                        <CustomTooltip
                          placement={"bottom"}
                          title={`Calls from this Endpoint will **NOT** trigger ${branding.eavesdrop} participant and/or program recordings`}
                        >
                          <span>
                            <h2 className={styles.endpointWarningText}>
                              <FontAwesomeIcon icon={["fas", "file-video"]} />
                            </h2>
                          </span>
                        </CustomTooltip>
                      )}
                    </ButtonGroup>
                  )}
                </div>
              </div>
              <Paper style={{ padding: "8px" }}>
                <CMPInfoAccordion />
              </Paper>
              {!assignedCaller && (
                <Alert
                  style={{
                    width: "100%",
                    margin: "0 !important",
                  }}
                  variant="warning"
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="text-left"
                      style={{ width: "50%", display: "inline-block" }}
                    >
                      <h4 style={{ margin: 0 }}>
                        <b>No Participant Assigned</b>
                      </h4>
                    </div>
                    <div
                      className="text-right"
                      style={{ width: "50%", display: "inline-block" }}
                    >
                      <QuickParticipantButton />
                    </div>
                  </div>
                </Alert>
              )}
              {!isMultiboxController &&
                assignedCaller &&
                Object.keys(assignedCaller).length > 0 && (
                  <Grid container spacing={1}>
                    <Grid container item xs={12}>
                      {clientConfiguration?.enableNetworkDiagnostics &&
                        assignedCaller.type === participantTypeEnums.VIP && (
                          <NetworkDiagnostics
                            connected={assignedCaller.connected}
                            disableTestButton={
                              Boolean(currentScreener.inCall) ||
                              !assignedCaller.connected
                            }
                            networkDiagnosticState={
                              assignedCaller.networkDiagnosticState
                            }
                            participantID={assignedCaller.id}
                            participantType={assignedCaller.type}
                          />
                        )}

                      <Grid className="fullWidth" item>
                        <ParticipantSummary
                          currentScreener={currentScreener}
                          manualCaller={manualCaller}
                          parentFuncs={parentFuncs}
                          participant={assignedCaller}
                          screeners={screeners}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              {isMultiboxController && (
                <MultiboxControls
                  callButtonFuncs={parentFuncs.callButtons}
                  canControlMultibox={this.props.canControlMultibox}
                  currentScreener={currentScreener}
                />
              )}
            </Fragment>
          )}
        </div>
      );
    } else {
      return <Fragment />;
    }
  }
}

const mapStateToProps = (state) => {
  const {
    callers,
    clientConfiguration,
    currentScreener,
    roleChoices,
    screeners,
    serverTimeOffset,
  } = state.queue;

  const currentScreenerActual = screeners[currentScreener];

  let manualCaller =
    currentScreener &&
    screeners &&
    screeners[currentScreener].assignedCaller &&
    screeners[currentScreener].assignedCaller.type === "manual"
      ? true
      : false;

  return {
    callers,
    clientConfiguration,
    currentScreener: currentScreenerActual,
    manualCaller,
    roleChoices,
    screeners,
    serverTimeOffset,
  };
};

export default connect(mapStateToProps)(Screener);
