import type { FunctionComponent, MouseEventHandler } from "react";
import type { Theme, MenuProps, MenuItemProps } from "@mui/material";
import type { TooltipButtonProps } from "./TooltipButton";

import { Fragment, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TooltipButton from "./TooltipButton";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";

const useStyles = makeStyles<Theme, { color: string }>((theme) => ({
  endIcon: ({ color }) => {
    const contrastColor = theme.palette[color]?.contrastText;

    return {
      color: contrastColor,
      display: "inline-flex",
      opacity: 0.8,
      "& span": {
        WebkitAlignSelf: "stretch",
        alignSelf: "stretch",
        backgroundColor: contrastColor,
        margin: "1px 4px 1px 4px",
        width: 0.76,
        boxSizing: "border-box",
      },
    };
  },
  endIconDivider: ({ color }) => ({}),
  menuItem: {
    "& .MuiListItem-root.Mui-disabled": {
      opacity: 0.4,
      color: "black !important",
    },
  },
}));

export type MenuButtonItem = Omit<MenuItemProps<"li">, "button">;

export interface MenuButtonProps extends Omit<TooltipButtonProps, "onClick"> {
  items?: MenuButtonItem[];
  MenuProps?: Omit<MenuProps, "anchorEl" | "children" | "open" | "onClose">;
}

const MenuButton: FunctionComponent<MenuButtonProps> = (props) => {
  const { children, color, items, MenuProps = {}, ...rest } = props;

  const classes = useStyles({ color });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (children || items?.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <TooltipButton
        {...rest}
        color={color}
        endIcon={
          <div className={classes.endIcon}>
            <span />
            <KeyboardArrowDownIcon />
          </div>
        }
        onClick={handleClick}
      />
      {(items || children) && (
        <Menu
          {...MenuProps}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {items?.map((item, index) => (
            <MenuItem
              {...item}
              className={classes.menuItem}
              key={index}
              onClick={(event) => {
                item.onClick?.(event);
                handleClose();
              }}
            />
          )) ?? children}
        </Menu>
      )}
    </Fragment>
  );
};

export default MenuButton;
