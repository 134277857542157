import type { FunctionComponent, MouseEventHandler } from "react";

import { Fragment } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TableCell,
} from "@mui/material";
import { useDateFormatter, useSelector } from "../../hooks";
import TooltipButton from "../../components/TooltipButton";
import Table from "../../components/Table";
import TableRow from "../../components/TableRow";

type Props = {
  duplicateParticipantGuids: string[];
  handleCancelClick: MouseEventHandler<HTMLButtonElement>;
  handleProceedClick: MouseEventHandler<HTMLButtonElement>;
  handleUseExistingClick?: (duplicateParticipant: any) => void;
};

const DuplicateParticipantsWarning: FunctionComponent<Props> = (props) => {
  const {
    duplicateParticipantGuids,
    handleCancelClick,
    handleProceedClick,
    handleUseExistingClick,
  } = props;

  const formatDate = useDateFormatter();

  const participants = useSelector((state) => state.queue.participants);

  const duplicateParticipants = participants.filter(({ guid }) =>
    duplicateParticipantGuids.includes(guid)
  );

  return (
    <Fragment>
      <DialogContent>
        <Table
          columns={[
            { id: "fullName", label: "Name " },
            { id: "contactsStr", label: "Contact Info" },
            {
              id: "createdAt",
              label: "Created At",
            },
          ]}
          data={duplicateParticipants}
          initialOrderByProperty="fullName"
          rowRenderer={(props) => (
            <TableRow {...props}>
              <TableCell>{props.rowItem.fullName}</TableCell>
              <TableCell>{props.rowItem.contactsStr}</TableCell>
              <TableCell>{formatDate(props.rowItem.createdAt)}</TableCell>
              {handleUseExistingClick && (
                <TableCell>
                  <TooltipButton
                    color="primary"
                    text={"Use Existing"}
                    title={
                      "Discard the new participant and use this existing one instead"
                    }
                    variant="contained"
                    onClick={() => handleUseExistingClick(props.rowItem)}
                  />
                </TableCell>
              )}
            </TableRow>
          )}
          uniqueIdentifierProperty="guid"
        />
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <TooltipButton
              color="error"
              text={"Proceed"}
              title={"Ignore the existing participant and continue"}
              variant="contained"
              onClick={handleProceedClick}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Fragment>
  );
};

export default DuplicateParticipantsWarning;
