export const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const branding = {
  airfirst: "AirFirst™",
  callerqueue: "Caller Queue®",
  cmp: "Call Manager Pro™",
  crowdview: "CrowdView™",
  eavesdrop: "EavesDrop™ beta",
  bop: "BOP®",
  gruveo: "Gruveo",
  stagedoor: "StageDoor™",
  tenaciti: "Tenaciti™ beta",
  twilio: "VCC WebRTC",
  zoom: "NexToZoom",
};

export const localStorageKey = {
  currentTheme: "cqDisplayTheme",
  militaryTime: "cqDisplayMilitaryTime",
  timeZone: "cqDisplayedTimeZone",
  suppressZoomWarning: "cqSuppressZoomWarning",
};

export const formPlaceholders = {
  phoneNumber: "2122357019",
};

export const webRTCApps = ["Twilio", "Gruveo"];

export const appList = ["Facetime", ...webRTCApps];

// For converting into display friendly name
export const participantTypeEnums = {
  MANUAL: "manual",
  QUEUE: "queue",
  VIP: "vip",
};

export const queueStateEnums = {
  PARTICIPANT_IS_ASSIGNED: "qAssigned",
  PARTICIPANT_IS_AVAILABLE: "qReady",
  PARTICIPANT_IS_IN_CALL: "qCalling",
  PARTICIPANT_IS_NOT_AVAILABLE: "qNot",
  PARTICIPANT_WAS_ASSIGNED: "qUnassigned",
  PARTICIPANT_WAS_IN_CALL: "qCalled",
};

export const chatAppDict = {
  gruveo: branding.gruveo,
  "web-rtc": branding.twilio,
  twilio: branding.twilio,
};

export const recordingDestinations = {
  sd: "SD Card",
  usb: "USB",
  smb: "SMB Network Share",
  nfs: "NFS Network Share",
};

export const scheduledCallDateFormat = "yyyy-MM-dd";

export const scheduledCallDateAndTimeFormat = {
  military: "yyyy-MM-dd HH:mm zzz",
  standard: "yyyy-MM-dd hh:mm a zzz",
};

export const scheduledCallTimeFormat = {
  military: "HH:mm",
  standard: "hh:mm a",
};

export const deviceOsNames = {
  android: "Android",
  ios: "iOS",
  linux: "Linux",
  macos: "macOS",
  windows: "Windows",
};

export const deviceTypes = {
  mobile: "Mobile Device",
  desktop: "Desktop/Laptop",
  tablet: "Tablet",
};

export type ParticipantStatus =
  | "calling"
  | "callingDisconnected"
  | "connectedAssigned"
  | "connectedUnassigned"
  | "disconnectedAssigned"
  | "disconnectedUnassigned";

export const participantStatusMuiColors: Record<ParticipantStatus, any> = {
  calling: "error",
  callingDisconnected: "error",
  connectedAssigned: "success",
  connectedUnassigned: "warning",
  disconnectedAssigned: "info",
  disconnectedUnassigned: undefined,
};

export const participantStatusText: Record<ParticipantStatus, string> = {
  calling: "In Call",
  callingDisconnected: "In Call",
  connectedAssigned: "Online/Assigned",
  connectedUnassigned: "Online/Not Assigned",
  disconnectedAssigned: "Not Online/Assigned",
  disconnectedUnassigned: "Not Online",
};

export enum ParticipantSessionEventLogType {
  CallEnded = "call_ended",
  CallStarted = "call_started",
  InvitationSent = "invitation_sent",
  SocketConnected = "socket_connected",
  SocketDisconnected = "socket_disconnected",
  Other = "other",
  Assigned = "assigned",
  Unassigned = "unassigned",
}

export const friendlyEventLogTypeNames: Record<
  ParticipantSessionEventLogType,
  string
> = {
  call_ended: "Call Ended",
  call_started: "Call Started",
  invitation_sent: "Invitation Sent",
  socket_connected: `Connected to ${branding.stagedoor}`,
  socket_disconnected: `Disconnected from ${branding.stagedoor}`,
  other: "Other/Misc.",
  assigned: "Assigned to Control Panel",
  unassigned: "Unassigned from Control Panel",
};
