import type { ProviderContext, SnackbarKey } from "notistack";
import { useSnackbar as useNotistackSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close } from "@mui/icons-material";

type EnqueueSnackbar = ProviderContext["enqueueSnackbar"];
type EnqueueSnackbarParams = Parameters<EnqueueSnackbar>;

const useStyles = makeStyles({
  closeButton: {
    color: "#fff",
  },
});

export const useSnackbar = () => {
  const snackbar = useNotistackSnackbar();

  const classes = useStyles();

  const enqueueSnackbar = (...args: EnqueueSnackbarParams) => {
    const [message, options] = args;

    const modifiedOptions = {
      ...(options ?? {}),
      preventDuplicate: true,
      action: (key: SnackbarKey) => (
        <IconButton
          className={classes.closeButton}
          size="small"
          onClick={() => snackbar.closeSnackbar(key)}
        >
          <Close />
        </IconButton>
      ),
    };

    return snackbar.enqueueSnackbar(message, modifiedOptions);
  };

  const closeSnackbar = (key: SnackbarKey) => {
    snackbar.closeSnackbar(key);
  };

  return {
    ...snackbar,
    closeSnackbar,
    enqueueSnackbar: enqueueSnackbar,
  };
};
