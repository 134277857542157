import React, { Fragment, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import TooltipButton from "../../../components/TooltipButton";
import CQ from "../../../utilities/socket/CQ";

/*

In the event that "Fix Zoom" functionality is determined to be required again for FaceTime, just reimplement the below

<MenuItem
            key="fix-call-zoom"
            onClick={() => sendFixCommand("zoom")}
            onMouseDown={onMouseDown}
          >
            Fix Zoom
          </MenuItem>,

*/

type Props = {
  appName: string;
  className?: string;
  endpointID: string;
  isCallFullscreen: boolean;
  isDisabled: boolean;
  style?: React.CSSProperties;
  tooltipTitle: string;
  version?: number;
};

enum FixAction {
  BLUR = "blur",
  CURSOR = "cursor",
  DISPLAY = "display",
  FULLSCREEN = "fullscreen",
  MAXIMIZE = "maximize",
  ORIENTATION = "orientation",
  PREVIEW = "preview",
  REFRESH = "refresh",
  ZOOM = "zoom",
}

const FixCMPCallMenu: React.FC<Props> = (props) => {
  const {
    appName,
    className,
    endpointID,
    isCallFullscreen,
    isDisabled,
    tooltipTitle,
    version,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const sendFixCommand = (action: FixAction): void => {
    CQ.emit("fixCallManagerPro", {
      action,
      app: appName,
    });
    setAnchorEl(null);
  };

  const onMouseDown = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ): void => {
    event.preventDefault();
  };

  const isCmpV2 = typeof version !== "undefined" && version >= 2;

  return (
    <Fragment>
      <TooltipButton
        className={className}
        color={isDisabled ? undefined : "warning"}
        icon={["fas", "wrench"]}
        isDisabled={isDisabled}
        text="Fix"
        title={tooltipTitle}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{
          paper: "paper-border",
        }}
        elevation={0}
        keepMounted
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => sendFixCommand(FixAction.CURSOR)}
          onMouseDown={onMouseDown}
        >
          {appName === "Twilio" || appName === "Zoom"
            ? "Move Cursor"
            : "Hide Buttons"}
        </MenuItem>
        {appName !== "Zoom" && (
          <MenuItem
            onClick={() => sendFixCommand(FixAction.MAXIMIZE)}
            onMouseDown={onMouseDown}
          >
            Fix Framing
          </MenuItem>
        )}
        {isCmpV2 && appName === "Gruveo" && (
          <MenuItem
            onClick={() => {
              sendFixCommand(FixAction.PREVIEW);
            }}
            onMouseDown={onMouseDown}
          >
            Toggle Preview
          </MenuItem>
        )}
        {appName === "Multibox" && (
          <MenuItem
            key="fix-call-fullscreen"
            onClick={() => sendFixCommand(FixAction.FULLSCREEN)}
            onMouseDown={onMouseDown}
          >
            {`${isCallFullscreen ? "Un-" : ""}Toggle Fullscreen`}
          </MenuItem>
        )}
        {(appName === "Twilio" || appName === "Multibox") && (
          <MenuItem
            key="fix-call-refresh"
            onClick={() => sendFixCommand(FixAction.REFRESH)}
            onMouseDown={onMouseDown}
          >
            Refresh
          </MenuItem>
        )}
        {appName === "Facetime" && (
          <MenuItem
            key="fix-call-orientation"
            onClick={() => sendFixCommand(FixAction.ORIENTATION)}
            onMouseDown={onMouseDown}
          >
            Return Orientation
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};

export default FixCMPCallMenu;
