import type {
  OnAuthRequiredFunction,
  RestoreOriginalUriFunction,
} from "@okta/okta-react/bundles/types/OktaContext";
import type { FunctionComponent } from "react";
import type { Theme } from "@mui/material/styles";
import { Route, useHistory } from "react-router-dom";
import {
  LoginCallback,
  Security,
  SecureRoute as AuthenticatedRoute,
} from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { oktaClient } from "./utilities/okta";
import AuthHandler from "./components/AuthHandler";
import Layout from "./components/Layout";
import AuthorizedRoute from "./components/AuthorizedRoute";
import AdminPage from "./pages/admin";
import AdminUsersPage from "./pages/admin/users";
import MainPage from "./pages";
import LoginPage from "./pages/login";
import { useTheme } from "./hooks/theme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type Props = {};

const App: FunctionComponent<Props> = () => {
  const history = useHistory();
  const { theme } = useTheme();

  const onAuthRequired: OnAuthRequiredFunction = () => {
    history.push("/login");
  };

  const restoreOriginalUri: RestoreOriginalUriFunction = async (
    _oktaAuth,
    originalUri
  ) => {
    const url = originalUri ?? "/";
    history.replace(toRelativeUrl(url, window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaClient}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Layout>
            <AuthHandler>
              <Route component={AdminPage} exact path="/admin" />
              <AuthorizedRoute
                component={AdminUsersPage}
                exact
                path="/admin/users"
                requiredRoles={["Role_HelpDesk"]}
              />

              <Route component={LoginPage} path="/login" />
              <AuthenticatedRoute component={MainPage} exact path="/" />
              <Route component={LoginCallback} path="/implicit/callback" />
            </AuthHandler>
          </Layout>
        </ThemeProvider>
      </StyledEngineProvider>
    </Security>
  );
};

export default App;
