import type { FunctionComponent } from "react";

import { DialogContent as MuiDialogContent } from "@mui/material";
import { useSelector } from "../../../../hooks/redux";
import { useHttpRequest } from "../../../../hooks/rest";
import DraggableDialog from "../../../../components/DraggableDialog";
import DialogContent from "./DialogContent";

type Props = {
  contacts: any[];
  deviceInfo: any;
  guid: string;
  isDisabled: boolean;
};

const ChangeFacetimeContact: FunctionComponent<Props> = (props) => {
  const { contacts, deviceInfo, guid, isDisabled } = props;

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );

  const [createContactRequest, createContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}/contacts`,
    {
      method: "post",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const [deleteContactRequest, deleteContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}/contacts/`,
    {
      method: "delete",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const [updateContactRequest, updateContactRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}/contacts/`,
    {
      method: "put",
      body: {
        accountGuid,
        productionGuid,
      },
    }
  );

  const isLoading =
    createContactRequestState.loading ||
    deleteContactRequestState.loading ||
    updateContactRequestState.loading;

  const facetimeContact = contacts?.find((contact: any) => {
    return contact.type === "facetime" && contact.value !== "";
  });
  const hasFacetimeContact = Boolean(facetimeContact);

  return (
    <DraggableDialog
      id="select-facetime-dialog"
      OpenButtonProps={{
        className: "fullWidth",
        disabled: isDisabled || isLoading,
        color: "primary",
        placement: "top",
        text: "Edit",
        title: `${
          hasFacetimeContact ? "Edit" : "Create"
        } or Choose Apple/FaceTime ID`,
      }}
      title={"Edit or Choose Apple/FaceTime ID"}
    >
      <MuiDialogContent>
        <DialogContent
          contacts={contacts}
          createContactRequest={createContactRequest}
          deleteContactRequest={deleteContactRequest}
          deviceInfo={deviceInfo}
          facetimeContact={facetimeContact}
          hasFacetimeContact={hasFacetimeContact}
          isDisabled={isDisabled}
          isLoading={isLoading}
          updateContactRequest={updateContactRequest}
        />
      </MuiDialogContent>
    </DraggableDialog>
  );
};

export default ChangeFacetimeContact;
