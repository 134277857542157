import { useCallback } from "react";
import { useSelector } from "./redux";
import {
  scheduledCallDateAndTimeFormat,
  scheduledCallTimeFormat,
} from "../utilities/definitions";
import { dateToFormattedString } from "../utilities/utilityFunctions";

export const useDateFormatter = () => {
  const displayMilitary = useSelector(
    (state) => state.userPrefs.displayMilitary
  );
  const timeZone = useSelector((state) => state.userPrefs.timeZone);

  return useCallback(
    (date: Date | number | string, options?: { timeOnly?: boolean }) => {
      const timeOnly = options?.timeOnly ?? false;

      let format = displayMilitary
        ? scheduledCallDateAndTimeFormat.military
        : scheduledCallDateAndTimeFormat.standard;

      if (timeOnly) {
        format = displayMilitary
          ? scheduledCallTimeFormat.military
          : scheduledCallTimeFormat.standard;
      }

      return dateToFormattedString(date, format, timeZone);
    },
    [displayMilitary, timeZone]
  );
};
