export default function selectDefaultRole(_roleChoices) {
  let defaultRole = _roleChoices[0];

  for (var i = 0; i < _roleChoices.length; i++) {
    let tempName = _roleChoices[i].name;

    if (tempName === "callProducer" && _roleChoices[i].value === true) {
      defaultRole = _roleChoices[i];
      break;
    } else if (tempName === "callScreener" && _roleChoices[i].value === true) {
      defaultRole = _roleChoices[i];
      break;
    } else if (tempName === "callScheduler" && _roleChoices[i].value === true) {
      defaultRole = _roleChoices[i];
      break;
    } else if (tempName === "callBooker" && _roleChoices[i].value === true) {
      defaultRole = _roleChoices[i];
      break;
    } else if (tempName === "queueTech" && _roleChoices[i].value === true) {
      defaultRole = _roleChoices[i];
      break;
    } else if (tempName === "queueAdmin" && _roleChoices[i].value === true) {
      defaultRole = _roleChoices[i];
      break;
    } else if (tempName === "stageDoor" && _roleChoices[i].value === true) {
      defaultRole = _roleChoices[i];
      break;
    } else {
      /* Do nothing */
    }
  }

  return defaultRole;
}
