export const noLocation = "Location Not Available";

export const noTopic = "No Topic Provided";

export const notProvided = "Not Provided";

export const titles = {
  location: "Location",
  prompt: "Prompt Response",
  topic: "Topic",
};
