import type { FunctionComponent } from "react";
import { grey } from "@mui/material/colors";
import {
  Alert,
  Backdrop,
  Box,
  CssBaseline,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuBar from "../modules/MenuBar";
import { useSelector, useSocket } from "../hooks";
import { useLocation } from "react-router-dom";
import { PACKAGE_VERSION } from "../config";

const useStyles = makeStyles((theme) => ({
  disconnectedBackdrop: {
    color: grey[100],
    position: "absolute",
    textAlign: "center",
    zIndex: 99999,
  },
  footer: {
    alignItems: "center",
    backgroundColor: "rgb(18, 18, 18)",
    color: theme.palette.info.light,
    display: "flex",
    flexShrink: 0,
    height: "5vh",
    justifyContent: "space-between",
    padding: "5px",
    textAlign: "center",
    width: "100%",
    zIndex: 999,
  },
  main: {
    backgroundColor: theme.palette.mode === "light" ? grey[100] : grey[900],
    flex: "1 0 auto",
    paddingRight: 5,
    paddingTop: 5,
    position: "relative",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
}));

const Layout: FunctionComponent = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const socket = useSocket();

  const disconnected = useSelector((state) => state.queue.disconnected);

  return (
    <Box className={classes.root}>
      <CssBaseline />

      <MenuBar />

      <Box className={classes.main} component="main">
        {!location.pathname.startsWith("/login") && (
          <Backdrop
            className={classes.disconnectedBackdrop}
            open={disconnected && socket.initialized}
          >
            <Alert
              color="error"
              icon={false}
              sx={{
                width: "100%",
                "& .MuiAlert-message": {
                  width: "100%",
                },
              }}
            >
              <Box justifyContent="center">
                <Typography variant="h4">Connecting to Server ...</Typography>

                <Typography variant="subtitle1">
                  If this message persists after a few moments, please{" "}
                  <Link
                    href="mailto:vcc-support@nextologies.com?subject=Connecting to Server message not going away"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    contact VCC Support
                  </Link>{" "}
                  (vcc-support@nextologies.com)
                </Typography>
              </Box>
            </Alert>
          </Backdrop>
        )}

        {props.children}
      </Box>

      <Box className={classes.footer} component="footer">
        <Link
          href="https://www.nextologies.com/vcc-services.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="AirFirst logo"
            src="/img/vcc_nextologies.png"
            style={{
              height: "4vh",
              padding: "1px",
              width: "auto",
            }}
          />
        </Link>
        <Typography variant="caption">{`Manager Version: ${PACKAGE_VERSION}`}</Typography>
      </Box>
    </Box>
  );
};

export default Layout;
