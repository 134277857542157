import type { FunctionComponent } from "react";

import { Box } from "@mui/material";

type Props = {
  hide?: boolean;
};

const Hidden: FunctionComponent<Props> = (props) => {
  const { children, hide = true } = props;

  return <Box sx={{ display: hide ? "none" : undefined }}>{children}</Box>;
};

export default Hidden;
