const env = import.meta.env;

export const PACKAGE_NAME = env.REACT_APP_PACKAGE_NAME as string;

export const PACKAGE_VERSION = env.REACT_APP_PACKAGE_VERSION as string;

export const NODE_ENV = env.PROD ? "production" : "development";

export const SERVER_ROOT = (env.REACT_APP_QUEUE_SERVER as string) || "";

export const ADMIN_SERVER_ROOT = (env.REACT_APP_ADMIN_SERVER as string) ?? "";

export const DEBUG_MODE = (env.REACT_APP_DEBUG_MODE as boolean) || false;

export const OKTA_CLIENT_ID = (env.REACT_APP_OKTA_CLIENT_ID as string) || "";

export const OKTA_ISSUER = (env.REACT_APP_OKTA_ISSUER as string) || "";

export const IMAGES_CDN = (env.REACT_APP_IMAGES_CDN as string) || "";

export const ENVIRONMENT_NAME = env.REACT_APP_ENVIRONMENT_NAME ?? "local-dev";

export const ENABLE_ICAL = env.REACT_APP_ENABLE_ICAL === "true";

export const SENTRY_DSN = (env.REACT_APP_SENTRY_DSN as string) ?? "";
