import type { FunctionComponent } from "react";
import { useEffect, useRef, useState } from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

const CustomTooltip: FunctionComponent<TooltipProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const timerRef = useRef(null);
  const startTimeout = (e: any) => {
    e?.preventDefault();
    setIsOpen(true);
    timerRef.current = window.setTimeout(() => {
      setIsOpen(false);
    }, 4500);
  };

  useEffect(() => {
    return () => {
      setIsOpen(false);
      window.clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Tooltip
      disableInteractive={true}
      enterDelay={750}
      enterNextDelay={1125}
      open={isOpen}
      onClose={(e: any) => {
        e?.preventDefault();
        window.clearTimeout(timerRef.current);
        setIsOpen(false);
      }}
      onOpen={startTimeout}
      {...props}
      style={{ pointerEvents: "all" }}
    />
  );
};

export default CustomTooltip;
