import type { FunctionComponent, MouseEventHandler } from "react";
import type { HeadCell } from "../../components/Table";
import type { BaseRowProps } from "../../components/TableRow";

import { useCallback, useEffect, useState } from "react";
import { Divider, Grid, TableCell, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import {
  useDateFormatter,
  useHttpRequest,
  useSelector,
  useSnackbar,
} from "../../hooks";
import LimitedBackdrop from "../../components/LimitedBackdrop";
import Table from "../../components/Table";
import TableRow from "../../components/TableRow";
import TooltipButton from "../../components/TooltipButton";

const columns: HeadCell[] = [
  { id: "fullName", label: "Name" },
  { id: "email", label: "Email" },
  { id: "status", label: "Status" },
];

type OktaAccountStatus =
  | "ACTIVE"
  | "DEPROVISIONED"
  | "LOCKED_OUT"
  | "PASSWORD_EXPIRED"
  | "PROVISIONED"
  | "RECOVERY"
  | "STAGED"
  | "SUSPENDED";

export default function UsersPage() {
  const [users, setUsers] = useState<any[]>([]);

  const networkName = useSelector((state) => state.queue.network);

  /**
   * @todo Make less stupid
   */
  const groupName = `Network_${networkName}`;

  const [fetchUsers] = useHttpRequest("users", {
    admin: true,
    method: "get",
    searchParams: {
      groupName,
    },
  });

  useEffect(() => {
    (async () => {
      const users = await fetchUsers();

      if (users) {
        setUsers(users);
      }
    })();
  }, [fetchUsers]);

  return (
    <div>
      <Typography variant="h4">User Management</Typography>

      <Divider />
      <br />

      <Table
        columns={columns}
        data={users}
        initialOrderByProperty="fullName"
        rowRenderer={(props) => <UserRow {...props} />}
        searchable
      />
    </div>
  );
}

const UserRow: FunctionComponent<BaseRowProps> = (props) => {
  const { rowItem } = props;

  return (
    <TableRow {...props} expandedRow={<ExpandedUserRow userId={rowItem.id} />}>
      <TableCell>{rowItem.fullName}</TableCell>
      <TableCell>{rowItem.email}</TableCell>
      <TableCell>{rowItem.status}</TableCell>
    </TableRow>
  );
};

const ExpandedUserRow: FunctionComponent<{ userId: string }> = (props) => {
  const { userId } = props;

  const [user, setUser] = useState<any>();

  const { enqueueSnackbar } = useSnackbar();
  const formatDate = useDateFormatter();

  const [fetchUser, fetchUserState] = useHttpRequest(`users/${userId}`, {
    admin: true,
    method: "get",
  });

  const [resetPassword, resetPasswordState] = useHttpRequest(
    `users/${userId}/credentials/reset_password`,
    {
      admin: true,
      method: "get",
      onError: () => {
        enqueueSnackbar("Failed to reset user password.", { variant: "error" });
      },
      onSuccess: () => {
        enqueueSnackbar("User password reset.", {
          variant: "success",
        });

        loadUser();
      },
    }
  );

  const [suspend, suspendState] = useHttpRequest(
    `users/${userId}/lifecycle/suspend`,
    {
      admin: true,
      method: "get",
      onError: () => {
        enqueueSnackbar("Failed to suspend user.", { variant: "error" });
      },
      onSuccess: () => {
        enqueueSnackbar("User suspended.", {
          variant: "success",
        });

        loadUser();
      },
    }
  );

  const [unsuspend, unsuspendState] = useHttpRequest(
    `users/${userId}/lifecycle/unsuspend`,
    {
      admin: true,
      method: "get",
      onError: () => {
        enqueueSnackbar("Failed to unsuspend user.", { variant: "error" });
      },
      onSuccess: () => {
        enqueueSnackbar("User unsuspended.", {
          variant: "success",
        });

        loadUser();
      },
    }
  );

  const loadUser = useCallback(async () => {
    const user = await fetchUser();

    if (user) {
      setUser(user);
    }
  }, [fetchUser]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const handleResetPasswordClick: MouseEventHandler<HTMLButtonElement> = async (
    _event
  ) => {
    await resetPassword();
  };

  const handleSuspendClick: MouseEventHandler<HTMLButtonElement> = async (
    _event
  ) => {
    await suspend();
  };

  const handleUnsuspendClick: MouseEventHandler<HTMLButtonElement> = async (
    _event
  ) => {
    await unsuspend();
  };

  const loading =
    fetchUserState.loading ||
    resetPasswordState.loading ||
    suspendState.loading ||
    unsuspendState.loading;

  const loginAt = user?.loginAt ? formatDate(parseISO(user.loginAt)) : "";
  const updatedAt = user?.updatedAt ? formatDate(parseISO(user.updatedAt)) : "";

  const status: OktaAccountStatus = user?.status;

  return (
    <div>
      <LimitedBackdrop open={loading} />

      <Grid container spacing={1}>
        <Grid container direction="column" item sm={3}>
          <Grid item>
            <b>First name:</b>&nbsp;
            {user?.givenName}
          </Grid>

          <Grid item>
            <b>Last name:</b>&nbsp;
            {user?.familyName}
          </Grid>

          <Grid item>
            <b>Email:</b>&nbsp;
            {user?.email}
          </Grid>
        </Grid>

        <Grid container direction="column" item sm={3}>
          <Grid item>
            <b>Status:</b>&nbsp;
            {user?.status}
          </Grid>

          <Grid item>
            <b>Last login:</b>&nbsp;
            {loginAt}
          </Grid>

          <Grid item>
            <b>Last updated at:</b>&nbsp;
            {updatedAt}
          </Grid>
        </Grid>

        <Grid container item spacing={1} xs={12}>
          <Grid item>
            <TooltipButton
              disabled={status === "SUSPENDED"}
              size="small"
              text="Reset Password"
              title="Reset user password and send email"
              onClick={handleResetPasswordClick}
            />
          </Grid>

          {status === "SUSPENDED" ? (
            <Grid item>
              <TooltipButton
                size="small"
                text="Unsuspend"
                title="User will have access returned"
                onClick={handleUnsuspendClick}
              />
            </Grid>
          ) : (
            <Grid item>
              <TooltipButton
                disabled={status !== "ACTIVE"}
                size="small"
                text="Suspend"
                title="User will have access revoked"
                onClick={handleSuspendClick}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
