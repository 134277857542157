import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "../../hooks/redux";
import TimezoneSelect from "react-timezone-select";
import { Input, Menu, MenuItem, MenuList } from "@mui/material";
import { localStorageKey } from "../../utilities/definitions";
import { setTimeZone } from "../../store/userPrefs/actions";
import { useTheme } from "@mui/material";

const CustomInput = ({ innerProps }) => {
  return <Input {...innerProps} />;
};

const CustomMenu = ({ innerProps }) => {
  return <Menu {...innerProps} />;
};

const CustomMenuList = ({ innerProps }) => {
  return <MenuList {...innerProps} />;
};

const CustomOption = ({ innerProps }) => {
  return <MenuItem {...innerProps} />;
};

const SelectTimeZone: FunctionComponent = () => {
  const timeZone = useSelector((state: any) => state.userPrefs?.timeZone);

  const dispatch = useDispatch();
  const theme = useTheme();

  function handleSelect(timezone: any) {
    const { value } = timezone;

    localStorage.setItem(localStorageKey.timeZone, value);
    dispatch(setTimeZone(value));
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 250,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? theme.palette.info.dark
        : theme.palette.info.main,
      border: "none",
      fontSize: "smaller",
      ":hover": {
        ...provided[":hover"],
        backgroundColor: theme.palette.info.dark,
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #C0C0C0",
      color: "black",
      fontWeight: state.isSelected ? "bold" : "normal",
      padding: 5,
      width: 250,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.palette.info.contrastText,
    }),
  };

  return (
    <TimezoneSelect
      component={{
        Input: CustomInput,
        Menu: CustomMenu,
        MenuList: CustomMenuList,
        Option: CustomOption,
      }}
      labelStyle="abbrev"
      styles={customStyles}
      value={timeZone}
      onChange={handleSelect}
    />
  );
};

export default SelectTimeZone;
