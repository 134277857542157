import type { FunctionComponent } from "react";

import mustache from "mustache";
import { localStorageKey } from "../../utilities/definitions";
import MenuButton from "../../components/MenuButton";
import { setDisplayMilitary } from "../../store/userPrefs/actions";
import { useDispatch, useSelector } from "../../hooks/redux";
import { options } from "./templates";

const { clockMode } = options;

const SelectClockMode: FunctionComponent = () => {
  const displayMilitary: boolean = useSelector(
    (state: any) => state.userPrefs.displayMilitary
  );

  const dispatch = useDispatch();

  function handleSelect(clockMode: boolean): void {
    localStorage.setItem(localStorageKey.militaryTime, String(clockMode));

    dispatch(setDisplayMilitary(clockMode));
  }

  const clockmodeTooltip = mustache.render(clockMode.tooltip, {
    selectedClockMode: displayMilitary
      ? clockMode.military
      : clockMode.standard,
  });

  return (
    <MenuButton
      color="info"
      id="clock-selection"
      items={[
        {
          children: `${clockMode.standard}${displayMilitary ? "" : " ✓"}`,
          onClick: () => handleSelect(false),
        },
        {
          children: `${clockMode.military}${displayMilitary ? " ✓" : ""}`,
          onClick: () => handleSelect(true),
        },
      ]}
      placement={"bottom"}
      text={`${displayMilitary ? clockMode.military : clockMode.standard}`}
      title={clockmodeTooltip}
    />
  );
};

export default SelectClockMode;
