import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type IconPurpose =
  | "muted"
  | "notMuted"
  | "participantAssigned"
  | "participantCalling"
  | "participantNone"
  | "participantPopped";

export const icons: Record<IconPurpose, IconProp> = {
  muted: ["fas", "volume-mute"],
  notMuted: ["fas", "microphone-alt"],
  participantAssigned: ["fas", "user"],
  participantCalling: ["fas", "phone-slash"],
  participantNone: ["fas", "user-slash"],
  participantPopped: ["fab", "buromobelexperte"],
};
