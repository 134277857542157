import { useCallback, useEffect, useState } from "react";
import { useHttpRequest } from "./rest";
import { useSelector } from "./redux";

export const useParticipantContacts = (participantGuid: string) => {
  const [contacts, setContacts] = useState<any[]>([]);

  const [fetchContactsRequest, fetchContactsRequestState] = useHttpRequest(
    `participants/${participantGuid}/contacts`,
    {
      admin: true,
      method: "get",
    }
  );

  const fetchContacts = useCallback(async () => {
    const updatedContacts = await fetchContactsRequest();

    if (updatedContacts) {
      setContacts(updatedContacts);
    }
  }, [fetchContactsRequest]);

  // Fetch contacts on mount
  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  return {
    contacts,
    fetchContacts,
    loadingContacts: fetchContactsRequestState.loading,
  };
};

export const useParticipantSessions = (
  participantGuid: string,
  connected: boolean,
  networkDiagnosticState: {
    error: any;
    running: boolean;
  }
) => {
  const { running } = networkDiagnosticState ?? {
    error: null,
    running: false,
  };

  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const [sessions, setSessions] = useState<any[]>([]);
  const [latestSessionDevice, setLatestSessionDevice] = useState<any>(null);
  const [networkDiagnostics, setNetworkDiagnostics] = useState<any[]>([]);

  const { productionGuid } = useSelector((state) => state.queue.showInfo);

  const [fetchSessionsRequest, fetchSessionsState] = useHttpRequest(
    `participants/${participantGuid}/sessions`,
    {
      admin: true,
      method: "get",
    }
  );

  const fetchSessions = useCallback(async () => {
    if (participantGuid) {
      const sessions = await fetchSessionsRequest();

      if (sessions) {
        setSessions(sessions);
      }
    }
  }, [fetchSessionsRequest, participantGuid]);

  const latestSession = sessions.find(
    (s) => s.productionGuid === productionGuid
  );

  const latestSessionGuid = latestSession?.guid;

  const [fetchSessionDevicesRequest] = useHttpRequest(
    `participants/${participantGuid}/sessions/${latestSessionGuid}/devices`,
    {
      admin: true,
      method: "get",
    }
  );

  const [fetchSessionNetworkDiagnosticsRequest] = useHttpRequest(
    `participants/${participantGuid}/sessions/${latestSessionGuid}/network_diagnostics`,
    {
      admin: true,
      method: "get",
    }
  );

  useEffect(() => {
    fetchSessions();
  }, [connected, fetchSessions]);

  useEffect(() => {
    // ensure device and diagnostic info is retrieved at least once, re-fetch anytime participant reconnects
    if (latestSessionGuid && (connected || !hasFetched)) {
      (async () => {
        const devices = await fetchSessionDevicesRequest();
        const latestDevice = devices?.sort().pop();

        if (latestDevice) {
          setLatestSessionDevice(latestDevice);
        }

        setHasFetched(true);
      })();
    }
  }, [connected, fetchSessionDevicesRequest, hasFetched, latestSessionGuid]);

  useEffect(() => {
    if (!running) {
      (async () => {
        if (latestSessionGuid) {
          const networkDiagnostics =
            await fetchSessionNetworkDiagnosticsRequest();

          if (networkDiagnostics) {
            setNetworkDiagnostics(networkDiagnostics.sort());
          }
        }
      })();
    }
  }, [fetchSessionNetworkDiagnosticsRequest, latestSessionGuid, running]);

  const latestSessionNetworkDiagnostic =
    networkDiagnostics[networkDiagnostics.length - 1];

  return {
    latestSession,
    latestSessionDevice,
    latestSessionNetworkDiagnostic,
    networkDiagnostics,
    fetchSessions,
    fetchSessionsState,
    sessions,
  };
};

export const useParticipantSessionLogs = (
  participantGuid: string,
  sessionGuid: string
) => {
  const [eventLogs, setEventsLogs] = useState<any[]>([]);

  const [requestEventLogsForSession, requestEventLogsForSessionState] =
    useHttpRequest(
      `participants/${participantGuid}/sessions/${sessionGuid}/event_logs`,
      {
        admin: true,
        method: "get",
      }
    );

  const fetchEventLogs = useCallback(async () => {
    if (participantGuid && sessionGuid) {
      const eventLogs = await requestEventLogsForSession();

      if (eventLogs) {
        setEventsLogs(eventLogs);
      }
    }
  }, [participantGuid, sessionGuid, requestEventLogsForSession]);

  // Fetch event logs on mount
  useEffect(() => {
    fetchEventLogs();
  }, [fetchEventLogs]);

  return {
    eventLogs,
    fetchEventLogs,
    loadingEventLogs: requestEventLogsForSessionState.loading,
  };
};
