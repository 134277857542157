import { Fragment, PureComponent } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { withOktaAuth } from "@okta/okta-react";
import { confirmAlert } from "react-confirm-alert";
import { branding } from "../utilities/definitions";
import { SocketContext } from "../utilities/socket";
import CQ from "../utilities/socket/CQ";
import { changePage, zoomHostUrl } from "../store/queue/actions";
import ActivationRequired from "../modules/ActivationRequired";
import Queue from "../modules/Queue";
import Screener from "../modules/Screener";
import ControlPanelList from "../modules/Queue/ScreenerList/ControlPanelList";
import checkIfRoleChoicesAllows from "../utilities/checkIfRoleChoicesAllows";

class App extends PureComponent {
  constructor(props) {
    super(props);
    let tempState = {};
    for (var key in props) {
      tempState[key] = props[key];
    }

    this.state = { ...tempState };

    this.nonCmpCallWindow = "";

    this.changePage = this.changePage.bind(this);
    this.endNonCmpCall = this.endNonCmpCall.bind(this);
    this.startNonCmpCall = this.startNonCmpCall.bind(this);

    this.childFuncs = {
      queue: {
        screenerSelected: (_endpointID) => {
          this.changePage("Screener", _endpointID);
        },
        demoCallers: {
          createDemoCallers: (_demoCallerCount) => {
            CQ.emit("createDemoCallers", { demoCallerCount: _demoCallerCount });
          },
          removeDemoCallers: () => {
            CQ.emit("removeDemoCallers", {});
          },
        },
      },
      screener: {
        assignedCaller: {
          changePage: (_toWhich) => {
            this.changePage(_toWhich);
          },
          chatToServer: (_data) => {
            CQ.emit("chatToServer", _data);
          },
          unassignParticipant: (isLocalWebRtcCall) => {
            CQ.emit("unassignParticipant");

            if (isLocalWebRtcCall) {
              this.endNonCmpCall();
            }
          },
        },
        callButtons: {
          startAndEndCall: {
            // These are common between local calls and CMP calls
            endCall: (_data, _isLocalCall) => {
              if (_data.endpointID.includes("multibox_")) {
                confirmAlert({
                  title: `Close ${branding.crowdview}?`,
                  childrenElement: () => (
                    <div
                      style={{
                        fontSize: "2vh",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      This could be potentially disastrous.
                    </div>
                  ),
                  buttons: [
                    {
                      label: "Yes",
                      onClick: () => {
                        CQ.emit("closeMultibox", _data);
                        if (_isLocalCall) {
                          this.endNonCmpCall(_data);
                        }
                      },
                    },
                    {
                      label: "No",
                      onClick: () => {
                        /* Do nothing */
                      },
                    },
                  ],
                });
              } else {
                CQ.emit("endCall", _data);
                if (_isLocalCall) {
                  this.endNonCmpCall(_data);
                }
              }
            },
            startCall: (_data, _isLocalCall) => {
              const programNotRecording =
                this.props.programCMP &&
                this.props.programCMP.programEavesDropper &&
                this.props.programCMP.programEavesDropper.status !== "Recording"
                  ? true
                  : false;
              const isPrescreener =
                _data.endpointID.toLowerCase().indexOf("prescreen") > -1
                  ? true
                  : false;

              // TODO the "fax check mode" issues this will cause
              if (programNotRecording && !isPrescreener) {
                CQ.emit("startEavesDropping", {
                  macAddress: this.props.programCMP.macAddress,
                  network: this.props.programCMP.network,
                  type: "program",
                });
                setTimeout(() => {
                  if (_data.endpointID.includes("multibox_")) {
                    CQ.emit("launchMultibox", _data);
                  } else {
                    CQ.emit("call", _data);
                  }
                  if (_isLocalCall) {
                    this.startNonCmpCall(_data);
                  }
                }, 750);
              } else {
                if (_data.endpointID.includes("multibox_")) {
                  CQ.emit("launchMultibox", _data);
                } else {
                  CQ.emit("call", _data);
                }
                if (_isLocalCall) {
                  this.startNonCmpCall(_data);
                }
              }
            },
          },
        },
        currentScreener: {
          switchScreeners: (_newScreener) => {
            // This was the best way to do this without entirely rewriting everything pertaining to how endpoint selection is handled
            this.changePage("Producer");
            this.changePage("Screener", _newScreener);
          },
        },
      },
    };
  }

  endNonCmpCall(data) {
    try {
      this.nonCmpCallWindow.close();
      this.props.dispatch(zoomHostUrl({ hostUrl: null }));
    } catch (e) {
      console.warn("Could not close call popup, may already be closed.");
    }
    this.nonCmpCallWindow = "";
  }

  async startNonCmpCall(_data) {
    // Initiate a call on the local computer
    // Environment that it choses from
    const endpointHostURL = this.props.showInfo?.endpointHostURL;

    const multiboxUrl = `${endpointHostURL}/multibox/${_data.accountName}`;
    const gruveoUrl = `${endpointHostURL}/gruveo/${_data.accountName}?noCmp=true`;
    const webRtcUrl = `${endpointHostURL}/web-rtc/${
      _data.accountName
    }?perspective=presenter&uid=${
      this.state.currentScreener || "qm"
    }&noCmp=true`;

    switch (_data.chatApp) {
      case "facetime":
        this.nonCmpCallWindow = window.open(
          `facetime://${_data.accountName.replaceAll?.(" ", "")}?call`,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes"
        );
        this.nonCmpCallWindow.document.write(
          "<h1>Close this window when FaceTime opens</h1>"
        );
        break;
      case "gruveo":
        this.nonCmpCallWindow = window.open(
          gruveoUrl,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes"
        );
        break;
      case "twilio":
        if (_data.endpointID.includes("multibox_")) {
          confirmAlert({
            title: `Launch ${branding.crowdview}`,
            childrenElement: () => (
              <div
                style={{ fontSize: "2vh", fontWeight: "bold", color: "red" }}
              >
                Are you sure you want to launch {branding.crowdview} without a
                CMP?
              </div>
            ),
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  this.nonCmpCallWindow = window.open(
                    multiboxUrl,
                    "_blank",
                    "toolbar=yes,scrollbars=yes,resizable=yes"
                  );
                },
              },
              {
                label: "No",
                onClick: () => {
                  /* Do nothing */
                },
              },
            ],
          });
        } else {
          this.nonCmpCallWindow = window.open(
            webRtcUrl,
            "_blank",
            "toolbar=yes,scrollbars=yes,resizable=yes"
          );
        }
        break;
      case "zoom":
        /* Do nothing, handled upstream */
        break;
      default:
        //CQ.DEBUG('CQ Error', 'Attempted to start a call using a chat app with an invalid type', 'error', 'FF0000', _appType.toLowerCase());
        break;
    }
  }

  componentDidUpdate = async (prevProps) => {
    const { shows } = this.props;
    const { programCMP } = this.props;
    if (prevProps.programCMP !== programCMP || prevProps.shows !== shows) {
      this.setState({ shows, programCMP });
    }
  };

  changePage(_toWhich, _endpointID) {
    if (this.props.authState?.isAuthenticated) {
      this.props.dispatch(
        changePage({
          newPage: _toWhich,
          screenerName: _endpointID,
        })
      );
    }
  }

  render() {
    const {
      currentRole,
      isEpisodeSelected,
      page,
      roleChoices,
      screeners,
      shows,
    } = this.props;

    const canControlMultibox = checkIfRoleChoicesAllows(
      roleChoices,
      "multiboxController"
    );

    const hasAvailableShows = shows ? Object.keys(shows).length > 0 : false;

    return (
      <Grid container direction={"row"} style={{ height: "100% !important" }}>
        {hasAvailableShows ? (
          <Fragment>
            {page === "Screener" && (
              <Grid
                className={"layout-screener-screenerView"}
                id="endpointControls"
                item
                xs={6}
              >
                <Screener
                  canControlMultibox={canControlMultibox}
                  parentFuncs={this.childFuncs.screener}
                  username={this.state.username}
                />
              </Grid>
            )}

            <Grid
              className={
                page === "Screener" ? "layout-queue-screenerView" : undefined
              }
              id="queue"
              item
              xs={page === "Screener" ? 6 : 9.5}
            >
              <Queue
                currentRole={currentRole}
                parentFuncs={this.childFuncs.queue}
                view={page.toLowerCase()}
              />
            </Grid>

            {isEpisodeSelected && (
              <Grid
                className={page === "Screener" ? "layout-hidden" : undefined}
                id="endpointList"
                item
                xs={2.5}
              >
                <ControlPanelList
                  currentRole={currentRole}
                  disabled={!isEpisodeSelected}
                  parentFuncs={this.childFuncs.queue}
                  roleChoices={roleChoices}
                  screeners={screeners}
                />
              </Grid>
            )}
          </Fragment>
        ) : (
          <ActivationRequired />
        )}
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

//Function to map the redux state to object properties
const mapStateToProps = (state) => {
  const {
    currentRole,
    isEpisodeSelected,
    network,
    page,
    programCMP,
    roleChoices,
    screeners,
    showInfo,
    shows,
    username,
  } = state.queue;

  return {
    currentRole,
    isEpisodeSelected,
    network,
    page,
    programCMP,
    roleChoices,
    screeners,
    showInfo,
    shows,
    username,
  };
};

App.contextType = SocketContext;

//Connect (subscribe) this component to the redux state provider
export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(App));
