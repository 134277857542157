import type { FunctionComponent } from "react";
import { useMemo, Fragment } from "react";
import { Chip, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branding, deviceOsNames } from "../utilities/definitions";
import CustomTooltip from "./Tooltip";

type Props = {
  deviceInfo: Record<string, any> | null;
  small?: boolean;
};

const useStyles = makeStyles<any>((_theme) => ({
  deviceChip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  small: {
    justifyContent: "center",
    width: "100%",
  },
}));

const DeviceInfoIcons: FunctionComponent<Props> = (props) => {
  const { deviceInfo, small = false } = props;

  const classes = useStyles(props);

  const deviceType: string | undefined =
    deviceInfo?.deviceType ?? deviceInfo?.device?.type;
  const deviceOs: string | undefined =
    deviceInfo?.deviceOs ?? deviceInfo?.os?.name;
  const deviceOsVersion: string | undefined =
    deviceInfo?.deviceOsVersion ?? deviceInfo?.os?.version;

  const isParticipantWebRTCCompatible =
    deviceInfo?.webrtcCompatible ?? deviceInfo?.supportsWebRTC;

  const { osLogo, typeLogo } = useMemo(() => {
    let osLogo: IconProp, typeLogo: IconProp;

    switch (deviceOs?.toLowerCase()) {
      case "ios":
        osLogo = ["fab", "apple"];
        break;
      case "macos":
        osLogo = ["fab", "apple"];
        break;
      case "windows":
        osLogo = ["fab", "windows"];
        break;
      case "android":
        osLogo = ["fab", "android"];
        break;
      default:
        osLogo = ["fas", "question-circle"];
        break;
    }

    switch (deviceType?.toLowerCase()) {
      case "mobile":
        typeLogo = ["fas", "mobile"];
        break;
      case "desktop":
        typeLogo = ["fas", "desktop"];
        break;
      case "tablet":
        typeLogo = ["fas", "tablet"];
        break;
      default:
        typeLogo = ["fas", "question-circle"];
        break;
    }

    return {
      osLogo,
      typeLogo,
    };
  }, [deviceType, deviceOs]);

  const parsedOsName = deviceOsNames[deviceOs] ?? "Other OS";

  const tooltipTitle = deviceInfo
    ? `${parsedOsName} ${
        deviceOs.toLowerCase() === "macos" ? "" : deviceOsVersion
      }${` (${deviceType})`}${
        isParticipantWebRTCCompatible ? "" : " - Not WebRTC compatible"
      }`.toUpperCase()
    : `Device summary will be displayed when participant opens their ${branding.stagedoor} link`;

  return (
    <CustomTooltip title={tooltipTitle}>
      <Stack direction="row">
        {!small && (
          <Fragment>
            <Chip
              classes={classes.deviceChip}
              color={
                isParticipantWebRTCCompatible
                  ? "success"
                  : deviceInfo
                  ? "primary"
                  : "error"
              }
              label={<FontAwesomeIcon icon={osLogo} />}
              variant={deviceInfo ? "filled" : "outlined"}
            />
            &nbsp;
            <Chip
              classes={classes.deviceChip}
              color={deviceInfo ? "primary" : "error"}
              label={<FontAwesomeIcon icon={typeLogo} />}
              variant={deviceInfo ? "filled" : "outlined"}
            />
          </Fragment>
        )}
        {small && (
          <div className={classes.small}>
            <FontAwesomeIcon icon={osLogo} size="sm" />
            &nbsp;
            <FontAwesomeIcon icon={typeLogo} size="sm" />
          </div>
        )}
      </Stack>
    </CustomTooltip>
  );
};

export default DeviceInfoIcons;
