import { Fragment } from "react";
import { Tooltip } from "@mui/material";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { branding } from "../../../utilities/definitions";

const ParticipantEavesDropRecordingStatus = ({ participantEavesDropper }) => {
  const eavesdropRecordingText = participantEavesDropper?.status?.includes(
    "Error"
  )
    ? `${branding.eavesdrop} Error; Enter Endpoint for more details`
    : `${branding.eavesdrop} is Recording`;

  const hasEavesdropError = participantEavesDropper?.status?.includes("Error");
  const eavesdropIsRecording =
    !hasEavesdropError && participantEavesDropper?.status === "Recording";

  const eavesdropRecordingClassName = `float-right${
    eavesdropIsRecording ? " recording-indicator" : ""
  }`;

  return (
    <Tooltip placement={"bottom"} title={eavesdropRecordingText}>
      <div className={eavesdropRecordingClassName}>
        {eavesdropIsRecording && (
          <Fragment>
            <strong>REC</strong>
            &nbsp;
            <Spinner animation="grow" variant="danger" />
          </Fragment>
        )}
        {hasEavesdropError && (
          <Fragment>
            <strong>ERROR</strong>
            &nbsp;
            <FontAwesomeIcon icon={["fas", "exclamation"]} />
          </Fragment>
        )}
      </div>
    </Tooltip>
  );
};

export default ParticipantEavesDropRecordingStatus;
