import { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import { oidcConfig } from "../utilities/okta";

import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";

export default function LoginPage() {
  const widgetRef = useRef();

  useEffect(() => {
    if (widgetRef.current) {
      const { pkce, issuer, clientId, redirectUri, scopes } = oidcConfig;

      const widget = new OktaSignIn({
        el: widgetRef.current,
        baseUrl: issuer.split("/oauth2")[0],
        logo: "/img/airfirst_logo.png",
        clientId,
        redirectUri,
        i18n: {
          en: {
            "primaryauth.title": " ",
            "primaryauth.username.placeholder": "Email",
            "primaryauth.password.placeholder": "Password",
            "password.expiring.subtitle.generic":
              "You will be unable to access the platform if you do not change your password before it expires.",
            "errors.E0000069":
              "Your account has been locked. If you are unable to reset your password, contact vcc-support@nextologies.com or +1 (914) 732-1774 for assistance",
            "errors.E0000004":
              "The email or password you provided is incorrect. If you are unable to reset your password, contact vcc-support@nextologies.com or +1 (914) 732-1774 for assistance",
          },
        },
        authParams: {
          pkce,
          issuer,
          display: "page",
          scopes,
          responseType: ["code"],
        },
        helpLinks: {
          help: "mailto:vcc-support@nextologies.com?subject=Assistance needed with AirFirst™ login",
        },
      });

      widget.showSignInAndRedirect().catch((error) => {
        console.error(error);
      });

      return () => {
        widget.remove();
      };
    }
  }, [widgetRef]);

  return <div id="sign-in-widget" ref={widgetRef} />;
}
