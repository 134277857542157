import * as Ramda from "ramda";
import { webRTCApps } from "./definitions";
import * as search from "searchjs";
const searchConfig = { propertySearch: true };
search.setDefaults(searchConfig);

const translateFilterArraysIntoSmartFilters = (
  _targetZip,
  _filtersAnd,
  _filtersOr
) => {
  let smartFilters = [];

  // PARTICIPANTS MUST HAVE AT LEAST **1** of these to be displayed
  // For now we are assuming the only types of filtersOr are topics
  if (_filtersOr.length > 0) {
    let desiredTopics = [];
    for (var i = 0; i < _filtersOr.length; i++) {
      const choice = _filtersOr[i];
      if (choice.indexOf("topic_") > -1) {
        desiredTopics.push(choice.substring(6, choice.length));
      }
    }

    smartFilters.push({
      name: "topics",
      filterType: FilterTypeEnum.CHOICE,
      choiceSelector: ChoiceSelectorEnum.ANY,
      propertyName: "topic",
      choices: desiredTopics,
    }); // TODO make the propertyName 'topics' when we change the server side to reflect ability to select multiple topics (one day)
  }

  // PARTICIPANTS MUST HAVE ***ALL*** of these to be displayed
  let deviceOsOptions = [];
  for (var j = 0; j < _filtersAnd.length; j++) {
    const filterOption = _filtersAnd[j];
    const filterOptionType =
      filterOption === "snapshot"
        ? "snapshot"
        : filterOption.substring(0, filterOption.indexOf("_"));

    switch (filterOptionType) {
      case "calledanswer":
        if (filterOption.indexOf("_yes") > -1) {
          smartFilters.push({
            name: filterOption,
            filterType: FilterTypeEnum.COMPARISON,
            comparisonType: ComparisonTypeEnum.EQUALS,
            propertyName: "greenRoomState",
            filterValue: "establishedCall",
          });
          break;
        } else if (filterOption.indexOf("_no") > -1) {
          smartFilters.push({
            name: filterOption,
            filterType: FilterTypeEnum.COMPARISON,
            comparisonType: ComparisonTypeEnum.EQUALS,
            propertyName: "greenRoomState",
            filterValue: "callNoAnswer",
          });
          break;
        } else if (filterOption.indexOf("_uncalled") > -1) {
          smartFilters.push({
            name: filterOption,
            filterType: FilterTypeEnum.COMPARISON,
            comparisonType: ComparisonTypeEnum.EQUALS,
            propertyName: "greenRoomState",
            filterValue: "uncalled",
          });
        } else {
          if (filterOption.indexOf("_any") > -1) {
            // TODO nothing in the GUI does this yet
            smartFilters.push({
              name: filterOption,
              filterType: FilterTypeEnum.CHOICE,
              choiceSelector: ChoiceSelectorEnum.ANY,
              propertyName: "greenRoomState",
              filterValue: ["callNoAnswer", "establishedCall", "uncalled"],
            });
          }
          break;
        }
        break;
      case "chatApp":
        const propertyName = filterOption.substring(
          filterOption.indexOf("_") + 1,
          filterOption.length
        );
        smartFilters.push({
          name: filterOption,
          filterType: FilterTypeEnum.EXISTS,
          propertyName: propertyName,
        });
        if (webRTCApps.includes(propertyName)) {
          smartFilters.push({
            name: "webRtcCompatible",
            filterType: FilterTypeEnum.COMPARISON,
            comparisonType: ComparisonTypeEnum.EQUALS,
            propertyName: `deviceInfo.${`webRTCcompatible`}`,
            filterValue: true,
          });
        }
        if (propertyName === "facetime") {
          smartFilters.push({
            name: "isMacOsOrIOS",
            filterType: FilterTypeEnum.CHOICE,
            choiceSelector: ChoiceSelectorEnum.ANY,
            propertyName: `deviceInfo.${`os`}.${`name`}`,
            choices: ["macos", "ios"],
          });
        }
        break;
      case "deviceos":
        // This one is intentionally different from the rest because it's an anded 'or'
        deviceOsOptions.push(filterOption);
        break;
      case "distance":
        if (_targetZip !== false) {
          smartFilters.push({
            name: filterOption,
            filterType: FilterTypeEnum.COMPARISON,
            comparisonType: ComparisonTypeEnum.TO,
            propertyName: `targetZipCodeDistances.${_targetZip}`,
            filterValue: parseInt(
              filterOption.substring(
                filterOption.indexOf("under_") + 6,
                filterOption.length
              )
            ),
          });
        }
        break;
      case "snapshot":
        smartFilters.push({
          name: filterOption,
          filterType: FilterTypeEnum.EXISTS,
          propertyName: "snapshotURL",
        });
        break;
      default:
        // Currently only devicetype adheres to this rule
        smartFilters.push({
          name: filterOptionType,
          filterType: FilterTypeEnum.CHOICE,
          choiceSelector: ChoiceSelectorEnum.ANY,
          propertyName: "tags",
          choices: [filterOption],
        });
        break;
    }
  }

  if (deviceOsOptions.length > 0) {
    deviceOsOptions.sort();
    smartFilters.push({
      name: "deviceos",
      filterType: FilterTypeEnum.CHOICE,
      choiceSelector: ChoiceSelectorEnum.ANY,
      propertyName: "tags",
      choices: deviceOsOptions,
    });
  }

  return Ramda.uniqBy((filter) => filter.name, smartFilters);
};

const FilterTypeEnum = Object.freeze({
  EXISTS: "exists", //NOTE: Checks for the existence of a value set for the given property.
  COMPARISON: "comparison", //NOTE: Offers various comparison types to test property values against given filter value.
  CHOICE: "choice", //NOTE: Allows for testing a property against an array of choices.
  RANGE: "range", //NOTE: Allows testing for a range of values.
});

const ChoiceSelectorEnum = Object.freeze({
  ANY: "any", //NOTE: This option will allow any of the choices to match i.e. UNION of all the choices
  ALL: "all", //NOTE: This option will require all of the choices to match i.e. INTERSECTION of all the choices
});

const ComparisonTypeEnum = Object.freeze({
  EQUALS: "eq",
  NOT_EQUALS: "neq",
  LESS_THAN: "lt",
  GREATER_THAN: "gt",
  FROM: "from", //NOTE: Equivalent to >= comparison
  TO: "to", //NOTE: Equivalent to <= comparison
});

const PropertyFilter = {
  exists: (items, filter) => {
    return search.matchArray(items, { [filter.propertyName]: "", _not: true });
  },

  comparison: (items, filter) => {
    let filteredItems;
    switch (filter.comparisonType) {
      case ComparisonTypeEnum.EQUALS:
        filteredItems = search.matchArray(items, {
          [filter.propertyName]: filter.filterValue,
        });
        break;
      case ComparisonTypeEnum.NOT_EQUALS:
        filteredItems = search.matchArray(items, {
          [filter.propertyName]: filter.filterValue,
          _not: true,
        });
        break;
      default:
        filteredItems = search.matchArray(items, {
          [filter.propertyName]: {
            [filter.comparisonType]: filter.filterValue,
          },
        });
    }

    return filteredItems;
  },

  choice: (items, filter) => {
    let filteredItems;
    switch (filter.choiceSelector) {
      case ChoiceSelectorEnum.ANY:
        filteredItems = search.matchArray(items, {
          [filter.propertyName]: filter.choices,
        });
        break;
      case ChoiceSelectorEnum.ALL:
        let choices_for_intersection = filter.choices.map((choice) => {
          return { [filter.propertyName]: choice };
        });
        filteredItems = search.matchArray(items, {
          _join: "AND",
          terms: choices_for_intersection,
        });
        break;
      default:
        console.error("Invalid choiceSelector value for Choice filter.");
        filteredItems = items;
    }

    return filteredItems;
  },

  /*
   * NOTE: There is a caveat in this functionality. Passing a number representation of 0 causes the comparisons to
   * fail in the underlying library. If passing integers for comparison that may be a ZERO value, pass min/max as a
   * String representation instead.
   * e.g. {filterType: 'range', min: '0', max: '60', propertyName: 'age'}
   */
  range: (items, filter) => {
    let filteredItems;
    if (!(filter.min == null || filter.max == null)) {
      filteredItems = search.matchArray(items, {
        [filter.propertyName]: { from: filter.min, to: filter.max },
      });
    } else {
      console.error("Range filter missing range values.");
    }
    return filteredItems;
  },

  filter: (_items, _filters) => {
    let filteredItems = _items;
    for (let filter of _filters) {
      if (filter && filter.filterType && filter.propertyName) {
        filteredItems = PropertyFilter[filter.filterType].apply(
          PropertyFilter,
          [filteredItems, filter]
        );
      } else {
        console.error("Invalid filter constructed.");
      }
    }
    return filteredItems;
  },
};

const filter = (_participants, _targetZip, _filtersAnd, _filtersOr) => {
  const smartFilters = translateFilterArraysIntoSmartFilters(
    _targetZip,
    _filtersAnd,
    _filtersOr
  );
  const participantsAsObjectArray = Object.entries(_participants);
  const filteredParticipants = PropertyFilter.filter(
    participantsAsObjectArray,
    smartFilters
  );

  if (Boolean(_targetZip)) {
    filteredParticipants.sort(function (first, second) {
      let firstDistance = first[1].targetZipCodeDistances[_targetZip];
      let secondDistance = second[1].targetZipCodeDistances[_targetZip];

      return secondDistance - firstDistance;
    });
    filteredParticipants.reverse();
  }

  const filteredParticipantsObjectArrayAsObject =
    Object.fromEntries(filteredParticipants);

  return filteredParticipantsObjectArrayAsObject;
};

export default { filter };
