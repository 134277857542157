import { createAction } from "@reduxjs/toolkit";
import { withPayloadType } from "../helpers";
import { SET_DISPLAY_MILITARY, SET_TIME_ZONE } from "./types";

export const setDisplayMilitary = createAction(
  SET_DISPLAY_MILITARY,
  withPayloadType<boolean>()
);

export const setTimeZone = createAction(
  SET_TIME_ZONE,
  withPayloadType<string>()
);
