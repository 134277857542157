export const getRows = (_endpointFullName) => {
  let result = 1;

  try {
    const parsedEndpointName = _endpointFullName.replace(
      _endpointFullName.substring(
        _endpointFullName.indexOf("layout"),
        _endpointFullName.length
      ),
      ""
    );
    const withoutParsedName = _endpointFullName
      .replace(parsedEndpointName, "")
      .replace("layout", "");
    const rows = withoutParsedName.substring(0, withoutParsedName.indexOf("x"));

    const parsedAsInt = parseInt(rows);
    // @ts-ignore
    if (!parsedAsInt.isNaN) {
      result = parsedAsInt;
    }
  } catch (e) {
    console.error(e);
  }

  return result;
};

export const getSeats = (_endpointFullName) => {
  let result = 1;

  try {
    const parsedEndpointName = _endpointFullName.replace(
      _endpointFullName.substring(
        _endpointFullName.indexOf("layout"),
        _endpointFullName.length
      ),
      ""
    );
    const withoutParsedName = _endpointFullName
      .replace(parsedEndpointName, "")
      .replace("layout", "");
    const seats = withoutParsedName.substring(
      withoutParsedName.indexOf("x") + 1,
      withoutParsedName.length
    );

    const parsedAsInt = parseInt(seats);
    //@ts-ignore
    if (!parsedAsInt.isNaN) {
      result = parsedAsInt;
    }
  } catch (e) {
    console.error(e);
  }

  return result;
};
