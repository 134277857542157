import {
  storeForcedLogoutRecoveryData,
  onSocketConnect,
  allocateCMP,
  cmpList,
  setProgramCMP,
  setShowInfo,
  setServerOffset,
  setParticipantEavesDropStatus,
  setProgramEavesDropStatus,
} from "../../store/queue/actions";

import { store } from "../../store";
import { ParticipantType } from "../../store/types";
import { queueEventListeners } from "../../store/queue/eventListeners";

export const eventListeners = {
  ...queueEventListeners,
  disconnect: () => {
    store.dispatch(storeForcedLogoutRecoveryData());
  },
  connect: () => {
    store.dispatch(onSocketConnect());
  },
  allocateCMPresponse: (_data) => {
    if (_data.success) {
      store.dispatch(allocateCMP(_data.cmp));
    } else {
      console.warn("Could not allocate CMP:");
      console.dir(_data.error);
    }
  },
  cmpList: (_data) => {
    store.dispatch(cmpList(_data.cmpList));
  },
  errorFromServer: (_data) => {
    store.dispatch({
      type: "CRITICAL_ERROR",
      payload: { message: _data.message },
    });
  },
  episodeInfo: (_data) => {
    store.dispatch({ type: "EPISODE_INFO", epInfo: _data });
  },
  participantEavesDropStatus: (data) =>
    store.dispatch(setParticipantEavesDropStatus(data.info)),
  programEavesDropStatus: (data) =>
    store.dispatch(setProgramEavesDropStatus(data.info)),
  kickedByProducer: (_data) => {
    store.dispatch({ type: "KICKED_BY_PRODUCER", data: _data });
  },
  messageFromServer: (data: any) => {
    store.dispatch({ type: "SERVER_MESSAGE", data: data.message });
  },
  participantAdded: (data: any) => {
    if (data.type === ParticipantType.QUEUE)
      store.dispatch({ type: "ADD_QUEUE_PARTICIPANT", payload: data });
  },
  participantRemoved: (data) => {
    const { id, type } = data;
    if (type === ParticipantType.QUEUE)
      store.dispatch({ type: "REMOVE_QUEUE_PARTICIPANT", payload: id });
  },
  participantUpdated: (data) => {
    if (data.type === ParticipantType.QUEUE)
      store.dispatch({ type: "UPDATE_QUEUE_PARTICIPANT", payload: data });
  },
  programCMP: (_data) => {
    store.dispatch(setProgramCMP(_data.programCMP));
  },
  sendCallers: (_data) => {
    store.dispatch({
      type: "CALLERS",
      currentHash: _data.currentHash,
      callers: _data.callers,
      totalCallersInQueue: _data.totalCallersInQueue,
      timeStamp: new Date(),
    });
  },
  showList: (_data) => {
    store.dispatch({ type: "SHOWS", shows: _data.shows });
  },
  showInfo: ({ showInfo }) => {
    const { zoomApiAvailability } = showInfo;
    const { available, nextAvailableDateAndTime } = zoomApiAvailability;

    if (available === false) {
      store.dispatch({
        type: "ZOOM_AVAILABILITY_CHANGE",
        payload: {
          available,
          nextAvailableDateAndTime,
        },
      });
    }

    store.dispatch(setShowInfo(showInfo));
  },
  timesync: (_data) => {
    store.dispatch(setServerOffset(_data.timesync));
  },
  zipError: (_data) => {
    store.dispatch({ type: "ZIP_ERROR", error: _data.message });
  },
  zoomHostUrl: (_data) => {
    store.dispatch({
      type: "ZOOM_HOST_URL",
      payload: { zoomHostUrl: _data.hostUrl },
    });
  },
  hostedNetworks: (_data) => {
    store.dispatch({
      type: "SET_HOSTED_NETWORKS",
      hostedNetworks: _data.hostedNetworks,
    });
  },
  zoomAvailability: (_data) => {
    const { available, nextAvailableDateAndTime } = _data;
    store.dispatch({
      type: "ZOOM_AVAILABILITY_CHANGE",
      payload: { available, nextAvailableDateAndTime },
    });
  },
};
