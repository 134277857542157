import { useEffect, useMemo, useState } from "react";
import {
  Duration,
  addMilliseconds,
  set,
  format,
  intervalToDuration,
  subHours,
  subMinutes,
  subSeconds,
} from "date-fns";

type TimeUnit = "second" | "minute" | "hour";

const unitToMilliseconds = {
  second: 1000,
  minute: 60000,
  hour: 3600000,
};

const unitToFormat = {
  second: "HH:mm:ss",
  minute: "HH:mm",
  hour: "HH",
};

export const useTimer = (
  startingTime: string | number,
  unitToIncrement: TimeUnit,
  timeOffset?: number
) => {
  const adjustedStartingTime = useMemo(() => {
    if (startingTime) {
      const startingTimeAsDate = new Date(startingTime);

      return timeOffset
        ? addMilliseconds(startingTimeAsDate, timeOffset)
        : startingTimeAsDate;
    }

    return null;
  }, [startingTime, timeOffset]);

  const [duration, setDuration] = useState<Duration>({
    seconds: 0,
    minutes: 0,
    hours: 0,
  });

  useEffect(() => {
    if (adjustedStartingTime && unitToIncrement) {
      setDuration(
        intervalToDuration({
          start: adjustedStartingTime,
          end: new Date(),
        })
      );

      const milliseconds = unitToMilliseconds[unitToIncrement];
      const interval = setInterval(() => {
        const updatedDuration = intervalToDuration({
          start: adjustedStartingTime,
          end: new Date(),
        });

        setDuration(updatedDuration);
      }, milliseconds);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {
      // empty cleanup for consistent return
    };
  }, [adjustedStartingTime, unitToIncrement]);

  const durationString = format(
    set(new Date(), duration),
    unitToFormat[unitToIncrement]
  );

  return { duration, durationString };
};

export const useCountdown = (startingTime: Date, unitToDecrement: TimeUnit) => {
  const [timeRemaining, setTimeRemaining] = useState<Date | null>(null);

  useEffect(() => {
    if (startingTime && unitToDecrement) {
      setTimeRemaining(startingTime);
      const milliseconds = unitToMilliseconds[unitToDecrement];
      const interval = setInterval(() => {
        setTimeRemaining((timeRemaining) => {
          let updatedTime: Date = null;

          if (unitToDecrement === "hour") {
            updatedTime = subHours(timeRemaining, 1);
          } else if (unitToDecrement === "minute") {
            updatedTime = subMinutes(timeRemaining, 1);
          } else {
            updatedTime = subSeconds(timeRemaining, 1);
          }

          return updatedTime;
        });
      }, milliseconds);

      return () => {
        clearInterval(interval);
        setTimeRemaining(null);
      };
    }
  }, [startingTime, unitToDecrement]);

  return timeRemaining;
};

export const useClickTimeout = (seconds?: number) => {
  const [hasClicked, setHasClicked] = useState<boolean>(false);
  const [isTimeoutActive, setIsTimeoutActive] = useState<boolean>(false);

  useEffect(() => {
    if (hasClicked && seconds) {
      setIsTimeoutActive(true);

      const timeoutMilliseconds = seconds * 1000;
      const timeout = setTimeout(() => {
        setHasClicked(false);
        setIsTimeoutActive(false);
      }, timeoutMilliseconds);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [hasClicked, seconds]);

  return {
    isTimeoutActive: isTimeoutActive,
    triggerTimeout: () => {
      setHasClicked(true);
    },
  };
};
