export const participantSummary = {
  scheduled: {
    buttons: {
      completeCall: "Complete/Remove Event",
      undoCompleteCall: "Restore Event",
    },
    tooltips: {
      completeCall: "Remove event from schedule",
      undoCompleteCall: "Restore event to schedule",
    },
    eventDetails: {
      typeText: {
        air: "Air",
        test: "Test",
      },
    },
  },
};

export const manualParticipantText = "Manual Participant";

export const nameNotProvided = "Name Not Provided";

export const unknownReplacement = "Unknown";
