import type { FunctionComponent, MouseEventHandler } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Statistic from "./Statistic";
import NetworkQualityScore from "./NetworkQualityScore";
import TooltipButton from "../TooltipButton";
import { useClickTimeout } from "../../utilities/customHooks";
import { NetworkDiagnosticStatus } from "./types";
import { branding } from "../../utilities/definitions";
import { buttons, resultsAge, statistics } from "./templates"; // isBeta
import {
  useDateFormatter,
  useParticipantSessions,
  useSelector,
  useSocket,
} from "../../hooks";
import { isWithinInterval, parseISO, subHours } from "date-fns";
import colorDefinitions from "../../utilities/colorDefinitions";
import CustomTooltip from "../Tooltip";

const kbpsToMbps = (value: number) => value / 1000;

type Props = {
  connected: boolean;
  disableTestButton?: boolean;
  networkDiagnosticState: any;
  participantID: string;
  participantType: string;
};

const NetworkDiagnostics: FunctionComponent<Props> = (props) => {
  const {
    connected,
    disableTestButton,
    networkDiagnosticState = {
      error: null,
      running: false,
    },
    participantID,
    participantType,
  } = props;

  const { error, running } = networkDiagnosticState;
  const { latestSessionNetworkDiagnostic, networkDiagnostics } =
    useParticipantSessions(participantID, connected, networkDiagnosticState);

  const numTimesCompleted = networkDiagnostics.length;
  const { createdAt, score, ...stats } = latestSessionNetworkDiagnostic ?? {};

  const thresholds = useSelector(
    (state: any) => state.queue.clientConfiguration?.performanceThresholds
  );

  const { isTimeoutActive, triggerTimeout } = useClickTimeout(3);

  const socketService = useSocket();

  const formatDate = useDateFormatter();

  const handleTestButtonClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      const event = running
        ? "stopNetworkDiagnostic"
        : "startNetworkDiagnostic";
      socketService.emit(event, {
        participantID,
        participantType,
      });

      triggerTimeout();
    }, [
      participantID,
      participantType,
      running,
      socketService,
      triggerTimeout,
    ]);

  const resultsAgeText: string = useMemo(() => {
    if (running) {
      return resultsAge.state.pending;
    } else if (error && typeof createdAt !== "string") {
      return resultsAge.state.error;
    } else if (numTimesCompleted < 1) {
      return resultsAge.state.notTested;
    } else if (typeof createdAt === "string") {
      return formatDate(parseISO(createdAt));
    } else {
      return "";
    }
  }, [createdAt, error, formatDate, numTimesCompleted, running]);

  const testStatus: NetworkDiagnosticStatus = useMemo(() => {
    if (running) {
      return "pending";
    } else if (!running && numTimesCompleted < 1) {
      return "not-started";
    }

    return "completed";
  }, [numTimesCompleted, running]);

  const determineIfScoreIsOld = (networkDiagnostics) => {
    if (networkDiagnostics) {
      const createdAt = parseISO(networkDiagnostics?.createdAt) ?? undefined;
      const now = new Date();
      if (createdAt) {
        return !isWithinInterval(createdAt, {
          start: subHours(now, 1),
          end: now,
        });
      }
    }
    return false;
  };

  const [isOldTenacitiScore, setIsOldTenacitiScore] = useState<boolean>(false);

  useEffect(() => {
    const isOld = determineIfScoreIsOld(latestSessionNetworkDiagnostic);
    setIsOldTenacitiScore(isOld);
  }, [latestSessionNetworkDiagnostic]);

  return (
    <Alert
      color="info"
      icon={
        <img
          alt={`${branding.tenaciti} logo`}
          src="/img/tenaciti_beta_logo.png"
          style={{
            height: 35,
            marginRight: 15,
            width: "auto",
          }}
        />
      }
      sx={{
        width: "100%",
        "& .MuiAlert-icon": {
          alignItems: "center",
          display: "flex",
        },
        "& .MuiAlert-message": {
          width: "100%",
        },
      }}
    >
      <AlertTitle style={{ width: "inherit" }}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid className="text-center" item>
            <Typography variant="caption">{resultsAge.title}</Typography>
            {!isOldTenacitiScore && (
              <>
                <Typography
                  sx={
                    resultsAgeText !== resultsAge.state.notTested &&
                    resultsAgeText !== resultsAge.state.notTested &&
                    resultsAgeText !== resultsAge.state.error
                      ? { color: colorDefinitions.GREEN }
                      : resultsAgeText === resultsAge.state.error
                      ? { color: colorDefinitions.RED }
                      : undefined
                  }
                >
                  <b>{resultsAgeText}</b>
                </Typography>
              </>
            )}
            {isOldTenacitiScore && (
              <>
                <Typography sx={{ color: colorDefinitions.RED }}>
                  <b>{resultsAgeText}</b>
                  &nbsp;
                  <CustomTooltip
                    placement="right"
                    title={
                      "Score was last retrieved over 1 hour ago.  Please Re-Test when possible."
                    }
                  >
                    <span style={{ color: colorDefinitions.RED }}>{"⚠"}</span>
                  </CustomTooltip>
                </Typography>
              </>
            )}
          </Grid>
          <Grid item>
            <NetworkQualityScore score={score} testStatus={testStatus} />
          </Grid>
          {running && (
            <Grid item>
              <CircularProgress />
            </Grid>
          )}
          <Grid item>
            <TooltipButton
              color={running ? "error" : "info"}
              fullWidth
              isDisabled={disableTestButton || (isTimeoutActive && !running)}
              text={running ? buttons.stopTest.text : buttons.startTest.text}
              title={
                disableTestButton
                  ? buttons.testDisabled.tooltip
                  : running
                  ? buttons.stopTest.tooltip
                  : buttons.startTest.tooltip
              }
              onClick={handleTestButtonClick}
            />
          </Grid>
        </Grid>
      </AlertTitle>

      <Divider style={{ marginBottom: "5px", marginTop: "5px" }} />
      <Grid container justifyContent="space-between" style={{ width: "100%" }}>
        <Statistic
          description={statistics.rtt.description}
          icon={["fas", "redo-alt"]}
          stat={stats?.rtt}
          testStatus={testStatus}
          thresholds={thresholds?.rtt}
          title={statistics.rtt.title}
          unit={statistics.rtt.unit}
        />
        <Statistic
          description={statistics.packetLoss.description}
          icon={["fas", "exchange-alt"]}
          stat={stats?.packetLoss}
          testStatus={testStatus}
          thresholds={thresholds?.packetLoss}
          title={statistics.packetLoss.title}
          unit={statistics.packetLoss.unit}
        />
        <Statistic
          description={statistics.jitter.description}
          icon={["fas", "history"]}
          stat={stats?.jitter}
          testStatus={testStatus}
          thresholds={thresholds?.jitter}
          title={statistics.jitter.title}
          unit={statistics.jitter.unit}
        />
        <Statistic
          description={statistics.bitrate.description}
          icon={["fas", "arrow-up"]}
          stat={stats?.bitrate ? kbpsToMbps(stats.bitrate) : undefined}
          testStatus={testStatus}
          thresholds={
            thresholds?.bitrate
              ? {
                  bad: kbpsToMbps(thresholds.bitrate.bad),
                  good: kbpsToMbps(thresholds.bitrate.good),
                }
              : undefined
          }
          title={statistics.bitrate.title}
          unit={statistics.bitrate.unit}
        />
      </Grid>
    </Alert>
  );
};

export default NetworkDiagnostics;
