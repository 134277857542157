export const colorDefinitions = {
  BLACK: "#FFFFFF",
  GREEN: "#73DC7A",
  GREEN_BRIGHT: "84DA83",
  GREEN_FADED: "#ADE3A3",
  GREY_BRIGHT: "#BFBFBF",
  GREY_DARK: "#9C9C9C",
  RED: "#FF8787",
  RED_BRIGHT: "#DC3545",
  RED_DARK: "#D28884",
  RED_FADED: "#EB9995",
  WHITE: "#000000",
  YELLOW_BRIGHT: "#FFFF00",
  YELLOW_DARK: "#DAC383",
  YELLOW_DEFAULT: "yellow",
  YELLOW_FADED: "#FFE59A",
};

export default colorDefinitions;
