import type { FunctionComponent } from "react";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import { isWithinInterval, parseISO, subHours } from "date-fns";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colorDefinitions from "../../utilities/colorDefinitions";
import {
  branding,
  deviceOsNames,
  deviceTypes,
} from "../../utilities/definitions";
import { useDateFormatter } from "../../hooks";
import CustomTooltip from "../../components/Tooltip";

const useStyles = makeStyles<
  any,
  {
    facetimeCompatible: boolean;
    webrtcCompatible: boolean;
  }
>((_theme) => ({
  facetimeCompatible: (props) => {
    return {
      color: props.facetimeCompatible
        ? colorDefinitions.GREEN
        : colorDefinitions.RED,
    };
  },
  webrtcCompatible: (props) => {
    return {
      color: props.webrtcCompatible
        ? colorDefinitions.GREEN
        : colorDefinitions.RED,
    };
  },
}));

type Props = {
  deviceInfo: any;
  networkDiagnostics?: any;
};

const DeviceInfo: FunctionComponent<Props> = (props) => {
  const { deviceInfo, networkDiagnostics } = props;

  const formatDate = useDateFormatter();

  const formattedNetworkQualityScore = networkDiagnostics?.score
    ? `${networkDiagnostics.score.toFixed(1)} / 5.0`
    : "N/A";

  const recommendedBrowser =
    deviceInfo?.os?.name?.toLowerCase() === "ios" ||
    deviceInfo?.os?.name?.toLowerCase() === "macos"
      ? "Safari"
      : "Google Chrome";

  const facetimeCompatible = deviceInfo?.facetimeCompatible;
  const webrtcCompatible = deviceInfo?.webrtcCompatible;

  const classes = useStyles({
    facetimeCompatible,
    webrtcCompatible,
  });

  let browserText = "Awaiting Connection";
  let deviceTypeText = "Awaiting Connection";
  let deviceOsText = "Awaiting Connection";

  if (deviceInfo) {
    const {
      browserName,
      browserVersion,
      deviceType,
      deviceOs,
      deviceOsVersion,
    } = deviceInfo;

    browserText = browserName
      ? `${browserName} ${browserVersion}`
      : "Not Provided";

    const parsedOsName = deviceOsNames[deviceOs] ?? "Other OS";

    deviceTypeText = deviceType ? deviceTypes[deviceType] : "Not Provided";
    deviceOsText = deviceOs
      ? `${parsedOsName} ${
          deviceOs.toLowerCase() === "macos" ? "" : deviceOsVersion
        }`
      : "Not Provided";
  }

  const compatibleIcon: IconProp = ["fas", "check-square"];
  const incompatibleIcon: IconProp = ["fas", "times-circle"];
  const retrievedAtText = deviceInfo?.createdAt
    ? formatDate(deviceInfo.createdAt)
    : "N/A";

  const determineIfScoreIsOld = (networkDiagnostics) => {
    if (networkDiagnostics) {
      const createdAt = parseISO(networkDiagnostics?.createdAt) ?? undefined;
      const now = new Date();
      if (createdAt) {
        return !isWithinInterval(createdAt, {
          start: subHours(now, 1),
          end: now,
        });
      }
    }
    return false;
  };

  const [isOldTenacitiScore, setIsOldTenacitiScore] = useState<boolean>(false);

  useEffect(() => {
    const isOld = determineIfScoreIsOld(networkDiagnostics);
    setIsOldTenacitiScore(isOld);
  }, [networkDiagnostics]);

  return (
    <Grid className="fullWidth" container direction="column" item spacing={1}>
      <Grid item>
        <b>Retrieved At: </b> {retrievedAtText}
      </Grid>

      <Grid item>
        <b>Device Type:</b> {deviceTypeText}
      </Grid>

      <Grid item>
        <b>Device OS:</b> {deviceOsText}
      </Grid>

      <Grid item>
        <b>Browser:</b> {browserText}
      </Grid>
      {networkDiagnostics && (
        <Grid item>
          {!isOldTenacitiScore && (
            <>
              <b>{branding.tenaciti} Score: </b> {formattedNetworkQualityScore}
            </>
          )}
          {isOldTenacitiScore && (
            <>
              <b>{branding.tenaciti} Score: </b>
              {formattedNetworkQualityScore}&nbsp;
              <CustomTooltip
                placement="right"
                title={
                  "Score was last retrieved over 1 hour ago.  Please Re-Test when possible."
                }
              >
                <span style={{ color: colorDefinitions.RED }}>{"⚠"}</span>
              </CustomTooltip>
            </>
          )}
        </Grid>
      )}
      <Grid item>
        <b>FaceTime Compatible: </b>&nbsp;
        <span className={classes.facetimeCompatible}>
          {facetimeCompatible ? (
            <FontAwesomeIcon icon={compatibleIcon} />
          ) : (
            <FontAwesomeIcon icon={incompatibleIcon} />
          )}
        </span>
      </Grid>
      <Grid item>
        <b>WebRTC Compatible: </b>&nbsp;
        <span className={classes.webrtcCompatible}>
          {webrtcCompatible ? (
            <FontAwesomeIcon icon={compatibleIcon} />
          ) : (
            <FontAwesomeIcon icon={incompatibleIcon} />
          )}
        </span>
        {deviceInfo && !webrtcCompatible && (
          <span>
            <i>
              {" "}
              Recommend participant rejoins using{" "}
              <b className="importantText">{recommendedBrowser}</b> browser.
            </i>
          </span>
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceInfo;
