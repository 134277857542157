import type {
  ChangeEventHandler,
  CSSProperties,
  FunctionComponent,
  MouseEvent,
} from "react";
import type { TableContainerProps } from "@mui/material";

import { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { BaseRowProps } from "./TableRow";
import CustomTooltip from "./Tooltip";

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "revert!important" as "revert",
  },
  toolbar: {
    paddingTop: 10,
  },
}));

type RowRenderer = (baseProps: BaseRowProps) => JSX.Element;

type Props = {
  columns: HeadCell[];
  containerProps?: TableContainerProps;
  data: any[];
  initialOrder?: Order;
  initialOrderByProperty: string;
  initialRowsPerPage?: number;
  keepClosedRows?: string[];
  multiselect?: boolean;
  preselected?: string[];
  rowsPerPageOptions?: number[];
  searchable?: boolean;
  showCheckboxes?: boolean;
  style?: CSSProperties;
  toolbarChildren?: JSX.Element | JSX.Element[];
  tooltip?: string;
  uniqueIdentifierProperty?: string;
  handleSelectedChange?: (selected: readonly string[]) => void;
  rowRenderer: RowRenderer;
};

const CustomTable: FunctionComponent<Props> = (props) => {
  const {
    columns,
    containerProps = {},
    data,
    initialOrder = "asc",
    initialOrderByProperty,
    initialRowsPerPage,
    keepClosedRows,
    multiselect = false,
    preselected = [],
    rowsPerPageOptions = [50, 100, 250],
    searchable = false,
    showCheckboxes = false,
    style,
    toolbarChildren,
    tooltip = "Search this table",
    uniqueIdentifierProperty = "id",
    handleSelectedChange,
    rowRenderer,
  } = props;

  const classes = useStyles();

  const [order, setOrder] = useState<Order>(initialOrder);
  const [orderBy, setOrderBy] = useState<string>(initialOrderByProperty);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialRowsPerPage ?? rowsPerPageOptions[rowsPerPageOptions.length - 1]
  );
  const [searchTerm, setSearchTerm] = useState<string>(undefined);
  const [selected, setSelected] = useState<readonly string[]>(preselected);

  useEffect(() => {
    handleSelectedChange?.(selected);
  }, [selected]);

  const handleRequestSort = (_event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchTermChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (rowId: string) => {
    if (multiselect) {
      if (selected.includes(rowId)) {
        setSelected((selected) => selected.filter((id) => id !== rowId));
      } else {
        setSelected((selected) => [...selected, rowId]);
      }
    } else {
      if (selected.includes(rowId)) {
        setSelected([]);
      } else {
        setSelected([rowId]);
      }
    }
  };

  const isSelected = (rowId: string) => selected.includes(rowId);

  const sortedAndFilteredParticipants = useMemo(() => {
    let sortedData = stableSort(data, getComparator(order, orderBy));
    if (searchTerm) {
      return sortedData.filter((datum) =>
        JSON.stringify(datum).toLowerCase().includes(searchTerm)
      );
    }

    return sortedData;
  }, [data, order, orderBy, searchTerm]);

  return (
    <Paper classes={{ root: classes.root }} style={style}>
      {(searchable || toolbarChildren) && (
        <Toolbar className={classes.toolbar}>
          <Grid
            alignItems="flex-start"
            className="fullWidth"
            container
            direction="row"
            justifyContent="flex-end"
            spacing={1}
          >
            {toolbarChildren ?? null}
            {searchable && (
              <Grid item>
                <CustomTooltip placement="top" title={tooltip}>
                  <TextField
                    error={Boolean(searchTerm)}
                    helperText={
                      Boolean(searchTerm)
                        ? `Searching: "${searchTerm}"`
                        : undefined
                    }
                    label="Search"
                    size="small"
                    value={searchTerm}
                    variant="filled"
                    onChange={handleSearchTermChange}
                  />
                </CustomTooltip>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      )}
      <TableContainer {...containerProps}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow>
              {showCheckboxes && <TableCell padding="checkbox" />}
              {columns.map((headCell) => (
                <TableCell
                  align={headCell.alignment ?? "left"}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <CustomTooltip
                    placement="top"
                    title={
                      headCell.sortable === false
                        ? `Field "${headCell.label}" is not sortable.`
                        : `Sort by ${headCell.label}`
                    }
                  >
                    <span>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        disabled={headCell.sortable === false}
                        onClick={(e) => handleRequestSort(e, headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className="table-hidden">
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </span>
                  </CustomTooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAndFilteredParticipants
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rowItem) => {
                return rowRenderer({
                  isOpen: isSelected(rowItem[uniqueIdentifierProperty]),
                  isSelected: isSelected(rowItem[uniqueIdentifierProperty]),
                  keepClosed: keepClosedRows?.includes(
                    rowItem[uniqueIdentifierProperty]
                  ),
                  key: rowItem[uniqueIdentifierProperty],
                  rowItem: rowItem,
                  showCheckbox: showCheckboxes,
                  uniqueIdentifierProperty,
                  onClick: handleRowClick,
                });
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
};

export type Order = "asc" | "desc";

export type AnyKey = keyof any;

export type Comparator = (
  a: { [key in AnyKey]: any },
  b: { [key in AnyKey]: any }
) => number;

type HeadCellAlignment = "left" | "right";

export interface HeadCell {
  alignment?: HeadCellAlignment;
  id: string;
  label: string;
  numeric?: boolean;
  sortable?: boolean;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  let aProperty = a[orderBy];
  let bProperty = b[orderBy];

  if (typeof aProperty === "string") {
    aProperty = aProperty.toLowerCase() as unknown as T[keyof T];
  }

  if (typeof bProperty === "string") {
    bProperty = bProperty.toLowerCase() as unknown as T[keyof T];
  }

  if (bProperty < aProperty) return -1;
  if (bProperty > aProperty) return 1;

  if (!bProperty && aProperty) return -1;
  if (bProperty && !aProperty) return 1;

  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): Comparator {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// https://material-ui.com/components/tables/#sorting-amp-selecting
export function stableSort(array: any[], comparator: Comparator) {
  if (!Array.isArray(array)) return [];

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default CustomTable;
