import type { ChangeEventHandler, FunctionComponent } from "react";
import { useState } from "react";
import { Grid, TextField } from "@mui/material";
import TooltipButton from "../TooltipButton";
import * as template from "./template";

interface Props {
  contents: string;
  mini?: boolean;
  onClose: (contents: string) => void;
  onSave: (contents: string) => void;
}

const NoteField: FunctionComponent<Props> = (props) => {
  const { contents: initialContents, mini = false, onClose, onSave } = props;

  const [contents, setContents] = useState<string>(initialContents);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setContents(event.target.value);
  };

  const handleSave = () => {
    if (initialContents.trim() !== contents.trim()) {
      onSave(contents);
    } else {
      onClose(contents);
    }
  };

  return (
    <Grid
      alignItems="center"
      className="fullWidth"
      container
      direction="row"
      justifyContent="space-between"
    >
      <Grid item xs={mini ? 9 : 10}>
        <TextField
          className="fullWidth"
          inputProps={{ rowsMax: mini ? 4 : 8 }}
          multiline
          placeholder={template.noteField.input.placeholder}
          size="small"
          value={contents}
          variant="outlined"
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <TooltipButton
          color="primary"
          text={template.noteField.saveButton.text}
          title={template.noteField.saveButton.tooltip}
          onClick={handleSave}
        />
      </Grid>
    </Grid>
  );
};

export default NoteField;
