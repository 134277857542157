import { PureComponent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { branding } from "../../../utilities/definitions";
import colorDefinitions from "../../../utilities/colorDefinitions";
import { compareNewPropsBasedOnState } from "../../../utilities/utilityFunctions";
import ParticipantStateLarge from "./ParticipantStateLarge";
import DeviceInfoIcons from "../../../components/DeviceInfoIcons";
import Selfie from "./Selfie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssignParticipant from "../../../components/AssignParticipant";
import "./participantModal.css";

class CallerModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      caller: props.caller,
      callers: props.callers,
      currentScreener: props.currentScreener,
      open: props.open,
      screeners: props.screeners,
      serverTimeOffset: props.serverTimeOffset,
      qmIsPrescreener: props.qmIsPrescreener,
      qmIsFullScreener: props.qmIsFullScreener,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    compareNewPropsBasedOnState(
      this.state,
      prevProps,
      this.props,
      (_updatedState) => {
        this.setState(_updatedState);
      }
    );
  }

  render() {
    const exlamationTriangleIcon = ["fas", "exclamation-triangle"];

    if (this.state.caller && this.state.open) {
      let callerNotAvailableWarning = false;
      let callerAvailable = false;
      for (var c in this.state.callers) {
        if (c === this.state.caller.id) {
          callerAvailable = true;
          break;
        }
        if (this.state.caller.email === this.state.callers[c].email) {
          callerAvailable = true;
        }
      }
      if (!this.state.callers || !callerAvailable) {
        let screenerName = false;
        for (var s in this.state.screeners) {
          let caller = this.state.screeners[s].assignedCaller;
          if (caller && caller.email === this.state.caller.email) {
            screenerName = s;
            break;
          }
        }
        if (screenerName) {
          callerNotAvailableWarning = (
            <h4 className="text-success">
              <FontAwesomeIcon icon={exlamationTriangleIcon} /> This participant
              has been assigned to: {screenerName}{" "}
              <FontAwesomeIcon icon={exlamationTriangleIcon} />
            </h4>
          );
        } else
          callerNotAvailableWarning = (
            <h4 className="text-danger">
              <FontAwesomeIcon icon={exlamationTriangleIcon} />
              This participant has left the queue.
              <FontAwesomeIcon icon={exlamationTriangleIcon} />
            </h4>
          );
      }

      const unavailableIcon = "X";
      const availableIcon = "✓";

      const {
        deviceInfo,
        given_name,
        phone_number,
        location,
        snapshotURL,
        family_name,
        facetime,
        gruveo,
        twilio,
        answer,
        topic,
      } = this.state.caller;

      return (
        <Dialog open={this.state.open} onClose={this.props.closeModal}>
          <DialogTitle>
            <div className="flexContainerStyle">
              <div className="nameAndInfoStyle">
                <div className={"callerModalTitleText"}>
                  <DeviceInfoIcons deviceInfo={deviceInfo || null} />
                  &nbsp;
                  <b>{`${given_name} ${family_name}`}</b>
                </div>
              </div>
              <div className="chatIconGridStyle" id="chatAppGrid">
                <div
                  className={"chatIcon"}
                  style={{
                    color: facetime
                      ? colorDefinitions.GREEN
                      : colorDefinitions.RED,
                  }}
                >
                  {facetime ? availableIcon : unavailableIcon} Facetime
                </div>
                <div
                  className={"chatIcon"}
                  style={{
                    color: gruveo
                      ? colorDefinitions.GREEN
                      : colorDefinitions.RED,
                  }}
                >
                  {gruveo ? availableIcon : unavailableIcon} Gruveo
                </div>
                <div
                  className={"chatIcon"}
                  style={{
                    color: twilio
                      ? colorDefinitions.GREEN
                      : colorDefinitions.RED,
                  }}
                >
                  {twilio ? availableIcon : unavailableIcon} {branding.twilio}
                </div>
              </div>
              <div>
                {callerNotAvailableWarning !== false &&
                  callerNotAvailableWarning}
              </div>
            </div>
          </DialogTitle>

          <DialogContent className="text-center callerModalData">
            <div className="selfieContainerStyle">
              <Selfie
                id="callerModalSnap"
                snapshotURL={snapshotURL}
                style={{
                  height: "100%",
                  width: "auto",
                  maxWidth: "100%",
                }}
                onClick={false}
              />
            </div>
            <ParticipantStateLarge participant={this.state.caller} />
            <Row style={{ marginTop: "10px" }}>
              <Col xs={2}>Name:</Col>
              <Col xs={10}>{`${given_name} ${family_name}`}</Col>
            </Row>
            <hr />
            <Row style={{ marginTop: "5px" }}>
              <Col xs={2}>Location:</Col>
              <Col xs={10}>{location}</Col>
            </Row>
            <hr />
            <Row style={{ marginTop: "5px" }}>
              <Col xs={2}>Topic:</Col>
              <Col xs={10}>{topic || "No Topic"}</Col>
            </Row>
            <hr />
            <Row style={{ marginTop: "5px" }}>
              <Col xs={2}>Answer:</Col>
              <Col xs={10}>{answer || "No Answer"}</Col>
            </Row>
            <hr />
            <Row style={{ marginTop: "5px" }}>
              <Col xs={2}>Phone Number:</Col>
              <Col xs={10}>{phone_number || "No Phone Number Provided"}</Col>
            </Row>
          </DialogContent>

          <DialogActions>
            <Button color="secondary" onClick={this.props.closeModal}>
              Close
            </Button>
            <AssignParticipant
              assignmentCallback={this.props.closeModal}
              participant={this.state.caller}
            />
          </DialogActions>
        </Dialog>
      );
    } else {
      return <div />;
    }
  }
}

export default CallerModal;
