import { store } from "../index";
import * as actions from "./actions";

export const queueEventListeners: Record<string, (data?: any) => void> = {
  clientConfiguration: (data) => {
    store.dispatch(actions.setClientConfiguration(data.clientConfiguration));
  },
  participantAddedNew: (data) => {
    const { participant } = data;
    store.dispatch(actions.participantCreated({ participant }));
  },
  participantDeletedNew: (data) => {
    const { participantGuid } = data;
    store.dispatch(actions.participantDeleted({ participantGuid }));
  },
  participantUpdatedNew: (data) => {
    const { participant } = data;
    store.dispatch(actions.participantUpdated({ participant }));
  },
  productionEventAdded: (data) => {
    const { productionEvent } = data;
    store.dispatch(actions.productionEventCreated({ productionEvent }));
  },
  productionEventDeleted: (data) => {
    const { productionEventGuid } = data;
    store.dispatch(actions.productionEventDeleted({ productionEventGuid }));
  },
  productionEventUpdated: (data) => {
    const { productionEvent } = data;
    store.dispatch(actions.productionEventUpdated({ productionEvent }));
  },
  participantAddedToProductionEvent: (data) => {
    const { productionEventGuid, participantGuid } = data;
    store.dispatch(
      actions.participantAddedToProductionEvent({
        productionEventGuid,
        participantGuid,
      })
    );
  },
  participantDeletedFromProductionEvent: (data) => {
    const { productionEventGuid, participantGuid } = data;
    store.dispatch(
      actions.participantRemovedFromProductionEvent({
        productionEventGuid,
        participantGuid,
      })
    );
  },
  sendScreener: (data) => {
    store.dispatch(
      actions.controlPanelUpdated({ controlPanel: data.screener })
    );
  },
  sendScreeners: (data) => {
    store.dispatch(
      actions.setControlPanels({
        controlPanels: data.screeners,
        episode: data.episode,
      })
    );
  },
  housekeepingPerformed: () => {
    store.dispatch(actions.housekeeping_performed());
  },
};
