import {
  Fragment,
  FunctionComponent,
  MouseEvent,
  MouseEventHandler,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { CircularProgress, Grid, Popover, Typography } from "@mui/material";
import { SettingsTwoTone } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import TooltipButton from "./TooltipButton";
import AlertDialogButton from "./AlertDialogButton";
import CMPList from "../modules/Screener/CMPList";
import CMPInfo from "../modules/Screener/CMPInfo";
import MediaSpaceCountdown from "../modules/Screener/MediaSpaceCountdown";
import CQ from "../utilities/socket/CQ";
import { branding } from "../utilities/definitions";
import { useTheme } from "../hooks/theme";
import MonitoringFeed from "../modules/Queue/ScreenerList/MonitoringFeed";
import LimitedBackdrop from "./LimitedBackdrop";
import CustomTooltip from "./Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  info: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  noInfo: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: "italic",
  },
  popover: {
    //pointerEvents: "none",
    height: "250px",
    margin: 0,
    maxHeight: "250px",
    minHeight: "250px",
    maxWidth: "500px",
    minWidth: "500px",
    width: "500px",
  },
  paper: {
    padding: theme.spacing(1),
    height: "100%",
    width: "100%",
  },
  tabs: {
    maxWidth: "100%",
  },
}));

const CMPInfoAccordion: FunctionComponent = () => {
  const classes = useStyles();
  const { isDarkMode } = useTheme();

  const [hasClickedSleepButton, setHasClickedSleepButton] =
    useState<boolean>(false);

  const cmps = useSelector((state: any) => state.queue.cmps);
  const currentEpisode = useSelector(
    (state: any) => state.queue.currentEpisode
  );
  const currentScreener = useSelector(
    (state: any) => state.queue.currentScreener
  );
  const currentShow = useSelector((state: any) => state.queue.currentShow);
  const screeners = useSelector((state: any) => state.queue.screeners);
  const serverTimeOffset = useSelector(
    (state: any) => state.queue.serverTimeOffset
  );

  const cmp = screeners[currentScreener]
    ? screeners[currentScreener].allocatedCMP
    : null;

  const inCall = Boolean(cmp?.inCall);

  const {
    isAwake,
    isUpdatingSession,
    friendlyName = "None Selected",
    participantEavesDropper,
    programEavesDropper,
    version,
  } = cmp ?? {};

  const participantEavesDropStatus =
    participantEavesDropper?.status ?? "Not Configured";
  const isRecordingParticipant = participantEavesDropStatus === "Recording";
  const isRecordingProgram = programEavesDropper?.status === "Recording";

  const { deallocateTooltip, sleepTooltip, wakeTooltip } = useMemo(() => {
    let deallocateTooltip = `Deallocate ${friendlyName}`;
    let sleepTooltip =
      isUpdatingSession || hasClickedSleepButton
        ? `Cannot end session on ${friendlyName} while the machine is updating its session state`
        : `End session on ${friendlyName}`;
    let wakeTooltip = isUpdatingSession
      ? `Cannot start session on ${friendlyName} while the machine is updating its session state`
      : `Start session on ${friendlyName}`;

    if (inCall) {
      deallocateTooltip = `Cannot deallocate ${friendlyName} while in call`;
      sleepTooltip = `Cannot end session on ${friendlyName} while in a call`;
      wakeTooltip = `Cannot start session on ${friendlyName} while in a call`;
    }

    if (isRecordingProgram) {
      deallocateTooltip = `Cannot deallocate ${friendlyName} while recording program`;
      sleepTooltip = `Cannot end session on ${friendlyName} while recording program`;
    } else if (isRecordingParticipant) {
      deallocateTooltip = `Cannot deallocate ${friendlyName} while recording participant`;
      sleepTooltip = `Cannot end session on ${friendlyName} while recording participant`;
    }

    if (isUpdatingSession || hasClickedSleepButton) {
      deallocateTooltip = `Cannot deallocate ${friendlyName} while the machine is updating its session state`;
    }

    return {
      deallocateTooltip,
      sleepTooltip,
      wakeTooltip,
    };
  }, [
    friendlyName,
    hasClickedSleepButton,
    inCall,
    isRecordingParticipant,
    isRecordingProgram,
    isUpdatingSession,
  ]);

  const { eavesDropStatusText, eavesDropStatusColor } = useMemo(() => {
    let parsedStatus = participantEavesDropStatus;
    let fontColor = "green";

    if (
      participantEavesDropStatus.includes("Error") ||
      participantEavesDropStatus.includes("Failed")
    ) {
      parsedStatus = "Error";
      fontColor = "orange";
    } else if (participantEavesDropStatus.includes("Recording")) {
      parsedStatus = "Recording";
      fontColor = "red";
    } else if (participantEavesDropStatus.includes("Not Configured")) {
      fontColor = isDarkMode ? "white" : "black";
    }

    return {
      eavesDropStatusColor: fontColor,
      eavesDropStatusText: parsedStatus,
    };
  }, [participantEavesDropStatus]);

  function handleDeallocateClick() {
    CQ.emit("deallocateCMP", {
      macAddress: cmp.macAddress,
      screener: currentScreener,
    });
  }

  const handleSessionClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    CQ.emit(isAwake ? "sleepCMP" : "wakeCMP");
  };

  const isButtonDisabled =
    isRecordingParticipant ||
    isRecordingProgram ||
    isUpdatingSession ||
    hasClickedSleepButton ||
    inCall;

  const sessionWarningText =
    isUpdatingSession || hasClickedSleepButton
      ? `Session state is changing...`
      : `Session must be started${
          screeners[currentScreener]?.assignedCaller
            ? ""
            : " and a participant must be assigned"
        } in order to start calls.`;

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  function handlePopoverOpen(
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const popoverIsOpen = useMemo(() => {
    const hasAnchorElement = Boolean(anchorEl);

    if (isUpdatingSession || hasClickedSleepButton) {
      if (hasAnchorElement) {
        return true;
      }
    } else {
      return hasAnchorElement;
    }

    return false;
  }, [anchorEl, isUpdatingSession, hasClickedSleepButton]);

  const userInfo = useSelector((state: any) => state.queue.userInfo);
  const userIsVccEmployee = userInfo?.networks?.includes("VCC"); // TODO replace with a real permissions schema
  const isCmpV2 = typeof version !== "undefined" && version >= 2;

  return (
    <>
      <div className={classes.root}>
        <Grid className="fullWidth" container direction="column" xs={12}>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            item
            justifyContent="space-between"
            xs={12}
          >
            <div>
              <Grid container direction="row" item style={{ padding: "8px" }}>
                {userIsVccEmployee && (
                  <Typography className={classes.heading}>
                    <CustomTooltip
                      placement="top"
                      title={`Click to ${
                        cmp
                          ? `view advanced controls for ${friendlyName}`
                          : "select a machine for allocation"
                      }`}
                    >
                      <span
                        onClick={(e) => {
                          if (!popoverIsOpen) {
                            handlePopoverOpen(e);
                          }
                        }}
                      >
                        <SettingsTwoTone color="action" fontSize="small" />
                        &nbsp;
                      </span>
                    </CustomTooltip>
                  </Typography>
                )}
                <Typography className={classes.heading}>
                  Machine:&nbsp;
                </Typography>
                <Typography className={cmp ? classes.info : classes.noInfo}>
                  {friendlyName}&nbsp;
                </Typography>
                {cmp && (isUpdatingSession || hasClickedSleepButton) && (
                  <Typography className={classes.heading}>
                    <CustomTooltip
                      placement="top"
                      title={`Machine ${friendlyName}'s session state is changing.`}
                    >
                      <CircularProgress size="20px" />
                    </CustomTooltip>
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ paddingLeft: "8px" }}>
                <Typography className={classes.heading}>
                  <i>
                    {cmp
                      ? isAwake
                        ? `Calls will launch remotely on machine ${friendlyName}`
                        : sessionWarningText
                      : "Calls will launch on your current device."}
                  </i>

                  {cmp && (
                    <>
                      <br />
                      <i>
                        <b>Automation version:</b>&nbsp;
                        {isCmpV2 ? version : "Legacy"}
                      </i>
                    </>
                  )}
                </Typography>
              </Grid>
              {cmp && isAwake && participantEavesDropper && (
                <Grid item style={{ paddingLeft: "8px" }}>
                  <Typography className={classes.heading}>
                    {branding.eavesdrop}:&nbsp;
                  </Typography>
                  <Typography className={classes.heading}>
                    <b
                      style={{ color: eavesDropStatusColor }}
                    >{` ${eavesDropStatusText}`}</b>
                    {participantEavesDropper && (
                      <Fragment>
                        &nbsp;{"("}
                        <MediaSpaceCountdown
                          bytesAvailable={
                            participantEavesDropper.bytesAvailable
                          }
                          recordStartTime={
                            participantEavesDropper.timeStarted
                              ? new Date(participantEavesDropper.timeStarted)
                              : undefined
                          }
                          videoBitrateKbps={
                            participantEavesDropper.videoBitrateKbps
                          }
                        />
                        &nbsp;{"remaining)"}
                      </Fragment>
                    )}
                  </Typography>
                </Grid>
              )}
            </div>
            <div>
              {cmp && (
                <Grid item style={{ paddingLeft: "0px !important" }}>
                  <MonitoringFeed
                    allowPopout={true}
                    feed={cmp.monitoringFeed}
                    friendlyName={cmp.friendlyName}
                    id={`${cmp.friendlyName}-monitoring-control-panel`}
                  />
                </Grid>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      {!cmp && Boolean(anchorEl) && (
        <CMPList
          anchorEl={anchorEl}
          cmps={cmps}
          currentEpisode={currentEpisode}
          currentScreener={currentScreener}
          currentShow={currentShow}
          inCall={
            screeners &&
            currentScreener &&
            (screeners[currentScreener]?.inCall ?? false)
          }
          onClick={handlePopoverClose}
        />
      )}
      {cmp && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          classes={{
            paper: classes.paper,
          }}
          className={classes.popover}
          open={popoverIsOpen}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => {
            if (!isUpdatingSession && !hasClickedSleepButton) {
              handlePopoverClose();
            }
          }}
        >
          <LimitedBackdrop open={isUpdatingSession || hasClickedSleepButton} />
          <Grid container direction="column" spacing={1} xs={12}>
            <Grid container item>
              <Grid
                container
                direction="row"
                item
                justifyContent={"space-between"}
                spacing={1}
              >
                <Grid item>
                  <AlertDialogButton
                    actions={[
                      {
                        label: "Confirm",
                        onClick: handleDeallocateClick,
                      },
                    ]}
                    color="secondary"
                    dialogTitle={`Deallocate Machine: ${friendlyName}`}
                    isDisabled={isButtonDisabled}
                    text="Deallocate"
                    title={deallocateTooltip}
                  >
                    <div className="alert-dialog-warning">
                      This Control Panel will no longer be able to launch video
                      calls on {friendlyName}. Please only deallocate this
                      machine for maintenance purposes, or to allocate it to
                      another Control Panel.
                      <br />
                      Are you sure you want to continue?
                    </div>
                  </AlertDialogButton>
                </Grid>
                <Grid item>
                  <TooltipButton
                    clickTimeout={{
                      callback: (isTimeoutActive) => {
                        setHasClickedSleepButton(isTimeoutActive);
                      },
                      seconds: 8,
                    }}
                    color="warning"
                    isDisabled={isButtonDisabled}
                    placement="top"
                    text={isAwake ? "End Session" : "Start Session"}
                    title={isAwake ? sleepTooltip : wakeTooltip}
                    onClick={handleSessionClick}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <CMPInfo
                cmp={cmp}
                currentScreener={currentScreener}
                serverTimeOffset={serverTimeOffset}
              />
            </Grid>
          </Grid>
        </Popover>
      )}
    </>
  );
};

export default CMPInfoAccordion;
