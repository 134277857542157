import type { FunctionComponent } from "react";
import { useState } from "react";
import { useHttpRequest, useSelector, useSnackbar } from "../../hooks";
import { branding } from "../../utilities/definitions";

import TooltipButton from "../../components/TooltipButton";

const QuickParticipantButton: FunctionComponent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const endpointName = useSelector((state) => state.queue.currentScreener);

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );

  const [createParticipant, createParticipantState] = useHttpRequest(
    "admin_proxy/participants",
    {
      method: "post",
      body: {
        accountGuid,
        contacts: [],
        endpointName,
        productionGuid,
        fullName: "Quick Participant",
        bypassDuplicateCheck: true,
        temporary: true,
        generateInvitation: true,
        sendInvitation: false,
      },
      onError: () => {
        enqueueSnackbar("Failed to create Quick Participant.", {
          variant: "error",
        });
      },
      onSuccess: (_response) => {
        enqueueSnackbar(
          `Quick Participant was created and is being assigned to Control Panel: ${endpointName}.`,
          {
            variant: "success",
          }
        );
      },
    }
  );

  async function handleQuickParticipantClick() {
    await createParticipant();
  }

  return (
    <TooltipButton
      color="warning"
      icon={["fas", "user-plus"]}
      isDisabled={createParticipantState.loading}
      text="Quick Participant"
      title={`Quickly create and automatically assign a ${branding.stagedoor} participant to this Control Panel`}
      onClick={handleQuickParticipantClick}
    />
  );
};

export default QuickParticipantButton;
