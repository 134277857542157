import type { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";
import {
  ParticipantStatus,
  participantStatusMuiColors,
  participantStatusText,
} from "../utilities/definitions";

const useStyles = makeStyles<any>((_theme) => ({
  statusChip: {
    marginLeft: 10,
  },
}));

interface Props {
  status: ParticipantStatus;
}

const ParticipantStatusChip: FunctionComponent<Props> = (props) => {
  const { status } = props;
  const classes = useStyles(props);

  const chipShouldDisplay = status && status !== "disconnectedUnassigned";

  return chipShouldDisplay ? (
    <Chip
      className={classes.statusChip}
      color={participantStatusMuiColors[status]}
      label={participantStatusText[status]}
    />
  ) : null;
};

export default ParticipantStatusChip;
