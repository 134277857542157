import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import queueReducer from "./queue/reducers";
import { userPrefsReducer } from "./userPrefs/reducers";
import { NODE_ENV } from "../config";

let middleware = [];

if (NODE_ENV !== "production") {
  middleware = [...middleware, logger];
}

export const store = configureStore({
  middleware,
  reducer: {
    queue: queueReducer,
    userPrefs: userPrefsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
