import { MouseEvent, Fragment, FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Menu, MenuItem } from "@mui/material";
import { selectRole } from "../../store/queue/actions";

type Props = {
  disabled: boolean;
};

const SelectRole: FunctionComponent<Props> = (props) => {
  const { disabled } = props;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const currentRole = useSelector((state: any) => state.queue.currentRole);
  const roles = useSelector(
    (state: any) =>
      state.queue.roleChoices || [
        {
          name: "callScheduler",
          alias: "Call Scheduler",
          value: false,
        },
        {
          name: "callProducer",
          alias: "Call Producer",
          value: false,
        },
        {
          name: "queueAdmin",
          alias: "Queue Admin",
          value: false,
        },
        {
          name: "queueTech",
          alias: "Queue Tech",
          value: false,
        },
      ]
  );

  function handleClick(event: MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorEl(null);
  }

  function handleSelect(roleName: string): void {
    const role = roles.find((role) => role.name === roleName) ?? currentRole;
    dispatch(selectRole(role));
    handleClose();
  }

  const availableRoleCount: number = roles.reduce(
    (acc, role) => (role.value ? ++acc : acc),
    0
  );

  return (
    <Fragment>
      <Button
        disabled={availableRoleCount <= 1 || disabled}
        onClick={handleClick}
      >
        {currentRole.alias}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="role-selection"
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {roles
          .filter((role) => Boolean(role))
          .map((role, i) => (
            <MenuItem key={i} onClick={() => handleSelect(role.name)}>
              {role.alias}
            </MenuItem>
          ))}
      </Menu>
    </Fragment>
  );
};

export default SelectRole;
