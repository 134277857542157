import type { FunctionComponent } from "react";
import type { MenuButtonItem } from "../../../components/MenuButton";

import MenuButton from "../../../components/MenuButton";
import { SeatStatus } from "../../../store/types";

type Props = {
  assignParticipantToSeat: any;
  participants: { [id: string]: any };
  seatInfo: any;
  seatStatus: SeatStatus;
  canControlMultibox: boolean;
};

const AvailableParticipantsDropdown: FunctionComponent<Props> = (props) => {
  const {
    seatInfo,
    seatStatus,
    participants,
    assignParticipantToSeat,
    canControlMultibox,
  } = props;
  const { id: seatID } = seatInfo;

  const availableParticipants = Object.values(participants).filter(
    (participant) =>
      !Object.values(seatStatus).find(
        (seat) => seat.participant?.id === participant.id
      )
  );

  const menuButtonItems: MenuButtonItem[] = Object.values(
    availableParticipants
  ).map((participant) => ({
    children: participant.fullName,
    disabled: !canControlMultibox,
    key: `dropdown_${participant.id}`,
    onClick: () => {
      assignParticipantToSeat(participant, seatID);
    },
  }));

  const title = `Seat ${seatID} Empty`;

  return (
    <MenuButton
      className="fullWidth crowdview-seat-dropdown"
      items={menuButtonItems}
      MenuProps={{
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
        transformOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      }}
      text={title}
      title={title}
      variant="outlined"
    />
  );
};

export default AvailableParticipantsDropdown;
