import { FunctionComponent } from "react";
import { Badge } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { grey } from "@mui/material/colors";
import MenuButton from "../../components/MenuButton";
import { buttons } from "./templates";
import { useSelector, useSocket } from "../../hooks";

const useStyles = makeStyles({
  badge: {
    backgroundColor: grey["300"],
    color: "black",
    outline: `1px solid ${grey["500"]}`,
  },
});

const SelectShow: FunctionComponent = () => {
  const classes = useStyles();
  const socketService = useSocket();

  const currentShow = useSelector((state: any) => state.queue.currentShow);
  const showList = useSelector((state: any) =>
    state.queue.shows ? Object.keys(state.queue.shows) : []
  );

  function handleSelect(show: string): void {
    socketService.selectShow(show);
  }

  return (
    <Badge
      badgeContent={currentShow ? showList.length - 1 : showList.length}
      classes={{
        badge: classes.badge,
      }}
      invisible={showList.length === 1}
    >
      <MenuButton
        color="info"
        disabled={showList.length === 0}
        items={showList
          .filter((name) => name !== currentShow)
          .map((show, i) => ({
            children: show,
            key: i,
            onClick: () => handleSelect(show),
          }))}
        text={
          showList.length > 0
            ? currentShow || buttons.selectShow.productionsAvailable
            : buttons.selectShow.noProductionsAvailable
        }
        title={`Select Production`}
      />
    </Badge>
  );
};

export default SelectShow;
