import * as yup from "yup";
import { phone } from "phone";

const isDisallowedNameError = "This is not an accepted name";

const isPossibleEmailError =
  "The email address you have entered is invalid, please check it.";

const isPossibleNameError =
  "The name you have entered is invalid. Numbers are not allowed.";

const isPossiblePhoneError =
  "The phone number you have entered is invalid.  For non-US numbers, please ensure you have added a country code prefix.  For Puerto Rico or the Dominican Republic, please use '+1'";

const isPossiblePhoneOrEmailError =
  "Please enter a valid Apple/FaceTime ID (can be email or phone number)";

// We accept an empty string as valid, but validatePhoneNumber does not. This is a short-circuit.
const validatePhoneNumber = (value: string): boolean =>
  value.length > 0 ? phone(value).isValid : true;

type regexKeys = "name";

export const regex: Record<regexKeys, RegExp> = {
  name: /^([^0-9]*)$/,
};

export const emailValidationSchema = yup
  .string()
  .email(isPossibleEmailError)
  .trim();

export const nameValidationSchema = yup
  .string()
  .trim()
  .required("A name is required.")
  .test("valid_name", isDisallowedNameError, (value) => {
    return !value?.toLowerCase()?.includes("participant");
  })
  .matches(regex.name, {
    message: isPossibleNameError,
  });

export const phoneValidationSchema = yup
  .string()
  .trim()
  .test("valid_phone", isPossiblePhoneError, (value) => {
    return validatePhoneNumber(value);
  });

export const facetimeValidationSchema = yup
  .string()
  .trim()
  .test("valid_phone_or_email", isPossiblePhoneOrEmailError, (value) => {
    const validPhone = validatePhoneNumber(value);
    const validEmail = yup.string().email().isValidSync(value);

    return validPhone || validEmail;
  });
