import type { FunctionComponent, MouseEventHandler, ReactNode } from "react";
import type {
  ButtonProps,
  ButtonTypeMap,
  ExtendButtonBase,
  IconButtonTypeMap,
} from "@mui/material";
import type {
  FlipProp,
  IconProp,
  SizeProp,
} from "@fortawesome/fontawesome-svg-core";

import { Fragment, useEffect, useMemo } from "react";
import { Button, MenuItem, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClickTimeout } from "../utilities/customHooks";
import CustomTooltip from "./Tooltip";

export type ButtonComponentType = "button" | "icon" | "menu-item";

export interface TooltipButtonProps extends ButtonProps {
  clickTimeout?: {
    callback?: (isTimeoutActive: boolean) => void;
    seconds: number;
  };
  component?: ButtonComponentType;
  icon?: IconProp;
  iconFlip?: FlipProp;
  iconPlacement?: "left" | "right";
  iconSize?: SizeProp;
  isDisabled?: boolean;
  htmlType?: any;
  placement?: "top" | "bottom" | "left" | "right";
  text?: string | ReactNode;
  title?: string;
}

const TooltipButton: FunctionComponent<TooltipButtonProps> = (props) => {
  const {
    className,
    clickTimeout,
    component = "button",
    disabled,
    htmlType,
    icon,
    iconFlip,
    iconPlacement = "left",
    iconSize,
    isDisabled = false,
    onClick,
    placement = "top",
    style = {},
    text,
    title,
    variant = "contained",
    ...rest
  } = props;

  const { isTimeoutActive, triggerTimeout } = useClickTimeout(
    clickTimeout?.seconds
  );

  useEffect(() => {
    clickTimeout?.callback?.(isTimeoutActive);
  }, [clickTimeout, isTimeoutActive]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    onClick?.(event);

    if (Boolean(clickTimeout)) {
      triggerTimeout();
    }
  };

  const ButtonComponent: ExtendButtonBase<
    IconButtonTypeMap<{}, "button"> | ButtonTypeMap<{}, "button">
  > = useMemo(() => {
    if (component === "button") {
      return Button;
    } else if (component === "icon") {
      return IconButton;
    } else {
      return MenuItem;
    }
  }, [component]);

  return (
    <CustomTooltip
      key={`tooltip-${title}`}
      placement={placement}
      style={{
        fontSize: "1em",
      }}
      title={title}
    >
      <span className={className}>
        <ButtonComponent
          {...rest}
          className={className}
          disabled={(disabled ?? isDisabled) || isTimeoutActive}
          style={
            isDisabled ?? props.disabled
              ? { ...style, pointerEvents: "none" }
              : { ...style }
          }
          type={props.type ?? htmlType}
          variant={variant}
          onClick={handleClick}
        >
          {icon && iconPlacement === "left" && (
            <span>
              <FontAwesomeIcon flip={iconFlip} icon={icon} />
              {text && <Fragment>&nbsp;</Fragment>}
            </span>
          )}
          {text && <span>{text}</span>}
          {icon && iconPlacement === "right" && (
            <span>
              {text && <Fragment>&nbsp;</Fragment>}
              <FontAwesomeIcon flip={iconFlip} icon={icon} size={iconSize} />
            </span>
          )}
        </ButtonComponent>
      </span>
    </CustomTooltip>
  );
};

export default TooltipButton;
