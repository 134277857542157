import { MouseEvent, FunctionComponent, useMemo, useState } from "react";
import { Popover, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NetworkDiagnosticStatus } from "./types";
import { networkQuality } from "./templates";
import mustache from "mustache";

const STEP = 6;
const BARS_ARRAY = [0, 1, 2, 3, 4];

const useStyles = makeStyles((theme) => ({
  bars: {
    display: "inline-flex",
    paddingBottom: `${STEP}px`,
    marginLeft: "15px",
    alignItems: "flex-end",
    "& div": {
      width: `${STEP}px`,
    },
  },
  paper: {
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: "none",
  },
  root: {
    display: "inline-flex",
  },
}));

const colors = {
  good: {
    empty: "#98de91",
    filled: "#109d01",
  },
  average: {
    empty: "#c5c5c5",
    filled: "#414141",
  },
  bad: {
    empty: "#de9292",
    filled: "#af0101",
  },
};

type Props = {
  score: number | null;
  testStatus?: NetworkDiagnosticStatus;
};

const NetworkQualityScore: FunctionComponent<Props> = (props) => {
  const { score, testStatus } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const open = Boolean(anchorEl);

  const classes = useStyles();

  function handlePopoverOpen(
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) {
    setAnchorEl(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }

  const { colorsToUse, scoreToUse, statusMessage, statusTooltip } =
    useMemo(() => {
      let colorsToUse = colors.average;
      let scoreToUse = 3;
      let statusMessage = "";
      let statusTooltip = "";

      if (testStatus === "not-started") {
        statusMessage = networkQuality.state.notTested.title;
        statusTooltip = networkQuality.state.notTested.tooltip;
      } else if (testStatus === "pending") {
        statusMessage = networkQuality.state.pending.title;
        statusTooltip = networkQuality.state.pending.tooltip;
      } else if (testStatus === "completed") {
        if (typeof score === "number") {
          scoreToUse = +score.toFixed(1);

          if (score > 3) {
            colorsToUse = colors.good;
          } else if (score < 3) {
            colorsToUse = colors.bad;
          }

          statusMessage = mustache.render(
            networkQuality.state.completed.title,
            {
              score: scoreToUse,
            }
          );
          statusTooltip = networkQuality.state.completed.tooltip;
        } else {
          statusMessage = networkQuality.state.error.title;
          statusTooltip = networkQuality.state.error.tooltip;
        }
      }

      return {
        colorsToUse,
        scoreToUse,
        statusMessage,
        statusTooltip,
      };
    }, [score, testStatus]);

  return (
    <div className={classes.root}>
      <span
        className="text-center"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Typography variant="caption">{networkQuality.title}</Typography>
        <Typography>
          <b>{statusMessage}</b>
        </Typography>
      </span>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.paper,
        }}
        className={classes.popover}
        open={open}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
      >
        {statusTooltip}
      </Popover>

      <div className={classes.bars}>
        {BARS_ARRAY.map((bar) => (
          <div
            key={bar}
            style={{
              height: `${STEP * (bar + 1)}px`,
              background: `linear-gradient(135deg, transparent ${STEP}px, ${
                scoreToUse > bar ? colorsToUse.filled : colorsToUse.empty
              } ${STEP}px`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default NetworkQualityScore;
