import React from "react";
import { IMAGES_CDN } from "../../../config";
import { useTheme } from "../../../hooks/theme";

const Selfie = ({ id, snapshotURL, onClick, style }) => {
  const { isDarkMode } = useTheme();
  const noSelfie = `${IMAGES_CDN}/demoSnapshots/nosnapshot.jpg`;

  const useSelfie = snapshotURL.length > 0;

  const selfieToRender = useSelfie ? snapshotURL : noSelfie;

  const selfieStyle = useSelfie
    ? style
    : { ...style, filter: isDarkMode ? "invert(100%)" : undefined };

  return (
    <React.Fragment>
      <img
        alt={id}
        id={id}
        src={selfieToRender}
        style={selfieStyle}
        onClick={() => {
          if (onClick) {
            onClick(id);
          }
        }}
        onError={(e) => {
          e.target.onerror = null;
          function loadAlternatives(element, imageList) {
            const image = new Image();
            image.src = imageList.shift();

            image.onload = function () {
              element.target.src = image.src;
            };

            image.onerror = function () {
              if (imageList.length) {
                loadAlternatives(element, imageList);
              }
            };
          }
          e.persist();
          loadAlternatives(e, [selfieToRender, noSelfie]);
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(Selfie);
