import type { FunctionComponent } from "react";

import { Fragment, useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useHttpRequest, useSelector } from "../../../hooks";
import LimitedBackdrop from "../../../components/LimitedBackdrop";
import Selfie from "../../Queue/Caller/Selfie";
import { participantTypeEnums } from "../../../utilities/definitions";
import * as template from "./assignedParticipantTemplate";
import EditParticipantForm from "../../EditParticipantForm";

type Contact = {
  guid: string;
  type: string;
  value: string;
  phoneIsInternational: boolean;
  phoneIsSmsCapable: boolean;
};

type Props = {
  participant: any;
  onInfoChange: (...params: any) => Promise<void> | void;
};

const AssignedParticipantInfo: FunctionComponent<Props> = (props) => {
  const { participant, onInfoChange } = props;
  const { guid } = participant;

  const isQueueParticipant = participant?.type === participantTypeEnums.QUEUE;

  const { accountGuid, productionGuid } = useSelector(
    (state) => state.queue.showInfo
  );

  const [contacts, setContacts] = useState<any[]>([]);

  const baseEmail = contacts.find((contact) => contact.type === "email");
  const basePhone = contacts.find((contact) => contact.type === "phone");
  const baseFacetime = contacts.find((contact) => contact.type === "facetime");

  const [email, setEmail] = useState<Contact>(baseEmail);
  const [phone, setPhone] = useState<Contact>(basePhone);
  const [facetime, setFacetime] = useState<Contact>(baseFacetime);

  const [participantRequest, participantRequestState] = useHttpRequest(
    `admin_proxy/participants/${guid}`,
    {
      method: "get",
    }
  );

  const loadContacts = useCallback(async () => {
    const data = await participantRequest({});

    if (data) {
      setContacts(data.contacts);
    }
  }, [participantRequest]);

  useEffect(() => {
    if (!isQueueParticipant) {
      loadContacts();
    }
  }, [isQueueParticipant, loadContacts]);

  useEffect(() => {
    const email = contacts.find((contact) => contact.type === "email");
    const phone = contacts.find((contact) => contact.type === "phone");
    const facetime = contacts.find((contact) => contact.type === "facetime");

    setEmail(email);
    setPhone(phone);
    setFacetime(facetime);
  }, [contacts]);

  const isBackdropOpen = !isQueueParticipant && participantRequestState.loading;

  return (
    <Fragment>
      <LimitedBackdrop open={isBackdropOpen} />
      <br />
      <Grid
        className="fullWidth"
        container
        justifyContent="space-between"
        spacing={1}
        sx={{ marginLeft: 2 }}
      >
        {isQueueParticipant && (
          <Grid container item justifyContent="center" xs={6}>
            <Selfie
              id={participant.id}
              snapshotURL={participant.snapshotURL}
              style={{}}
              onClick={() => {}}
            />
          </Grid>
        )}
        <Grid
          container
          direction="column"
          item
          spacing={2}
          xs={isQueueParticipant ? 6 : 12}
        >
          {isQueueParticipant && (
            <Fragment>
              <Grid item>
                <b>{template.titles.location}:</b>{" "}
                {participant.location || template.noLocation}
              </Grid>
              <Grid item>
                <b>{template.titles.topic}:</b>{" "}
                {participant.topic || template.noTopic}
              </Grid>
              {participant.answer && (
                <Grid item>
                  <b>{template.titles.prompt}:</b> {participant.answer}
                </Grid>
              )}
            </Fragment>
          )}
          {!isQueueParticipant && (
            <EditParticipantForm
              contactInfo={{
                accountGuid,
                productionGuid,
                email,
                facetime,
                phone,
                onSave: async () => {
                  const data = await participantRequest();

                  if (data) {
                    setContacts(data.contacts);
                  }

                  await onInfoChange?.();
                },
              }}
              contactOnly
              participant={participant}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AssignedParticipantInfo;
