import React, { useMemo } from "react";
import { differenceInMilliseconds } from "date-fns";
import { useCountdown } from "../../utilities/customHooks";

type Props = {
  bytesAvailable: number;
  recordStartTime?: Date;
  videoBitrateKbps: number;
};

function formatDateToHoursAndMinutesOnly(date: Date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours < 10 ? `0${hours}` : hours} hour${
    hours > 1 || hours === 0 ? "s" : ""
  }, ${minutes < 10 ? `0${minutes}` : minutes} minute${
    minutes > 1 || minutes === 0 ? "s" : ""
  }`;
}

const MediaSpaceCountdown: React.FC<Props> = (props) => {
  const { bytesAvailable, recordStartTime, videoBitrateKbps } = props;

  const millisecondsRemainingFromStart = useMemo(() => {
    if (bytesAvailable && videoBitrateKbps) {
      const kbpsToBpsDivisor = 0.008;
      const videoBitrateBps = videoBitrateKbps / kbpsToBpsDivisor;

      const secondsRemainingFromStart = bytesAvailable / videoBitrateBps;
      const secondsToMillisecondsMultiplier = 1000;
      return secondsRemainingFromStart * secondsToMillisecondsMultiplier;
    }

    return 0;
  }, [bytesAvailable, videoBitrateKbps]);

  const countdownStartTime = useMemo(() => {
    if (millisecondsRemainingFromStart && recordStartTime) {
      const millisecondsAlreadyElapsed = differenceInMilliseconds(
        new Date(),
        recordStartTime
      );
      const millisecondsRemaining =
        millisecondsRemainingFromStart - millisecondsAlreadyElapsed;
      const countdownStartTime = new Date(millisecondsRemaining);

      return countdownStartTime;
    }

    return null;
  }, [millisecondsRemainingFromStart, recordStartTime]);

  const timeRemaining = useCountdown(countdownStartTime, "minute");

  return (
    <strong>
      {"~"}
      {timeRemaining
        ? formatDateToHoursAndMinutesOnly(timeRemaining)
        : formatDateToHoursAndMinutesOnly(
            new Date(millisecondsRemainingFromStart)
          )}
    </strong>
  );
};

export default MediaSpaceCountdown;
