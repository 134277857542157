import React, { useState } from "react";
import Select from "react-select";

import Col from "react-bootstrap/Col";
import TabContent from "react-bootstrap/TabContent";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import CQ from "../../utilities/socket/CQ";

const EavesDropSettingsTab = ({ uploadStrategy }) => {
  const uploadStrategyOptions = [
    { value: null, label: "None" },
    { value: "aspera", label: "Aspera" },
    { value: "dropbox", label: "Dropbox" },
    { value: "latakoo", label: "Latakoo" },
    { value: "masv", label: "MASV" },
  ];

  function setUploadStrategy({ value }) {
    CQ.emit("setEavesDropUploadStrategy", { uploadStrategy: value });
  }

  return (
    <TabContent>
      <Row style={{ marginBottom: "10px" }}>
        <Col className="text-left" style={{ marginBottom: "10px" }} xs={12}>
          <h2 style={{ margin: "auto" }}>EavesDrop Settings</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.Group>
            <Form.Label>Upload Strategy</Form.Label>
            <Select
              isSearchable
              options={uploadStrategyOptions}
              value={uploadStrategyOptions.find(
                (option) => option.value === uploadStrategy
              )}
              onChange={setUploadStrategy}
            />
          </Form.Group>
        </Col>
      </Row>
    </TabContent>
  );
};

export default EavesDropSettingsTab;
